<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_zadmin_head />
            <inc_zadmin_left />
            <!-------------------------- 인클루드(e) -------------------------->

            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">

                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_zadmin_title />
                    <!-------------------------- 인클루드(e) -------------------------->
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">
                                <form id="regiForm" onsubmit="return false">

                                    <!--전달값-->
                                    <input type="hidden" name="no" v-model="view_1.NO" />
                                    <!--//전달값-->

                                    <table>
                                        <colgroup>
                                            <col width="130px">
                                            <col width="420px">
                                            <col width="130px">
                                            <col width="420px">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>기관(학교)명 *</th>
                                                <td>
                                                    <input type="text" name="comp_name" v-model="view_1.COMP_NAME" />
                                                </td>
                                                <th>기관구분</th>
                                                <td>
                                                    <select name="comp_gubun" class="pu_select-box" v-model="view_1.COMP_GUBUN"
                                                            title="기관을 선택하세요." style="width:200px">
                                                        <option value="">- 기관을 선택하세요.</option>
                                                        <option v-for="(row, idx) in list_comp_gubun" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>지역1</th>
                                                <td>
                                                    <select name="code_area_1" class="pu_select-box" v-model="view_1.CODE_AREA_1"
                                                            v-on:change="exec('CODE_AREA_2', {'event':$event});"
                                                            title="지역1을 선택하세요." style="width:200px">
                                                        <option value="">- 지역1을 선택하세요.</option>
                                                        <option v-for="(row, idx) in list_area_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>
                                                </td>
                                                <th>지역2</th>
                                                <td>
                                                    <select name="code_area_2" class="pu_select-box" v-model="view_1.CODE_AREA_2"
                                                            title="지역2을 선택하세요." style="width:200px">
                                                        <option value="">- 지역2을 선택하세요.</option>
                                                        <option v-for="(row, idx) in list_area_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>의뢰일자</th>
                                                <td>
                                                    <span>
                                                        <input type="text" name="req_date" class="pu_calendar" readonly="readonly"
                                                               v-model:value="view_1.REQ_DATE" onclick="pjh_calendar(undefined, '[name=req_date]', 'YYYY-MM-DD')" />
                                                        <input type="image" src="/images/icon_calendar.png" />
                                                    </span>
                                                </td>
                                                <th>
                                                    비밀번호 *<br />
                                                    (의뢰서에 기입한<br />전화번호 뒤 4자리)
                                                </th>
                                                <td>
                                                    <input type="text" name="pw" v-model="view_1.PW" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                </td>
                                                <td colspan="3">
                                                    <div class="pu_TableX01">
                                                        <table>
                                                            <colgroup>
                                                                <col width="*">
                                                                <col width="*">
                                                                <col width="*">
                                                                <col width="*">
                                                                <col width="70px;">
                                                            </colgroup>
                                                            <thead>
                                                                <tr>
                                                                    <td scope="col">품명</td>
                                                                    <td scope="col">가격</td>
                                                                    <td scope="col">수량</td>
                                                                    <td scope="col">금액</td>
                                                                    <td scope="col">
                                                                        <div class="pu_btnDiv01" style="padding:0; width: 70px; height: 40px; text-align: center; position: relative;">
                                                                            <a class="pu_btn_red" href="javascript:;" v-on:click="exec('ITEM_ADD', {})" style="padding: 0; min-width: unset; width: 70px;">+ 추가</a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(row, idx) in list_req_item">
                                                                    <td>
                                                                        <select name="item_no" class="pu_select-box" v-model="row.ITEM_NO"
                                                                                v-on:change="exec('ITEM_SELECT', {'row':row, 'event':$event});"
                                                                                title="품목을 선택하세요." style="width:200px">
                                                                            <option value="">- 품목을 선택하세요.</option>
                                                                            <option v-for="(row_item, idx_item) in list_item" v-bind:value="row_item.NO">{{ row_item.TITLE }}</option>
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" name="item_price" v-model="row.PRICE"
                                                                               @input="exec('ITEM_EDIT_EACH', {'row':row});" />
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" name="item_qty" v-model="row.QTY"
                                                                               @input="exec('ITEM_EDIT_EACH', {'row':row});" />
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" name="item_amount" v-model="row.AMOUNT"
                                                                               @input="exec('ITEM_EDIT_EACH', {'row':row});" />
                                                                    </td>
                                                                    <td>
                                                                        <div class="pu_btnDiv01" style="padding:0; width: 70px; height: 100%; text-align: center; position: relative;">
                                                                            <a class="pu_btn_dark" href="javascript:;" v-on:click="exec('ITEM_DEL', {'idx':idx})" style="padding: 0; min-width: unset; width: 70px;">- 삭제</a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <th>의뢰건수</th>
                                                <td>
                                                    <input type="text" name="req_cnt" class="dev_numnumnum" v-model="view_1.REQ_CNT" /> 건
                                                </td>
                                                <th>총금액</th>
                                                <td>
                                                    <input type="text" name="amount" class="dev_numnumnum" v-model="view_1.AMOUNT" /> 원
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>입금일자</th>
                                                <td>
                                                    <span>
                                                        <input type="text" name="deposit_date" class="pu_calendar" readonly="readonly"
                                                               v-model:value="view_1.DEPOSIT_DATE" onclick="pjh_calendar(undefined, '[name=deposit_date]', 'YYYY-MM-DD')" />
                                                        <input type="image" src="/images/icon_calendar.png" />
                                                    </span>

                                                    <div class="pu_btnDiv01" style="margin-left: 5px; padding: 0px; display: inline-block;">
                                                        <a class="pu_btn_dark" href="javascript:$('[name=deposit_date]').val('');"
                                                           style="padding: 0px; height: 30px; line-height: 29px; border-radius: 7px;">일자 삭제</a>
                                                    </div>
                                                </td>
                                                <th>입금자명</th>
                                                <td>
                                                    <input type="text" name="deposit_name" v-model="view_1.DEPOSIT_NAME" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>발송일자</th>
                                                <td>
                                                    <span>
                                                        <input type="text" name="delivery_date" class="pu_calendar" readonly="readonly"
                                                               v-model:value="view_1.DELIVERY_DATE" onclick="pjh_calendar(undefined, '[name=delivery_date]', 'YYYY-MM-DD')" />
                                                        <input type="image" src="/images/icon_calendar.png" />
                                                    </span>

                                                    <div class="pu_btnDiv01" style="margin-left: 5px; padding: 0px; display: inline-block;">
                                                        <a class="pu_btn_dark" href="javascript:$('[name=delivery_date]').val('');"
                                                           style="padding: 0px; height: 30px; line-height: 29px; border-radius: 7px;">일자 삭제</a>
                                                    </div>
                                                </td>
                                                <th>발송방법</th>
                                                <td>
                                                    <select name="delivery_gubun" class="pu_select-box" v-model="view_1.DELIVERY_GUBUN"
                                                            title="배송방법을 선택하세요." style="width:200px">
                                                        <option value="">- 배송방법을 선택하세요.</option>
                                                        <option v-for="(row, idx) in list_delivery_gubun" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>비고</th>
                                                <td colspan="3">
                                                    <textarea name="bigo">{{ view_1.BIGO }}</textarea>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>공개여부</th>
                                                <td colspan="3">
                                                    <input type="radio" id="showyn1" name="show_yn" value="Y" v-model="view_1.SHOW_YN"><label for="showyn1">공개</label>
                                                    <input type="radio" id="showyn2" name="show_yn" value="N" v-model="view_1.SHOW_YN"><label for="showyn2">비공개</label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->
                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_left">
                                <a class="pu_btn_dark" href="javascript:history.back();"><i class="xi-close"></i> 취소</a>
                                <a class="pu_btn_blue" href="javascript:;" v-on:click="exec('SAVE', {})"><i class="xi-check"></i> 저장</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "REGI"

                , cnt_lim_pic: 100  // 사진 갯수

                , rownum: 0        // 번호
                , paging: ""       // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                , list_area_1: []         // 시도
                , list_area_2: []         // 구군
                , list_comp_gubun: []     // 업체 구분
                , list_delivery_gubun: [] // 배송 구분

                , list_item: []           // 아이템 리스트
                , list_req_item: []       // 주문 목록

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/gmo/req_view";

                            // 폼
                            data["no"] = qs.no;
                        }
                        break;

                    case "CODE_AREA_1":
                        {
                            // url 설정
                            url = "/vue_api/common/common/code_area_1";
                        }
                        break;

                    case "CODE_AREA_2":
                        {
                            // url 설정
                            url = "/vue_api/common/common/code_area_2";

                            if (params.event) {
                                data["code_1"] = params.event.target.value;
                            }
                            else {
                                data["code_1"] = _this.view_1.CODE_AREA_1;
                            }
                        }
                        break;

                    case "GMO_COMP_GUBUN":
                        {
                            // url 설정
                            url = "/vue_api/common/common/list_code";

                            // 폼
                            data["gubun"] = "GMO_COMP_GUBUN";
                        }
                        break;

                    case "GMO_DELIVERY_GUBUN":
                        {
                            // url 설정
                            url = "/vue_api/common/common/list_code";

                            // 폼
                            data["gubun"] = "GMO_DELIVERY_GUBUN";
                        }
                        break;

                    case "GMO_ITEM":
                        {
                            // url 설정
                            url = "/vue_api/common/common/gmo_item";

                            // 폼
                            data["estimate_yn"] = "N";
                        }
                        break;

                    case "ITEM_LIST":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/gmo/req_view_item_list";

                            // 폼
                            data["req_no"] = _this.view_1.NO;
                        }
                        break;

                    case "ITEM_ADD":
                        {

                        }
                        break;

                    case "ITEM_DEL":
                        {
                            var idx = params.idx;
                            _this.list_req_item.splice(idx, 1);
                        }
                        break;

                    case "ITEM_SELECT":
                        {
                            // 개별 아이템 선택
                            var row = params.row;
                            var idx_item = params.event.target.value;

                            var row_item = {};
                            for (var idx = 0; idx < _this.list_item.length; idx++) {

                                if (_this.list_item[idx].NO == idx_item) {
                                    row_item = _this.list_item[idx];
                                    break;
                                }
                            }

                            // 값 변경
                            row.PRICE = parseInt(row_item.PRICE);
                            row.QTY = parseInt(row_item.QTY);
                            row.AMOUNT = parseInt(row.PRICE * row.QTY * 1.1);
                        }
                        break;

                    case "ITEM_EDIT_EACH":
                        {
                            // 개별 아이템 수정
                            var row = params.row;

                            row.PRICE = parseInt(row.PRICE);
                            row.QTY = parseInt(row.QTY);
                            row.AMOUNT = parseInt(row.PRICE * row.QTY * 1.1);
                        }
                        break;

                    case "EDIT_ITEM_TOT":
                        {
                            // 전체 아이템                            
                            var sumQty = 0;
                            var sumAmt = 0;

                            var list = _this.list_req_item;
                            for (var idx = 0; idx < list.length; idx++) {

                                var _no = list[idx].ITEM_NO;
                                var _qty = list[idx].QTY;
                                var _amt = list[idx].AMOUNT;
                                                                
                                sumAmt += _amt;

                                if (13 != _no) {
                                    sumQty += _qty;
                                }
                            }

                            _this.view_1.REQ_CNT = sumQty; // 의뢰건수
                            _this.view_1.AMOUNT = sumAmt;  // 총금액                            
                        }
                        break;

                    case "SAVE":
                        {
                            // 체크
                            var comp_name = $("[name='comp_name']");
                            if (!comp_name.val().trim()) {
                                alert("기관(학교)명 을 입력해주세요.");
                                comp_name.focus();
                                return false;
                            }

                            var pw = $("[name='pw']");
                            if (!pw.val().trim()) {
                                alert("비밀번호를 입력해주세요.");
                                pw.focus();
                                return false;
                            }

                            //if (confirm(INFO_CONF_SAVE))                             
                            {
                                // dimm 보이기
                                _this.$ShowDimmLogo();

                                // url 설정
                                url = "/vue_api/zadmin/gmo/req_save";

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.view_1 = data.VIEW_1;

                            if (!_this.view_1.CODE_AREA_1) _this.view_1.CODE_AREA_1 = "";
                            if (!_this.view_1.CODE_AREA_2) _this.view_1.CODE_AREA_2 = "";
                            if (!_this.view_1.COMP_GUBUN) _this.view_1.COMP_GUBUN = "";
                            if (!_this.view_1.DELIVERY_GUBUN) _this.view_1.DELIVERY_GUBUN = "";
                            if (!_this.view_1.SHOW_YN) _this.view_1.SHOW_YN = "Y";

                            // 검색
                            _this.exec("CODE_AREA_1", {});
                            _this.exec("GMO_COMP_GUBUN", {});
                            _this.exec("GMO_DELIVERY_GUBUN", {});
                            _this.exec("GMO_ITEM", {});
                            _this.exec("ITEM_LIST", {});
                        }
                        break;

                    case "CODE_AREA_1":
                        {
                            _this.list_area_1 = data.LIST_1;

                            _this.exec("CODE_AREA_2", {});
                        }
                        break;

                    case "CODE_AREA_2":
                        {
                            _this.list_area_2 = data.LIST_1;
                        }
                        break;

                    case "GMO_COMP_GUBUN":
                        {
                            _this.list_comp_gubun = data.LIST_1;
                        }
                        break;

                    case "GMO_DELIVERY_GUBUN":
                        {
                            _this.list_delivery_gubun = data.LIST_1;
                        }
                        break;

                    case "GMO_ITEM":
                        {
                            _this.list_item = data.LIST_1;
                        }
                        break;

                    case "ITEM_LIST":
                        {
                            _this.list_req_item = data.LIST_1;
                        }
                        break;

                    case "ITEM_ADD":
                        {
                            _this.list_req_item.push({ 'ITEM_NO': '', 'PRICE': '', 'QTY': 1, 'AMOUNT':''})
                        }
                        break;

                    case "ITEM_DEL":
                    case "ITEM_SELECT":
                    case "ITEM_EDIT_EACH":
                        {
                            _this.exec("EDIT_ITEM_TOT", {});
                        }
                        break;

                    case "SAVE":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>