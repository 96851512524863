<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_title />
            <!-------------------------- 인클루드(e) -------------------------->
            <!-- 서브 상단 영역 (s) -->
            <section>
                <div class="pu_subTitle01">
                    <article>
                        <h2>{{ getBase.userMenuName_3d }}</h2>
                        <p></p>
                    </article>
                </div>
                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">

                    <div class="pu_newsListDiv" v-show="check_pw_yn == 'N'">
                        <div class="pu_tableY">
                            <table style="margin:0 auto; max-width:400px;">
                                <thead>
                                    <tr>
                                        <th scope="col" class="list">게시물 비밀번호</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="pu_input_wrap" style="margin: 0 auto; float: unset;">
                                                <input type="password" name="searchtext" v-model="str_pw"
                                                       placeholder="비밀번호를 입력하세요" />

                                                <a class="pu_btn_red" href="javascript:;"
                                                   style="margin-left: 3px; padding: unset; width: 58px; min-width: unset; height: 32px; line-height: 32px; position: absolute;"
                                                   v-on:click="exec('CHECK_PW', {'curPage':1})">확인</a>
                                            </div>

                                            <span style="margin-top: 8px; background: none; color: red;">
                                                (의뢰서에 기입한 전화번호 뒤 4자리)
                                            </span>                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="pu_newsListDiv" v-show="check_pw_yn == 'Y'">
                        <div class="pu_table_view pu_tableX">
                            <table summary="">
                                <colgroup>
                                    <col style="width:15%;">
                                    <col style="width:*">
                                    <col style="width:15%;">
                                    <col style="width:*">
                                </colgroup>
                                <tbody>

                                    <tr>
                                        <th>
                                            의뢰일
                                        </th>
                                        <td colspan="3">
                                            {{ view_1.REQ_DATE }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            기관명
                                        </th>
                                        <td>
                                            {{ view_1.COMP_NAME }} ( {{ view_1.COMP_GUBUN_NAME }} )
                                        </td>
                                        <th>
                                            지역
                                        </th>
                                        <td>
                                            {{ view_1.CODE_AREA_NAME_1 }} {{ view_1.CODE_AREA_NAME_2 }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            금액
                                        </th>
                                        <td>
                                            {{ $ConMoney(view_1.AMOUNT) }} 원
                                        </td>
                                        <th>
                                            건수
                                        </th>
                                        <td>
                                            {{ view_1.REQ_CNT }} 건
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="4">
                                            <table summary="">
                                                <colgroup>
                                                    <col style="width:40%;">
                                                    <col style="width:20%;">
                                                    <col style="width:20%;">
                                                    <col style="width:20%;">
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>품목명</th>
                                                        <th>가격</th>
                                                        <th>수량</th>
                                                        <th>금액</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr v-for="(row, idx) in list_1">
                                                        <td style="text-align:center;">
                                                            {{ row.TITLE }}
                                                        </td>
                                                        <td style="text-align:center;">
                                                            {{ $ConMoney(row.PRICE) }}
                                                        </td>
                                                        <td style="text-align:center;">
                                                            {{ $ConMoney(row.QTY) }}
                                                        </td>
                                                        <td style="text-align:center;">
                                                            {{ $ConMoney(row.AMOUNT) }}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            입금일
                                        </th>
                                        <td>
                                            {{ view_1.DEPOSIT_DATE }}
                                        </td>
                                        <th>
                                            입금자명
                                        </th>
                                        <td>
                                            {{ view_1.DEPOSIT_NAME }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            발송일
                                        </th>
                                        <td>
                                            {{ view_1.DELIVERY_DATE }}
                                        </td>
                                        <th>
                                            발송방법
                                        </th>
                                        <td>
                                            {{ view_1.DELIVERY_GUBUN_NAME }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="pu_btnDiv01">
                                <a class="pu_btn_red" href="javascript:;" v-on:click="$PageMove('./req_list')"><i class="xi-bars"></i>목록</a>
                            </div>
                        </div>
                    </div>
                    <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
                </div>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->

        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "VIEW"

                , cnt_lim_pic: 50  // 사진 갯수

                , rownum: 0        // 번호
                , paging: ""       // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                , str_pw: ''
                , check_pw_yn: 'N'
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {
                    
                    case "CHECK_PW":
                        {
                            // url 설정
                            url = "/vue_api/user/gmo/check_pw";

                            // 폼
                            data["no"] = qs.no;
                            data["pw"] = _this.str_pw;
                        }
                        break;

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/gmo/req_view";

                            // 폼
                            data["no"] = qs.no;
                            data["pw"] = _this.str_pw;
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {
                    
                    case "CHECK_PW":
                        {
                            var map = data.VIEW_1;

                            if(map.CHECK_PW_YN == "Y"){
                            
                                // 검색
                                _this.exec("SEARCH", { "curPage": 1 });
                                _this.check_pw_yn = "Y";
                            }
                            else
                            {
                                _this.check_pw_yn = "N";

                                alert("게시물 비밀번호를 확인해주세요!");
                            }
                        }
                        break;

                    case "SEARCH":
                        {
                            _this.view_1 = data.VIEW_1;
                            _this.list_1 = data.LIST_1;

                            console.log(data.VIEW_1);
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>

<style>

    .pu_table_view {

    }

        .pu_table_view table tr th,
        .pu_table_view table tr td {
            padding: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            border-top: solid 1px #dddddd;
            border-right: solid 1px #dddddd;
        }

            .pu_table_view table tr th:first-child,
            .pu_table_view table tr td:first-child {                
                border-left: solid 1px #dddddd;
            }

</style>