<template>
    <!-- top title (s) -->
    <section class="pu_locationSection">
        <h2>{{ this.getBase.adminMenuName_3d }}</h2>
        <div>
            <ul class="pu_location">
                <li><a href="javascript:;"><i class="xi-home">홈</i></a></li>
                <li v-html="this.getBase.adminMenuName_1d"></li>
                <li v-html="this.getBase.adminMenuName_2d"></li>
                <li v-html="this.getBase.adminMenuName_3d"></li>
            </ul>
            <div class="pu_logout">
                <a class="xi-unlock-o" href="javascript:;" v-on:click="logOut()" title="로그아웃"><span>로그아웃</span></a>
            </div>
        </div>
    </section>
    <!-- top title (e) -->
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase",
            }),

        }

        , data() {
            return {
                THIS_NAME: "INC ZADMIN TITLE"
            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {
            }

            // 저장
            , logOut: function () {

                if (confirm("로그아웃 하시겠습니까?")) {

                    // API 조회
                    this.$SendPost("/vue_api/zadmin/logout", {}, this.logOutReturn);
                }
            }

            // 저장 데이터
            , logOutReturn: function (data, err) {
                this.$PageMove("/zadmin");
            }

        },

        beforeCreate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeCreate");
        },

        created() {
            // this.$PrintLog(this.THIS_NAME + " : created");
        },

        beforeMount() {
            // this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.default();
        },

        beforeUpdate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            // this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
            // this.$PrintLog(this.THIS_NAME + " : beforeDestroy");
        },

        destroyed() {
            // this.$PrintLog(this.THIS_NAME + " : destroyed");
        },
    };
</script>
