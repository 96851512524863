<template>
    <!-- 서브 상단 영역 (s) -->
    <section class="pu_layoutTop">
        <div class="pu_subVisual pu_sub01">
            <dl>
                <dt>{{ getBase.userMenuName_3d }}</dt>
                <dd>정부 및 대 기업체의 양돈관련 연구 프로젝트를 수행 중에 있습니다.</dd>
            </dl>
        </div>
        <!-- 서브 메뉴표시 (s) -->
        <div class="pu_locationWrap">
            <div class="pu_locationMenu">
                <a href="javascript:;" v-on:click="$PageMove('/user/home')" class="pu_home"><i class="xi-home-o"></i></a>
                <ul class="pu_lnbList">
                    
                    <li>
                        <div class="pu_select_wrap">
                            <a class="pu_txtwrap"><span>{{ getBase.userMenuName_1d }}</span> <i class="xi-angle-down-min"></i></a>
                            <div class="pu_select_list">
                                <ul class="pu_select_menu">

                                    <li v-for="(d1, idx_d1) in getBase.mapUserMenu['000|000|000|']"
                                        v-bind:key="idx_d1">

                                        <a href="javascript:;"
                                           v-on:click="$PageMove(d1.URL)"
                                           v-html="d1.TITLE"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    
                    <li>
                        <div class="pu_select_wrap">
                            <a class="pu_txtwrap"><span>{{ getBase.userMenuName_2d }}</span><i class="xi-angle-down-min"></i></a>
                            <div class="pu_select_list">
                                <ul class="pu_select_menu">

                                    <li v-for="(d2, idx_d2) in getBase.mapUserMenu[getBase.userMenuCode_1d.substring(0, 4) + '000|000|']"
                                        v-bind:key="idx_d2">

                                        <a href="javascript:;"
                                           v-on:click="$PageMove(d2.URL)"
                                           v-html="d2.TITLE"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    
                    <li>
                        <div class="pu_select_wrap">
                            <a class="pu_txtwrap"><span>{{ getBase.userMenuName_3d }}</span><i class="xi-angle-down-min"></i></a>
                            <div class="pu_select_list">
                                <ul class="pu_select_menu">

                                    <li v-for="(d3, idx_d3) in getBase.mapUserMenu[getBase.userMenuCode_2d.substring(0, 8) + '000|']"
                                        v-bind:key="idx_d3">

                                        <a href="javascript:;"
                                           v-on:click="$PageMove(d3.URL)"
                                           v-html="d3.TITLE"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 서브 메뉴표시 (e) -->
    </section>
    <!-- 서브 상단 영역 (s) -->
</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        },

        data() {
            return {
                THIS_NAME: "INC USER TITLE"
            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 시작
                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "INIT":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "INIT":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }
            }
        },

        beforeCreate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeCreate");
        },

        created() {
            // this.$PrintLog(this.THIS_NAME + " : created");
        },

        beforeMount() {
            // this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.default();
        },

        beforeUpdate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            // this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
            // this.$PrintLog(this.THIS_NAME + " : beforeDestroy");
        },

        destroyed() {
            // this.$PrintLog(this.THIS_NAME + " : destroyed");
        },
    };
</script>
