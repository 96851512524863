<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_zadmin_head />
            <inc_zadmin_left />
            <!-------------------------- 인클루드(e) -------------------------->

            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">

                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_zadmin_title />
                    <!-------------------------- 인클루드(e) -------------------------->

                    <!-- 검색조건 (s) -->
                    <section class="pu_searchSection">
                        <form id="searchForm" class="pu_searchBox" onsubmit="return false">

                            <dl>
                                <dt v-if="getBase.curQsMap.page_gubun == 'B'" 
                                    class="pu_wd90"><label for="">권한</label></dt>

                                <dd v-if="getBase.curQsMap.page_gubun == 'B'">
                                    <select name="grade_sel" class="pu_select-box" title="조건을 선택하세요." style="width:150px">
                                        <option value="" selected>전체</option>
                                        <option value="A">유료회원</option>
                                        <option value="B">일반회원</option>
                                    </select>
                                </dd>

                                <dt class="pu_wd140"><label for="">이메일 수신동의</label></dt>
                                <dd>
                                    <select name="receiveagreeemail" class="pu_select-box" title="조건을 선택하세요." style="width:150px">
                                        <option value="" selected>전체</option>
                                        <option value="1">동의</option>
                                        <option value="0">미동의</option>
                                    </select>
                                </dd>

                                <dt class="pu_ml15 pu_wd130"><label for="">SMS 수신동의</label></dt>
                                <dd>
                                    <select name="receiveagreesms" class="pu_select-box" title="조건을 선택하세요." style="width:150px">
                                        <option value="" selected>전체</option>
                                        <option value="1">동의</option>
                                        <option value="0">미동의</option>
                                    </select>
                                </dd>
                            </dl>

                            <dl>
                                <dt class="pu_wd90"><label for="">가입일자</label></dt>
                                <dd>
                                    <div class="pu_dateSelect">
                                        <ul class="pu_date_calendar" style="width:350px">
                                            <li>
                                                <input type="text" name="search_regi_date_start" class="pu_calendar" readonly="readonly"
                                                       v-model:value="search_regi_date_start" onclick="pjh_calendar(undefined, '[name=search_regi_date_start]', 'YYYY-MM-DD')" />
                                                <input type="image" src="/images/icon_calendar.png" />
                                            </li>
                                            <li>~</li>
                                            <li>
                                                <input type="text" name="search_regi_date_end" class="pu_calendar" readonly="readonly"
                                                       v-model:value="search_regi_date_end" onclick="pjh_calendar(undefined, '[name=search_regi_date_end]', 'YYYY-MM-DD')" />
                                                <input type="image" src="/images/icon_calendar.png" />
                                            </li>
                                        </ul>
                                        <ul class="pu_date_day">
                                            <!-- 빠른선택 -->
                                            <li v-for="item in datebutton">
                                                <a href="javascript:;" v-on:click="setSearchdate(item.startdate, item.enddate)">{{ item.title }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </dd>
                            </dl>

                            <dl>
                                <dt class="pu_wd90"><label for="">검색조건</label></dt>
                                <dd>
                                    <select name="searchtype" class="pu_select-box" title="조건을 선택하세요." style="width:150px">
                                        <option value="NAME">이름</option>
                                        <option value="ID">아이디</option>
                                    </select>
                                    <input type="text" name="searchtext" placeholder="검색어를 입력해주세요." style="width:300px">
                                </dd>
                            </dl>
                        </form>
                        <div class="pu_searchBtn"><a class="xi-search" href="javascript:;" v-on:click="exec('SEARCH', {'curPage': 1})"><span>검색</span></a></div>
                    </section>
                    <!-- 검색조건(e) -->
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <p class="pu_listNum">
                                <i class="xi-user"></i> 가입 회원 <strong>{{ rownum }}</strong>명
                            </p>
                            <div class="pu_TableX01">
                                <table>
                                    <colgroup>
                                        <col width="70px">
                                        <col width="80px">
                                        <col width="10%">
                                        <col width="*">
                                        <col width="11%">
                                        <col width="10%">
                                        <col width="9%">
                                        <col width="9%">
                                        <col width="11%">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">번호</th>
                                            <th scope="col">권한</th>
                                            <th scope="col">이름</th>
                                            <th scope="col">아이디(이메일)</th>
                                            <th scope="col">연락처</th>
                                            <th scope="col">가입일</th>
                                            <th scope="col">이메일 수신동의</th>
                                            <th scope="col">SMS 수신동의</th>
                                            <th scope="col">최근 접속일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row, idx) in list_1">
                                            <td>{{ getRowNum(idx) }}</td>
                                            <td>{{ gradeName(row.GRADE) }}</td>
                                            <td><a href="javascript:;" v-on:click="$PageMove('./user_regi?no=' + row.NO)">{{ row.NAME }}</a></td>
                                            <td><a href="javascript:;" v-on:click="$PageMove('./user_regi?no=' + row.NO)">{{ row.ID }}</a></td>
                                            <td>{{ row.PHONE }}</td>
                                            <td>{{ row.REGI_DATE }}</td>
                                            <td>{{ row.RECEIVEAGREEEMAIL == "1" ? "동의" : "미동의" }}</td>
                                            <td>{{ row.RECEIVEAGREESMS == "1" ? "동의" : "미동의" }}</td>
                                            <td>{{ row.LASTLOGIN_DATE }}</td>
                                        </tr>
                                        <tr v-if="list_1.length == 0">
                                            <td colspan="9">조회된 결과가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->
                        <!-- 하단 페이징영역 (s)  -->
                        <div class="pu_paging">
                            <div class="pu_pagination" v-html="paging">
                            </div>
                        </div>
                        <!-- 하단 페이징영역 (d)  -->

                    </section>
                    <!-- 컨텐츠영역 (e) -->

                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    function getStringDate(d) {
        return d.getFullYear() + "-" + ("00" + (d.getMonth() + 1)).slice(-2) + "-" + ("00" + d.getDate()).slice(-2);
    }

    // export
    export default {
        
        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            var curDate = new Date();
            var firstDate = new Date(curDate.getFullYear(), curDate.getMonth(), 1);
            var lastDate = new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0);

            var arrDate = [];
            for (var i = 0; i <= 12; i++) {
                var firstIDate = new Date(firstDate.getFullYear(), firstDate.getMonth() - i, firstDate.getDate());
                var lastIDate = new Date(firstIDate.getFullYear(), firstIDate.getMonth() + 1, 0);
                var curIDate = new Date(firstIDate.getFullYear(), firstIDate.getMonth(), lastIDate.getDate() < curDate.getDate() ? lastIDate.getDate() : curDate.getDate());
                arrDate.push({
                    "first": firstIDate,
                    "cur": curIDate,
                    "last": lastIDate
                });
            }

            var datebutton = [
                {
                    "title": (arrDate[0]["cur"].getFullYear() + "년 ").slice("-4") + (arrDate[0]["cur"].getMonth() + 1) + "월",
                    "startdate": getStringDate(arrDate[0]["first"]),
                    "enddate": getStringDate(arrDate[0]["last"])
                },
                {
                    "title": (arrDate[1]["cur"].getFullYear() + "년 ").slice("-4") + (arrDate[1]["cur"].getMonth() + 1) + "월",
                    "startdate": getStringDate(arrDate[1]["first"]),
                    "enddate": getStringDate(arrDate[1]["last"])
                },
                {
                    "title": (arrDate[2]["cur"].getFullYear() + "년 ").slice("-4") + (arrDate[2]["cur"].getMonth() + 1) + "월",
                    "startdate": getStringDate(arrDate[2]["first"]),
                    "enddate": getStringDate(arrDate[2]["last"])
                },
                { "title": "최근 1개월", "startdate": getStringDate(arrDate[1]["cur"]), "enddate": getStringDate(arrDate[0]["cur"]) },
                { "title": "최근 6개월", "startdate": getStringDate(arrDate[6]["cur"]), "enddate": getStringDate(arrDate[0]["cur"]) },
                { "title": "최근 1년", "startdate": getStringDate(arrDate[12]["cur"]), "enddate": getStringDate(arrDate[0]["cur"]) }
            ];

            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                , datebutton: datebutton                                    // 날짜버튼
                , search_regi_date_start: getStringDate(arrDate[12]["cur"]) // 시작일
                , search_regi_date_end: getStringDate(arrDate[0]["cur"])    // 종료일                
            }
        }

        , methods: {
            
            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", {"curPage": 1});
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/homepage/user_list/list";

                            // 폼
                            data["gubun_page"] = _this.gubun_page;
                            data["curPage"] = params.curPage;
                            data["grade"] = qs.page_gubun;

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    default:
                        {
                            
                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.rownum = data.ROWNUM;
                            _this.paging = data.PAGING;
                            _this.list_1 = data.LIST_1;
                            _this.list_2 = data.LIST_2;
                            _this.list_3 = data.LIST_3;
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // 달력 기간 설정
            , setSearchdate(startdate, enddate) {
                this.search_regi_date_start = startdate;
                this.search_regi_date_end = enddate;
            }

            // 권한명
            , gradeName: function (grade) {

                if (grade == 'A')
                    return "유료회원";
                else if (grade == 'B')
                    return "일반회원";
                else if (grade == 'C')
                    return "휴면회원";
                else if (grade == 'D')
                    return "탈퇴회원";
                else
                    return "";

            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>