<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_zadmin_head />
            <inc_zadmin_left />
            <!-------------------------- 인클루드(e) -------------------------->

            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">

                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_zadmin_title />
                    <!-------------------------- 인클루드(e) -------------------------->
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">
                                <form id="regiForm" onsubmit="return false">
                                    <table>
                                        <colgroup>
                                            <col width="15%">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>제목*</th>
                                                <td><input type="text" name="title" v-model="view_1.TITLE" style="width: 100%" /></td>
                                            </tr>
                                            <tr>
                                                <th>내용</th>
                                                <td>
                                                    <textarea name="contents" class="pu_input_textarea" v-html="view_1.CONTENTS"></textarea>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>적용여부</th>
                                                <td>
                                                    <input type="radio" id="showyn1" name="show_yn" value="Y" v-model="view_1.SHOW_YN"><label for="showyn1">적용</label>
                                                    <input type="radio" id="showyn2" name="show_yn" value="N" v-model="view_1.SHOW_YN"><label for="showyn2">미적용</label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->
                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_left">
                                <a class="pu_btn_dark" href="javascript:history.back();"><i class="xi-close"></i> 취소</a>
                                <a class="pu_btn_blue" href="javascript:;" v-on:click="exec('SAVE', {})"><i class="xi-check"></i> 저장</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "REGI"

                , cnt_lim_pic: 100  // 사진 갯수

                , rownum: 0        // 번호
                , paging: ""       // 페이징
                
                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/board/cms/view";

                            // 폼
                            data["no"] = qs.no;
                        }
                        break;

                    case "SAVE":
                        {
                            // 체크
                            var title = $("[name='title']");
                            if (!title.val().trim()) {
                                alert("제목를 입력해주세요");
                                title.focus();
                                return false;
                            }

                            // 내용 업데이트
                            CKEDITOR.instances.contents.updateElement();
                                                        
                            var contents = $("[name='contents']");
                            if (!contents.val().trim()) {
                                alert("내용을 입력해주세요");
                                contents.focus();
                                return false;
                            }

                            //if (confirm(INFO_CONF_SAVE))                             
                            {
                                // dimm 보이기
                                _this.$ShowDimmLogo();

                                // url 설정
                                url = "/vue_api/zadmin/board/cms/save";
                                
                                // 폼
                                data["no"] = qs.no;

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));
                            }
                        }
                        break;

                    case "CKEDITOR":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.view_1 = data.VIEW_1;

                            // CK 에디터 설정
                            _this.exec("CKEDITOR", {});
                        }
                        break;
                        
                    case "SAVE":
                        {
                            
                        }
                        break;

                    case "CKEDITOR":
                        {
                            _this.setCkEditor();
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // ck editor 세팅
            , setCkEditor: function () {

                setTimeout(() => {

                    try {
                        setCkEditorDefault("contents", "board_cms", this.cnt_lim_pic, ".jpeg,.jpg,.png");
                    } catch (err) {
                        this.setCkEditor();
                    }

                }, 100);
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>