<template>
    <div></div>
</template>

<script>
    export default {

        components: {
        },

        data() {
            return {};
        },

        methods: {
        },

        beforeCreate() {

            let hostname = window.location.hostname;
            if (hostname.indexOf("gmodna") > -1) {

                // 실험실로 들어오면 센터인증서로
                this.$router.push("/user/board/cms_view?page_gubun=62");
            }
            else {

                // 홈페이지 메인으로
                this.$router.push("/user/home");
            }
        },

        created() {
        },

        beforeMount() {
        },

        mounted() {
        },

        beforeUpdate() {
        },

        updated() {
        },

        beforeDestroy() {
        },

        destroyed() {
        },

    };
</script>
