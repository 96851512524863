<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_title />
            <!-------------------------- 인클루드(e) -------------------------->
            <!-- 서브 상단 영역 (s) -->
            <section>
                <div class="pu_subTitle01">
                    <article>
                        <h2>{{ getBase.userMenuName_3d }}</h2>
                        <p></p>
                    </article>
                </div>
                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">
                    <div class="pu_newsListDiv" style="margin:0 auto; max-width:800px;">
                        <!--  -->
                        <!--  -->
                        <div class="pu_tableX">
                            <table summary="">
                                <thead>
                                    <tr>
                                        <th scope="col">{{ view_1.TITLE }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <ul class="pu_newsTopInofo">
                                                <li><p class="pu_subject"><span>작성일 : </span></p> <p class="pu_info">{{ view_1.REGI_DATE }}</p> </li>
                                                <li><p class="pu_subject"><span>조회 : </span></p> <p class="pu_info"><i class="xi-eye-o"></i> {{ view_1.VIEWCOUNT}}</p> </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="pu_contents" v-html="$ConHtml(view_1.CONTENTS)" style="min-height: 600px;">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="pu_btnDiv01">
                                <a class="pu_btn_red" href="javascript:;" v-on:click="$PageMove('./normal?page_gubun=' + view_1.GUBUN)"><i class="xi-bars"></i>목록</a>
                            </div>
                            <!--  -->
                        </div>
                    </div>
                    <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
                </div>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->

        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {
        
        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }
        
        , data() {

            return {
                gubun_page: "VIEW"

                , cnt_lim_pic: 50  // 사진 갯수

                , rownum: 0        // 번호
                , paging: ""       // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {
            
            ...mapActions({
                setBase: "setBase"
            })
            
            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/board/normal/view";

                            // 폼
                            data["no"] = qs.no;
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.view_1 = data.VIEW_1;
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>