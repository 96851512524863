<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">
            <!-- 왼쪽 메뉴 layout(s) -->
                <div id="AdminNav" class="pu_LnbWrap">
                    <a  class="pu_openbtn" style="display: none;" onclick="openNav()" title="메뉴펼쳐보기"><i class="xi-bars xi-x2"></i></a>
                    <h1 class="pu_toggleN">㈜정P&C연구소 
                        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()" title="메뉴닫기"><i class="xi-dedent xi-x"></i></a>
                    </h1>
                    <nav>
                        <div class="pu_lnbmenu">
                            <ul>
                                <li><a href=""><span class="xi-devices"></span><span>㈜정P&C연구소</span></a></li>
                                <li><a href=""><span class="xi-lightbulb-o"></span><span>연구/사업</span></a></li>
                                <li><a href=""><span class="xi-chart-pie"></span><span>분석센터</span></a></li>
                                <li><a href=""><span class="xi-browser-text"></span><span>양돈정보(유료)</span></a></li>
                                <li><a href=""><span class="xi-cart-o"></span><span>JPNC Mall</span></a></li>
                                <li class="pu_on"><a href=""><span class="xi-pen"></span><span>홈페이지관리</span></a></li>
                            </ul>
                        </div>
                            <div class="pu_submenu">
                                    <ul class="pu_accordion-menu">
                                        <li><div class="pu_dropdownlink">배너관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">메인배너관리</a></li>
                                                <li><a href="">하단배너관리</a></li>
                                                <li><a href="">JPNC몰 배너관리</a></li>
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">팝업관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">메인팝업관리</a></li>                                            
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">회원관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">기업회원</a></li>
                                                <li><a href="">휴먼회원</a></li>
                                                <li><a href="">탈퇴회원</a></li>
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">관리자관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">관리자계정관리</a></li>
                                                <li><a href="">관리자접속이력</a></li>
                                            </ul>
                                        </li>
                                        <li class="pu_open"><div class="pu_dropdownlink">메뉴관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li class="pu_on"><a href="">사용자 메뉴관리</a></li>
                                                <li><a href="">관리자 메뉴관리</a></li>
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">footer관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">회사관리</a></li>
                                                <li><a href="">이용약관</a></li>
                                                <li><a href="">개인정보취급방침</a></li>
                                            </ul>
                                        </li>
                                        </ul>
                                      
                            <!-- submenu E -->
     
                        </div>
                    </nav>
                </div>
                <!--왼쪽 메뉴 layout(e) -->

                <!-- 컨텐츠 layout (s) -->
                <div id ="main" class="pu_ContentsWrap">
                    <div class="pu_ContentsDiv">
                        <!-- top title (s) -->
                        <section class="pu_locationSection">
                            <h2>사용자 메뉴관리</h2>
                            <div>
                                <ul class="pu_location">
                                    <li><a href="#"><i class="xi-home">홈</i></a></li>
                                    <li>홈페이지관리</li>
                                    <li>메뉴관리 </li>
                                    <li>사용자 메뉴관리 </li>
                                </ul>
                                <div class="pu_logout"><a href="login.html" class="xi-unlock-o" title="로그아웃"><span>로그아웃</span></a></div>
                            </div>
                        </section>
                        <!-- top title (e) -->

                       

                        <!-- 컨텐츠영역 (s) -->
                        <section class="pu_contentsSection pu_pd15">

                          <div class="pu_pu_MenuSettingDiv">
                            <!-- 메뉴관리 (s) -->
                            <div class="pu_MenuSetting">
                                <div class="pu_MenuSettingTop">
                                    <h3 class="pu_f_left"><i class="xi-comment-o"></i> 대메뉴</h3>
                                    <div class="pu_f_right">
                                        <a href="" class="pu_ss_btn_dark">상단이동</a>
                                        <a href="" class="pu_ss_btn_dark">하단이동</a>
                                    </div>
                                </div>
                                <div class="pu_TableX01">
                                    <table class="pu_zebra">
                                        <colgroup>
                                            <col width="*">
                                            <col span="2" width="18%">
                                          </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">목록</th>
                                                <th scope="col">삭제</th>
                                                <th scope="col">선택</th>
                                              </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="pu_leftTxt">㈜정P&C연구소</td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">연구/사업</td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">분석센터</td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_move.png" title="이동"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">양돈정보</td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">JPNC몰</td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">홈페이지관리</td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="pu_footBtn01">
                                    <button data-popname="popup01" class="pu_call_pop"> 대메뉴 추가 시, 이곳을 클릭하여 주세요 <i class="xi-angle-right"></i></button>
                                </div>
                            </div>
                            <!-- 메뉴관리 (s) -->
                            <!-- 메뉴관리 (s) -->
                            <div class="pu_MenuSetting">
                                <div class="pu_MenuSettingTop">
                                    <h3 class="pu_f_left"><i class="xi-comment-o"></i> 중메뉴</h3>
                                    <div class="pu_f_right">
                                        <a href="" class="pu_ss_btn_dark">상단이동</a>
                                        <a href="" class="pu_ss_btn_dark">하단이동</a>
                                    </div>
                                </div>
                                <div class="pu_TableX01">
                                    <table class="pu_zebra">
                                        <colgroup>
                                            <col width="*">
                                            <col span="3" width="16%">
                                          </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">목록</th>
                                                <th scope="col">설정</th>
                                                <th scope="col">삭제</th>
                                                <th scope="col">선택</th>
                                              </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="pu_leftTxt">메뉴명_메인팝업관리</td>
                                                <td><input type="image" src="/images/icon_setting.png" title="설정"></td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">메뉴명_메인팝업관리</td>
                                                <td><input type="image" src="/images/icon_setting.png" title="설정"></td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_move.png" title="이동"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">메뉴명_메인팝업관리</td>
                                                <td><input type="image" src="/images/icon_setting.png" title="설정"></td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">메뉴명_메인팝업관리</td>
                                                <td><input type="image" src="/images/icon_setting.png" title="설정"></td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">메뉴명_메인팝업관리</td>
                                                <td><input type="image" src="/images/icon_setting.png" title="설정"></td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">메뉴명_메인팝업관리</td>
                                                <td><input type="image" src="/images/icon_setting.png" title="설정"></td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">메뉴명_메인팝업관리</td>
                                                <td><input type="image" src="/images/icon_setting.png" title="설정"></td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">메뉴명_메인팝업관리</td>
                                                <td><input type="image" src="/images/icon_setting.png" title="설정"></td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                              <tr>
                                                <td class="pu_leftTxt">메뉴명_메인팝업관리</td>
                                                <td><input type="image" src="/images/icon_setting.png" title="설정"></td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="pu_footBtn01">
                                    <button data-popname="popup01" class="pu_call_pop"> 중메뉴 추가 시, 이곳을 클릭하여 주세요 <i class="xi-angle-right"></i></button>
                                </div>
                            </div>
                            <!-- 메뉴관리 (s) -->
                            <!-- 메뉴관리 (s) -->
                            <div class="pu_MenuSetting">
                                <div class="pu_MenuSettingTop">
                                    <h3 class="pu_f_left"><i class="xi-comment-o"></i> 소메뉴</h3>
                                    <div class="pu_f_right">
                                        <a href="" class="pu_ss_btn_dark">상단이동</a>
                                        <a href="" class="pu_ss_btn_dark">하단이동</a>
                                    </div>
                                </div>
                                <div class="pu_TableX01">
                                    <table class="pu_zebra">
                                        <colgroup>
                                            <col width="*">
                                            <col span="3" width="16%">
                                          </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">목록</th>
                                                <th scope="col">설정</th>
                                                <th scope="col">삭제</th>
                                                <th scope="col">선택</th>
                                              </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="pu_leftTxt">메뉴명_메인팝업관리</td>
                                                <td><input type="image" src="/images/icon_setting.png" title="설정"></td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제"></td>
                                                <td><input type="image" src="/images/icon_check.png" title="선택"></td>
                                              </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="pu_footBtn01">
                                    <button data-popname="popup01" class="pu_call_pop">소메뉴 추가 시, 이곳을 클릭하여 주세요 <i class="xi-angle-right"></i></button>
                                </div>
                            </div>
                            <!-- 메뉴관리 (s) -->
                          </div>

                        </section>
                        <!-- 컨텐츠영역 (e) -->

                    </div>
                    <div class="pb20"></div>
                </div>
                <!-- 컨텐츠 layout  (e) -->
        </div>

    </div>



</template>
<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGIFORM"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                , id: "" // 아이디
                , pw: "" // 비밀번호

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // enter 키 이벤트
                this.$SetEventEnter("id", function () { $("[name='pw']").focus(); });
                this.$SetEventEnter("pw", this.saveData);

                // dimm 숨기기
                this.$HideDimmLogo();

            }

            // 저장
            , saveData () {

                // 비밀번호
                var id = $("[name='id']");
                if (!id.val().trim()) {
                    alert("아이디를 입력해주세요");
                    id.focus();
                    return false;
                }

                // 비밀번호
                var pw = $("[name='pw']");
                if (!pw.val().trim()) {
                    alert("비밀번호를 입력해주세요");
                    pw.focus();
                    return false;
                }
                
                let qs = this.getBase.curQsMap;
                var data = this.$FormToMap($("#regiForm"));
                data["gubun_page"] = this.gubun_page;

                console.log(pw);

                // API 조회
                this.$SendPost("/vue_api/zadmin/login", data, this.saveEventReturn);

            }

            // 저장 데이터
            , saveEventReturn (data, err) {

            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>