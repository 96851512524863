<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">
                    <inc_zadmin_title />
                    <!-- 검색조건 (s) -->
                    <section class="pu_searchSection">
                        <form id="searchForm" class="pu_searchBox" onsubmit="return false">
                            <dl>
                                <dt class="pu_wd90"><label for="">검색조건</label></dt>
                                <dd>
                                    <select name="searchtype" class="pu_select-box" v-model="searchtypevalue" title="조건을 선택하세요." style="width:150px">
                                        <option value="title">제목</option>
                                    </select>
                                    <input name="searchtext" type="text" placeholder="제목을 입력해주세요." style="width:300px">
                                </dd>
                            </dl>
                            <dl>
                                <dt class="pu_wd90"><label for="">적용여부</label></dt>
                                <dd>
                                    <div>
                                        <input type="radio" id="searchshowyn01" name="show_yn" value="" checked=""><label for="searchshowyn01">전체</label>
                                        <input type="radio" id="searchshowyn02" name="show_yn" value="Y"><label for="searchshowyn02">적용</label>
                                        <input type="radio" id="searchshowyn03" name="show_yn" value="N"><label for="searchshowyn03">미적용</label>
                                    </div>
                                </dd>
                            </dl>
                        </form>
                        <div class="pu_searchBtn"><a class="xi-search" href="javascript:" v-on:click="getSearchData(1)"><span>검색</span></a></div>
                    </section>
                    <!-- 검색조건(e) -->
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <p class="pu_listNum">
                                <i class="xi-paper-o"></i> 총 <strong>{{ rownum }}</strong>개의 게시물이 있습니다.
                            </p>
                            <div class="pu_TableX01">
                                <table>
                                    <colgroup>
                                        <col width="4%">
                                        <col width="5%">
                                        <col width="7%">
                                        <col width="*">
                                        <col width="5%">
                                        <col width="6%">
                                        <col width="15%">
                                        <col width="6%">
                                        <col width="6%">
                                        <col width="8%">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col"><input type="checkbox" v-model="selectAll" class="pu_tableCheck"></th>
                                            <th scope="col">번호</th>
                                            <th scope="col">순서</th>
                                            <th scope="col">제목</th>
                                            <th scope="col">이미지</th>
                                            <th scope="col">링크</th>
                                            <th scope="col">게시기간</th>
                                            <th scope="col">적용여부</th>
                                            <th scope="col">등록자</th>
                                            <th scope="col">등록일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row, idx) in list_1">
                                            <td><input type="checkbox" v-model="selectedrows" v-bind:value="row.NO" class="pu_tableCheck"></td>
                                            <td>{{ getRowNum(idx) }}</td>
                                            <td>
                                                <div class="pu_updownDiv">
                                                    <input type="image" v-on:click="moveRank(-1, row.NO)" src="/images/icon_dropup.png" title="위로">
                                                    <input type="image" v-on:click="moveRank(1, row.NO)" src="/images/icon_dropdown.png" title="아래로">
                                                </div>
                                            </td>
                                            <td><a href="javascript:;" v-on:click="$PageMove('./board_mainbanner_view?no=' + row.NO)">{{ row.TITLE }}</a></td>
                                            <td><img v-bind:src="row.IMAGEURL" style="max-width: 100%" /></td>
                                            <td><a class="pu_ss_btn_dark" v-bind:href="row.LINK" v-show="row.LINK">바로가기</a></td>
                                            <td>{{ row.SDATE }} ~ {{ row.EDATE }}</td>
                                            <td><span v-bind:class="row.SHOW_YN == 'Y' ? 'pu_txtBlue' : 'pu_txtGrey'">{{ row.SHOW_YN == 'Y' ? '적용' : '미적용' }}</span></td>
                                            <td>{{ row.REGI_USERID }}</td>
                                            <td>{{ row.REGI_DATE }}</td>
                                        </tr>
                                        <tr v-if="list_1.length == 0">
                                            <td colspan="9">조회된 결과가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->
                        <!-- 하단 페이징영역 (s)  -->
                        <div class="pu_paging">
                            <div class="pu_pagination" v-html="paging">

                            </div>
                        </div>
                        <!-- 하단 페이징영역 (d)  -->
                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_left">
                                <a class="pu_btn_red" href="javascript:;" v-on:click="deleteData"><i class="xi-trash"></i> 선택삭제</a>
                                <a class="pu_btn_blue" href="javascript:;" v-on:click="$PageMove('./board_mainbanner_regi')"><i class="xi-pen"></i> 신규등록</a>
                            </article>
                            <article class="pu_f_right">                                
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->

                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {
            selectAll: {
                get: function () {
                    return this.list_1 ? this.list_1.length == this.selectedrows.length : false;
                },
                set: function (value) {
                    var selected = [];
                    if (value) {
                        this.list_1.forEach(function (row) {
                            selected.push(row.NO);
                        });
                    }
                    this.selectedrows = selected;
                }
            }
        }

        , data() {

            return {
                gubun_page: "board_mainbanner"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
                , searchtypevalue: "title"
                , selectedrows: []
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // dimm 숨기기
                this.getSearchData(1);

                this.$HideDimmLogo();
            }

            , getSearchData: function (curPage) {

                // qs
                let qs = this.getBase.curQsMap;

                // 폼
                var data = this.$FormToMap($("#searchForm"));
                data["gubun_page"] = this.gubun_page;
                data["curPage"] = curPage;

                // API 조회
                this.$SendPost("/vue_api/zadmin/homepage/board_mainbanner/list", data, this.searchDataReturn);

            }
            , searchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.rownum = data.ROWNUM;
                        this.paging = data.PAGING;
                        this.list_1 = data.LIST_1;
                        this.list_2 = data.LIST_2;
                        this.list_3 = data.LIST_3;

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }
            , refresh: function (data, err) {
                this.getSearchData(1);
            }
            , deleteData: function () {
                var data = { nos: this.selectedrows.join(",") };
                this.selectedrows = [];
                this.$SendPost("/vue_api/zadmin/homepage/board_mainbanner/delete", data, this.refresh);
            }, moveRank: function (direction, no) {
                var data = { no: no, step: direction };
                this.$SendPost("/vue_api/zadmin/homepage/board_mainbanner/rankswap", data, this.refresh);
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>