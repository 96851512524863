<template>
    <div class="pu_wrapper_pop">

        <inc_user_head />

        <div id="dev_img" class="pu_img"></div>

        <div class="pu_bottom">
            <a class="pu_btn_close_oneday" v-on:click="closeOneDay()">하루안보기</a>
            <a class="pu_btn_close" v-on:click="close()">닫기</a>
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "pop"

                , no: 0
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // qs
                let qs = this.getBase.curQsMap;

                // 변수
                this.no = this.$DecBase64(qs.no.replaceAll("%3D", "="));
                let img = this.$DecBase64(qs.img.replaceAll("%3D", "="));
                let link = this.$DecBase64(qs.link.replaceAll("%3D", "="));

                // 화면 창크기
                $(".pu_wrapper_pop, .pu_img").css("width", $(window).width() - 1);
                $(".pu_wrapper_pop, .pu_img").css("height", $(window).height() - 1);

                $("#dev_img").on("click", function () {

                    if (link) {
                        window.open(link);
                    }
                })

                // 이미지 로딩
                if (urlExists(img)) {
                    $("#dev_img").css({ "background": "url(" + img + ")" });
                }
                else {
                    $("#dev_img").css({ "background": "url(/images/pop_default.jpg)" });
                }

                // dimm 숨기기
                this.$HideDimmLogo();
            }

            , closeOneDay: function () {
                setCookie("pop_" + this.no, "Y", 1);
                this.close();
            }

            , close: function () {

                window.close();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>

<style>

    body {
        background: #ffffff;
    }

    .pu_wrapper_pop .pu_img {
        width: 100%;
        background-size: 100%, 100%, contain !important;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        transition: .5s ease;
        cursor: pointer;
    }

    .pu_wrapper_pop .pu_btn_img {
        width: 100%;
        display: block;
        cursor: pointer;
    }

        .pu_wrapper_pop .pu_btn_img img {
            width: 100%;
        }

    .pu_wrapper_pop .pu_bottom {
        width: 100%;
        height: 30px;
        line-height: 30px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        background-color: #000000;
    }

        .pu_wrapper_pop .pu_bottom .pu_btn_close_oneday {
            position: absolute;
            left: 20px;
            color: #dddddd;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
        }

        .pu_wrapper_pop .pu_bottom .pu_btn_close {
            position: absolute;
            right: 20px;
            color: #dddddd;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
        }
</style>