<template>
    <div class="pu_topHead">

        <!-- pc 메뉴 (s)-->
        <header>
            <div class="pu_blockWrap pu_headerG">

                <!--  최상단 회원/검색 (s)-->
                <div class="pu_gnbTop">
                    <div>
                        <div class="pu_topSearch" style="margin-right: 30px;">
                            <label for="serch" class="pu_blind">검색</label>
                            <input type="text" id="dev_search_text" name="dev_search_text" placeholder="검색어를 입력해주세요.">
                            <input type="button" class="pu_btnSerch01" v-on:click="exec('SEARCHALL')" title="검색">
                        </div>

                        <ul class="pu_members">

                            <li class="pu_alert">
                                <a v-show="getBase.userLoginYn == 'Y' && getBase.userGrade != 'A'" href="javascript:;" v-on:click="$PageMove('/user/member/paymember')">유료회원결제</a>
                            </li>

                            <li class="pu_join">
                                <a v-show="getBase.userLoginYn == 'N' " href="javascript:;" v-on:click="$PageMove('/user/member/join')">회원가입</a>
                                <a v-show="getBase.userLoginYn == 'Y' " href="javascript:;" v-on:click="$PageMove('/user/member/editmember')">
                                    <span style="color: #d8006b; font-weight: 400; ">{{ gradeName }}</span> 입니다
                                </a>
                            </li>

                            <li class="pu_login">
                                <a v-show="getBase.userLoginYn == 'N' " href="javascript:;" v-on:click="$PageMove('/user/member/login')">로그인</a>
                                <a v-show="getBase.userLoginYn == 'Y' " href="javascript:;" v-on:click="exec('LOGOUT')">로그아웃</a>
                            </li>

                        </ul>
                    </div>
                </div>

                <!--  최상단 회원/검색 (e)- -->
                <div class="pu_innerWrap">
                    <h1>
                        <a href="javascript:;" v-on:click="$PageMove('/user/home')">
                            <img src="/images/gnb_ci_01.png" alt="정p&c연구소">
                        </a>
                    </h1>

                    <div class="pu_gnb">

                        <ul class="pu_gnb_1depth">
                            <li class="pu_gnb_li"
                                v-for="(d1, idx_d1) in getBase.mapUserMenu['000|000|000|']"
                                v-bind:key="idx_d1">

                                <!--1뎁스-->
                                <a href="javascript:;"
                                   v-on:click="$PageMove(d1.URL)"
                                   v-html="d1.TITLE"></a>

                                <ul class="pu_gnb_2depth">
                                    <li v-for="(d2, idx_d2) in getBase.mapUserMenu[d1.CODE.substring(0, 4) + '000|000|']"
                                        v-bind:key="idx_d2">

                                        <!--2뎁스-->
                                        <a href="javascript:;"
                                           v-on:click="$PageMove(d2.URL)"
                                           v-html="d2.TITLE"></a>

                                        <ul class="pu_gnb_3depth">

                                            <li v-for="(d3, idx_d3) in getBase.mapUserMenu[d2.CODE.substring(0, 8) + '000|']"
                                                v-bind:key="idx_d3">

                                                <a href="javascript:;"
                                                   v-on:click="$PageMove(d3.URL)"
                                                   v-html="d3.TITLE"></a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <button class="pu_snb_btn"><i class="xi-bars xi-2x"></i></button>
                </div>

            </div>
        </header>
        <div class="pu_gnbMask"></div>

        <!-- 모바일 (e)-->
        <div class="pu_snb_wrap">
            <div class="pu_snb_innerWrap">
                <div class="pu_snb">
                    <ul class="pu_gnb_1depth">

                        <li class="pu_gnb_li"
                            v-for="(d1, idx_d1) in getBase.mapUserMenu['000|000|000|']"
                            v-bind:key="idx_d1">

                            <!--1뎁스-->
                            <p v-html="d1.TITLE"></p>

                            <ul class="pu_gnb_2depth" style="display:block;">

                                <li v-for="(d2, idx_d2) in getBase.mapUserMenu[d1.CODE.substring(0, 4) + '000|000|']"
                                    v-bind:key="idx_d2">

                                    <!--2뎁스-->
                                    <a href="javascript:;"
                                       v-on:click="$PageMove(d2.URL)"
                                       v-html="d2.TITLE"></a>

                                    <ul class="pu_gnb_3depth">

                                        <li v-for="(d3, idx_d3) in getBase.mapUserMenu[d2.CODE.substring(0, 8) + '000|']"
                                            v-bind:key="idx_d3">

                                            <!--3뎁스-->
                                            <a href="javascript:;"
                                               v-on:click="$PageMove(d3.URL)"
                                               v-html="d3.TITLE"></a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <button class="pu_snb_close_btn" type="button">닫기</button>
        </div>
        <div class="pu_mask"></div>
        <!-- 모바일 -->
    </div>

</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        },

        data() {
            return {
                THIS_NAME: "INC USER TOP",

                islogin: false, // 로그인 여부
                gradeName: "",  // 권한명
            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 시작
                _this.exec("MENU");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "MENU":
                        {
                            // data 설정
                            data["url"] = _this.getBase.curPath; // 현재 url 경로
                            data["qs"] = _this.getBase.curQs;    // 현재 url 쿼리스트링
                            data["menu_code"] = _this.getBase.userMenuCode; // 기존 메뉴 코드

                            // URL 설정
                            url = "/vue_api/common/menu/user";
                        }
                        break;

                    case "CSS":
                        {

                        }
                        break;

                    case "LOGOUT":
                        {
                            // data 설정

                            // URL 설정
                            url = "/vue_api/user/login_reset";
                        }
                        break;

                    case "SEARCHALL":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "MENU":
                        {
                            if (data.MENU_NAME_1D || Object.keys(_this.getBase.mapUserMenu).length < 1) {

                                _this.setBase({ "userMenuCode": data.MENU_CODE });       // 메뉴코드
                                _this.setBase({ "userMenuCode_1d": data.MENU_CODE_1D }); // 1뎁스 메뉴 코드
                                _this.setBase({ "userMenuCode_2d": data.MENU_CODE_2D }); // 2뎁스 메뉴 코드
                                _this.setBase({ "userMenuCode_3d": data.MENU_CODE_3D }); // 3뎁스 메뉴 코드
                                _this.setBase({ "userMenuName_1d": data.MENU_NAME_1D }); // 1뎁스 메뉴 명
                                _this.setBase({ "userMenuName_2d": data.MENU_NAME_2D }); // 2뎁스 메뉴 명
                                _this.setBase({ "userMenuName_3d": data.MENU_NAME_3D }); // 3뎁스 메뉴 명

                                _this.setBase({ "mapUserMenu": data.MAP_LIST_1 });       // 메뉴
                            }

                            if (_this.getBase.userGrade == "A") {
                                _this.gradeName = "유료회원";
                            } else {
                                _this.gradeName = "일반회원";
                            }

                            // CSS
                            _this.exec("CSS");
                        }
                        break;

                    case "CSS":
                        {
                            /*goto  top*/
                            var scrollToTopBtn = document.querySelector(".pu_ToTopBtn");
                            var rootElement = document.documentElement;

                            function handleScroll() {

                                // Do something on scroll
                                var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
                                if (rootElement.scrollTop / scrollTotal > 0.8) {
                                    // Show button
                                    scrollToTopBtn.classList.add("pu_showBtn");
                                } else {
                                    // Hide button
                                    scrollToTopBtn.classList.remove("pu_showBtn");
                                }
                            }

                            function scrollToTop() {
                                // Scroll to top logic
                                rootElement.scrollTo({
                                    top: 0,
                                    behavior: "smooth"
                                });
                            }

                            scrollToTopBtn.addEventListener("click", scrollToTop);
                            document.addEventListener("scroll", handleScroll);

                            /*gnb menu*/
                            $("header .pu_gnb").on({
                                "mouseover": function () {
                                    $(".pu_headerG").addClass("pu_gnb_on");
                                    $(".pu_headerG .pu_innerWrap").css("height", "auto");
                                    $(".pu_gnbMask").show();
                                },
                                "mouseout": function () {
                                    $(".pu_headerG").removeClass("pu_gnb_on");
                                    $(".pu_headerG .pu_innerWrap").css("height", "");
                                    $(".pu_gnbMask").hide();
                                }
                            });

                            /* 서브 */
                            $(".pu_snb_btn").on("click", function () {
                                $(".pu_snb_wrap").addClass("pu_snb_on");
                                $(".pu_mask").fadeIn();
                            });
                            $(".pu_snb_close_btn").on("click", function () {
                                $(".pu_snb_wrap").removeClass("pu_snb_on");
                                $(".pu_mask").fadeOut();
                            });
                            $(".pu_snb .pu_gnb_1depth > li > p").on("click", function () {
                                $(this).parent().toggleClass("pu_menu_on");
                                $(this).next(".pu_gnb_2depth").slideToggle();
                            });

                            /* 메인배너들 s*/
                            $(".pu_slide_box").init_slide();
                            $('#pu_MBanner').eclipse({
                                margin: 20,
                                autoplay: true,
                                interval: 4000,
                                autoControl: true
                            });
                            $('.pu_PContent').eclipse({
                                margin: 30,
                                slidesToShow: 3,
                                countIndex: 1,
                                autoplay: true,
                                interval: 5500,
                                autoControl: true
                            });
                            $('.pu_MContent').eclipse({
                                margin: 20,
                                slidesToShow: 1,
                                countIndex: 1,
                                autoplay: true,
                                interval: 5500,
                                autoControl: true
                            });
                            /* 메인배너들 e*/
                        }
                        break;

                    case "LOGOUT":
                        {
                            _this.setBase({ "userLoginYn": data.USER_LOGIN_YN });    // 로그인 여부
                            _this.setBase({ "userNo": data.USER_NO });               // 로그인 번호
                            _this.setBase({ "userGrade": data.USER_GRADE });         // 권한

                            _this.islogin = data.USER_LOGIN_YN == "Y" ? true : false;

                            _this.$PageMove('/user/member/login');
                        }
                        break;

                    case "SEARCHALL":
                        {
                            let words = $("#dev_search_text").val().trim();
                            if (words) {
                                _this.$PageMove('/user/findall/findall?words=' + words);
                            }
                        }
                        break;

                    default:
                        {

                        };
                }
            }
        },

        beforeCreate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeCreate");
        },

        created() {
            // this.$PrintLog(this.THIS_NAME + " : created");
        },

        beforeMount() {
            // this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.default();
        },

        beforeUpdate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            // this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
            // this.$PrintLog(this.THIS_NAME + " : beforeDestroy");
        },

        destroyed() {
            // this.$PrintLog(this.THIS_NAME + " : destroyed");
        },
    };
</script>
