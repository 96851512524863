<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-- 컨텐츠 영역 (s) -->
            <section>
                <div class="pu_subTitle01 loginTit">
                    <article>
                        <h2 class="pu_login">Member <span class="pu_txtColor4">Login</span></h2>
                        <p>정P&C연구소를 방문해주셔서 감사합니다.</p>
                    </article>
                </div>

                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">
                    <form id="regiForm" onsubmit="return false">
                        <div class="pu_loginDiv">
                            <ul>
                                <li class="pu_id">
                                    <label class="pu_blind">아이디</label>
                                    <input type="text" name="id" class="pu_loginput" placeholder="아이디 입력" autocomplete="off">
                                </li>
                                <li class="pu_pw">
                                    <label class="pu_blind">비밀번호</label>
                                    <input type="password" name="pw" class="pu_loginput" placeholder="비밀번호 입력" autocomplete="off">
                                </li>
                            </ul>

                            <div style="margin-top:17px; height:100px; position:relative;">

                                <input type="button" class="pu_loginbtn dev_btn_submit" value="로그인">

                                <a class="pu_newjoin" href="javascript:;" v-on:click="$PageMove('/user/member/join')" style="margin:0px; position:absolute; bottom:0px; left:0px;"><i class="xi-border-color"></i> 신규 회원가입</a>
                                <a class="idsearch" href="javascript:;" v-on:click="$PageMove('/user/member/findmember')" style="margin:0px; position:absolute; bottom:0px; right:0px;">아이디/비밀번호 찾기</a>
                            </div>
                        </div>
                    </form>
                </div>
                <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>

            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->
        <!-------------------------- 하단footer부분 (s) ------------------------>
        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>
        <inc_user_foot />
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGI"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })
            
            // 진입점
            , default() {

                // this
                let _this = this;
                
                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {
                    
                    case "SEARCH":
                        {

                        }
                        break;
                        
                    case "SAVE":
                        {
                            // 비밀번호
                            var id = $("[name='id']");
                            if (!id.val().trim()) {
                                alert("아이디를 입력해주세요");
                                id.focus();
                                return false;
                            }

                            // 비밀번호
                            var pw = $("[name='pw']");
                            if (!pw.val().trim()) {
                                alert("비밀번호를 입력해주세요");
                                pw.focus();
                                return false;
                            }

                            {
                                // dimm 보이기
                                this.$ShowDimmLogo();

                                // url 설정
                                url = "/vue_api/user/login";

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));
                            }                            
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {
                    
                    case "SEARCH":
                        {

                        }
                        break;
                        
                    case "SAVE":
                        {
                            
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>