<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-- 서브 상단 영역 (s) -->
            <section class="pu_layoutTop">
                <div class="pu_subVisual pu_sub04" style="height:190px;">
                    <dl>
                        <!--<dt>유료회원 결제</dt>-->
                        <!--<dd>정부 및 대 기업체의 양돈관련 연구 프로젝트를 수행 중에 있습니다.</dd>-->
                    </dl>
                </div>

                <!-- 서브 메뉴표시 (s) -->
                <div class="pu_locationWrap">
                    <div class="pu_locationMenu" style="width: fit-content;">
                        <a href="/" class="pu_home"><i class="xi-home-o"></i></a>
                        <ul class="pu_lnbList">

                            <li style="width: 200px; text-align: center;">
                                <div class="pu_select_wrap">
                                    <a class="pu_txtwrap" href="javascript:;" v-on:click="$PageMove('/user/member/editmember')"><span>회원정보 수정</span></a>
                                </div>
                            </li>

                            <li style="width: 200px; text-align: center;">
                                <div class="pu_select_wrap">
                                    <a class="pu_txtwrap" href="javascript:;" v-on:click="$PageMove('/user/member/paymember')"><span>월간 유료회원 결제</span></a>
                                </div>
                            </li>

                            <li style="width: 200px; text-align: center;">
                                <div class="pu_select_wrap">
                                    <a class="pu_txtwrap" href="javascript:;" v-on:click="$PageMove('/user/member/paylist')"><span>결제 내역</span></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 서브 메뉴표시 (e) -->
            </section>
            <!-- 서브 상단 영역 (s) -->
            <!-- 컨텐츠 영역 (s) -->
            <section>
                <div class="pu_subTitle01 loginTit">

                    <article v-if="getBase.userGrade != 'A'">
                        <h2 class="pu_login">월간 유료회원 <span class="pu_txtColor4">결제</span></h2>
                        <p>유료회원이 되시면 양질의 전문자료를 이용할수 있습니다.</p>
                    </article>

                    <article v-if="getBase.userGrade == 'A'">
                        <h2 class="pu_join">현재 <span class="pu_txtColor4">유료회원</span> 입니다.</h2>
                    </article>

                </div>

                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">

                    <div class="pu_joinDiv">
                        <div class="pu_tablejoin">
                            <div>
                                <img src="/images/img_intro_paymember.png"
                                     style="margin: 0 auto; width: 70%; display: block;" />
                            </div>
                        </div>
                    </div>
                </div>
                <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent" style="min-height: 250px !important;">

                    <div class="pu_joinDiv">
                        <div class="pu_tablejoin">

                            <div>
                                <div v-if="getBase.userGrade != 'A'" class="pu_btnDiv01">
                                    <div class="pu_member_pay_item">
                                        <div>
                                            <span>
                                                양돈 정보 제공
                                            </span>

                                            <ul>
                                                <li>
                                                    - 양돈 시세
                                                </li>
                                                <li>
                                                    - 시장 정보
                                                </li>
                                                <li>
                                                    - 세계 사료곡물 동향
                                                </li>
                                                <li>
                                                    - 신기술/신정보
                                                </li>
                                                <li>
                                                    - 질병정보
                                                </li>
                                            </ul>
                                        </div>

                                        <a class="pu_btn_red" href="javascript:;" v-on:click="exec('PAY', {type:'1', amount:amountMemberPay_1})">
                                            1개월(30일) 결제<br />₩{{ $ConMoney(amountMemberPay_1) }}원
                                        </a>
                                    </div>

                                    <div class="pu_member_pay_item">
                                        <div>
                                            <span>
                                                양돈 정보 제공
                                            </span>

                                            <ul>
                                                <li>
                                                    - 양돈 시세
                                                </li>
                                                <li>
                                                    - 시장 정보
                                                </li>
                                                <li>
                                                    - 세계 사료곡물 동향
                                                </li>
                                                <li>
                                                    - 신기술/신정보
                                                </li>
                                                <li>
                                                    - 질병정보
                                                </li>
                                            </ul>
                                        </div>

                                        <a class="pu_btn_red" href="javascript:;" v-on:click="exec('PAY', {type:'6', amount:amountMemberPay_6})">
                                            6개월(180일) 결제<br />₩{{ $ConMoney(amountMemberPay_6) }}원
                                        </a>
                                    </div>

                                    <div class="pu_member_pay_item">
                                        <div>
                                            <span>
                                                양돈 정보 제공
                                            </span>

                                            <ul>
                                                <li>
                                                    - 양돈 시세
                                                </li>
                                                <li>
                                                    - 시장 정보
                                                </li>
                                                <li>
                                                    - 세계 사료곡물 동향
                                                </li>
                                                <li>
                                                    - 신기술/신정보
                                                </li>
                                                <li>
                                                    - 질병정보
                                                </li>
                                            </ul>
                                        </div>

                                        <a class="pu_btn_red" href="javascript:;" v-on:click="exec('PAY', {type:'12', amount:amountMemberPay_12})">
                                            12개월(365일) 결제<br />₩{{ $ConMoney(amountMemberPay_12) }}원
                                        </a>
                                    </div>
                                </div>

                                <div v-if="getBase.userGrade != 'A'" class="pu_btnDiv01">
                                    * 위 상품의 최대 이용기간은 1년입니다.
                                </div>

                                <div class="pu_btnDiv01">
                                    <a class="pu_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/home')">
                                        <i class="xi-home-o"></i> 홈으로
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>

                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">

                    <div class="pu_joinDiv">
                        <div class="pu_tablejoin">
                            <div class="pu_member_pay_law">
                                <span>[환불규정]</span>
                                <ul>
                                    <li>1. 주문취소는 고객센터(Tel: 031-704-8113)를 통해 신청하실 수 있습니다.</li>

                                    <li>2. 유료회원(1개월) : 환불은 서비스를 이용한 일수를 제외하고 일할 계산으로 진행됩니다. 월 기준은 30이고 월 이용료를 30으로 나눈 금액을 말합니다. 결제 시간으로부터 24시간 이내 사용자는 전액 환불처리되며, 결제시간으로부터 24시간 이후부터 15일까지는 남은 일 수에 대한 일할 계산으로 환불 처리됩니다. 16일 이후 30일 이내 사용자는 환불이 불가능 합니다. 일할 계산은 카드 수수료와 위약금을 포함한 결제금액의 20% 금액을 제외한 금액이며, 남은 일 수에 대한 일할 계산으로 환불처리됩니다.</li>
                                    <li>&emsp;- 계산공식 : [유료회원(1개월) X 0.8 / 30] X 남은일수</li>
                                    <li>3. 유료회원(6개월) : 기준은 6개월이고 잔여 이용료는 6개월 이용료를 6로 나누고 하루라도 이용한 월은 제외하고 남은 금액을 환불합니다.</li>
                                    <li>4. 유료회원(12개월) : 기준은 12개월[11개월 + 1개월(추가)]이고 잔여 이용료는 12개월 이용료를 11로[1개월(추가) 제외] 나누고 하루라도 이용한 월은 제외하고 남은 금액을 환불합니다.</li>

                                    <li>5. 단순 변심, 착오 구매에 따른 교환/반품은 상품을 공급받은 날부터 7일 이내에 가능합니다. (배송비 고객 부담)</li>
                                    <li>6. 공급 받으신 상품 내용이 표시, 광고내용과 다르거나 다르게 이행된 경우에는 그 상품을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내 청약철회가 가능합니다. (배송비 회사 부담)</li>
                                    <li>7. 교환 및 반품 신청에는 사유에 따라 배송비 3,000~6,000원이 부과됩니다. 이 때 무료배송 혜택을 받은 주문일 경우 왕복 금액을, 배송비를 부담하신 경우 편도 금액을 산정하여 환불 금액에서 차감될 수 있습니다.</li>
                                    <li>8. 물품 하자에 의한 반송을 제외하고 고객변심에 의한 반품, 교환인 경우 배송비는 고객 부담이며(교환 시 6,000원 / 반품 시 3,000원), 접수 완료일로부터 평일 기준 2~3일 내에 수거가 이루어집니다.</li>
                                    <li>9. 구매수량 단위로 배송비가 부과된 상품은 교환 및 반품을 희망하실 경우 주문시와 동일하게 구매수량 단위로 회수비를 부과합니다.</li>
                                    <li>10. 반품 시, 상품대금 환불은 상품 회수 및 청약철회가 확정된 날로부터 3영업일 이내 진행되며, 기한을 초과한 경우 지연 기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 에서 정하는 이율을 곱하여 산정한 지연이자를 지급합니다.</li>

                                    <li>11. 단, 다음의 경우에는 교환 및 반품(환불)이 불가능합니다.</li>
                                    <li>&emsp;- 일부 제품의 경우 포장을 개봉하였거나 포장이 훼손되어 상품가치가 상실된 경우(상품 확인을 위하여 포장 훼손한 경우는 제외)</li>
                                    <li>&emsp;- 소비자에게 책임이 있는 사유로 재화등이 멸실되거나 훼손된 경우(재화의 내용을 확인하기 위하여 포장을 훼손한 경우는 제외)</li>
                                    <li>&emsp;- 소비자의 사용 또는 일부 소비로 재화등의 가치가 현저히 감소한 경우</li>
                                    <li>&emsp;- 시간이 지나 다시 판매하기 곤란할 정도로 재화등의 가치가 현저히 감소한 경우</li>
                                    <li>&emsp;- 복제가 가능한 재화등의 포장을 훼손한 경우</li>
                                    <li>&emsp;- 소비자의 주문에 따라 개별적으로 생산되는 재화등 또는 이와 유사한 재화등에 대하여 청약철회등을 인정하는 경우 통신판매업자에게 회복할 수 없는 중대한 피해가 예상되는 경우로서 사전에 해당 거래에 대하여 별도로 그 사실을 고지하고 소비자의 서면(전자문서 포함)에 의한 동의를 받은 경우</li>
                                </ul>
                            </div>                            
                        </div>
                    </div>
                </div>
                <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->
        <!-------------------------- 하단footer부분 (s) ------------------------>
        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>
        <inc_user_foot />
        <!-------------------------- 하단footer부분 (e) ------------------------>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGI"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , gubunMemberPay: ""
                , amountMemberPay_1: 0
                , amountMemberPay_6: 0
                , amountMemberPay_12: 0

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/member/info_member_pay";
                        }
                        break;

                    case "PAY":
                        {
                            // 확인
                            if (!confirm("유료회원 " + params.type + "개월 (\\" + _this.$ConMoney(params.amount) + "원) 결제를 진행하시겠습니까?")) {
                                return;
                            }
                            else
                            {
                                // url 설정
                                url = "/vue_api/user/member/save_member_pay";

                                // 폼
                                data["type"] = params.type;
                                data["amount"] = params.amount;
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            if (data && data.RESULT_YN == 'Y') {

                                _this.gubunMemberPay = data.HASH.GUBUN_MEMBER_PAY;
                                _this.amountMemberPay_1 = data.HASH.AMOUNT_MEMBER_PAY_1;
                                _this.amountMemberPay_6 = data.HASH.AMOUNT_MEMBER_PAY_6;
                                _this.amountMemberPay_12 = data.HASH.AMOUNT_MEMBER_PAY_12;
                            }
                        }
                        break;

                    case "PAY":
                        {
                            if (data && data.RESULT_YN == 'Y') {

                                let pay_key = data.HASH.HISTORY_PAY_CLIENTKEY;
                                let pay_no = data.HASH.HISTORY_PAY_NO;
                                let pay_amount = data.HASH.HISTORY_PAY_AMOUNT;
                                let pay_title = data.HASH.HISTORY_PAY_TITLE;
                                let pay_mem_name = data.HASH.HISTORY_PAY_MEM_NAME;

                                let tossPayments = TossPayments(pay_key)
                                tossPayments.requestPayment('카드', {
                                    amount: pay_amount,
                                    orderId: 'ORDERNO_' + pay_no,
                                    orderName: pay_title,
                                    customerName: pay_mem_name,
                                    successUrl: origin + "/user/member/paymember_success",
                                    failUrl: origin + "/user/member/paymember_fail",
                                })

                            } else {

                                alert("다시 진행해주세요.");
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>

<style>

    .pu_member_pay_item {
        margin-right: 7px;
        margin-left: 7px;
        width: 200px;
        height: 200px;
        border: solid 1px #f2f2f2;
        border-radius: 13px;
        display: inline-block;
        box-shadow: 7px 7px 11px 5px #d9d9d9;
    }

        .pu_member_pay_item > div {
            margin: 0 auto;
            width: 80%;
            height: 70%;
            color: #000000;
        }

            .pu_member_pay_item > div span {
                padding-top: 7px;
                padding-bottom: 5px;
                font-size: 17px;
                font-weight: 500;
                display: block;
            }

            .pu_member_pay_item > div ul {
                font-size: 12px;
                font-weight: 500;
            }

                .pu_member_pay_item > div ul li {
                }

        .pu_member_pay_item > a {
            padding: 5px;
            width: 90%;
            font-size: 15px;
            line-height: 19px;
        }

    .pu_member_pay_law {
        padding: 10px;
        border: solid 1px #dddddd;
        border-radius: 10px;
    }

        .pu_member_pay_law span {
            font-size: 15px;
            font-weight: 500;
            padding-bottom: 10px;
            display: block;
        }

        .pu_member_pay_law ul {
            font-size: 13px;
            font-weight: 400;
        }

        .pu_member_pay_law ul li {
        }

    @media screen and (max-width: 720px) {
        .pu_member_pay_item {
            margin: 0 auto;
            margin-bottom: 29px;
            width: 60%;
            display: block;
        }
    }

</style>