<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_title />
            <!-------------------------- 인클루드(e) -------------------------->
            <!-- 서브 상단 영역 (s) -->
            <section>
                <div class="pu_subTitle01">
                    <article>
                        <h2>{{ getBase.userMenuName_3d }}</h2>
                        <p></p>
                    </article>
                </div>
                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">
                    <div class="pu_newsListDiv">
                        <div class="pu_listSearch">
                            <div class="pu_search01">
                                <form id="searchForm" onsubmit="return false;">
                                    <select name="searchtype">
                                        <option value="title" selected="selected">제목</option>
                                        <option value="contents">내용</option>
                                        <option value="titlecontents">제목+내용</option>
                                        <option value="user">작성자</option>
                                    </select>
                                    <div class="pu_input_wrap">
                                        <label for="search" class="pu_blind">검색어</label>
                                        <input type="text" name="searchtext" id="searchtext" value="" placeholder="검색어를 입력하세요" title="검색어를 입력하세요" />
                                        <a href="javascript:;" class="pu_btnSerch01" v-on:click="exec('SEARCH', {'curPage': 1})">검색</a>
                                    </div>
                                </form>
                            </div>
                            <div class="pu_totalTxt">총 <span>{{ rownum }}</span>개 조회완료</div>
                        </div>
                        <div class="pu_tableY">
                            <table>
                                <colgroup>
                                    <col style="width:7%;">
                                    <col style="width:*;">
                                    <col style="width:15%;">
                                    <col style="width:10%;">
                                    <col style="width:7%;">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">번호</th>
                                        <th scope="col" class="list">제목</th>
                                        <th scope="col">작성자</th>
                                        <th scope="col">작성일</th>
                                        <th scope="col">조회</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, idx) in list_1">
                                        <td><span class="pu_notice">공지</span></td>
                                        <td class="list">
                                            <a href="javascript:;" v-on:click="$PageMove('./notice_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">
                                                {{ item.TITLE }}
                                            </a>
                                        </td>
                                        <td>{{ item.REGI_NAME }}</td>
                                        <td>{{ item.REGI_DATE }}</td>
                                        <td>{{ item.VIEWCOUNT }}</td>
                                    </tr>

                                    <tr v-for="(item, idx) in list_2">
                                        <td>{{ getRowNum(idx) }}</td>
                                        <td class="list">
                                            <a href="javascript:;" v-on:click="$PageMove('./notice_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">
                                                {{ item.TITLE }}
                                            </a>
                                        <td>{{ item.REGI_NAME }}</td>
                                        <td>{{ item.REGI_DATE }}</td>
                                        <td>{{ $ConMoney(item.VIEWCOUNT) }}</td>
                                    </tr>

                                    <tr v-if="list_1.length < 1 && list_2.length < 1">
                                        <td colspan="5">조회된 내용이 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!--  -->
                        <!-- 페이징 -->
                        <div class="pu_paging">
                            <div class="pu_pagination" v-html="paging"></div>
                        </div>
                        <!--  //페이징 -->
                        <!--  -->
                    </div>
                </div>
                <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->

        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {
        
        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }
        
        , data() {

            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {
            
            ...mapActions({
                setBase: "setBase"
            })
            
            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/board/notice/list";

                            // 폼
                            data["curPage"] = params.curPage;

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.rownum = data.ROWNUM;
                            _this.paging = data.PAGING;

                            _this.list_1 = data.LIST_1;
                            _this.list_2 = data.LIST_2;
                            _this.list_3 = data.LIST_3;
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>