<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-- 컨텐츠 영역 (s) -->
            <section>
                <div class="pu_subTitle01">
                    <article>
                        <h2 class="pu_search">통합검색</h2>
                        <p>찾으시는 단어를 입력해주세요.</p>
                    </article>
                </div>
                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">
                    <!-- 검색영역s -->
                    <div class="pu_topSearchDiv">

                        <form id="searchForm" onsubmit="return false;">

                            <input type="text" name="search_words" placeholder="검색어를 입력해주세요.">
                            <input type="button" class="pu_btnSerch01" v-on:click="exec('SEARCH', {'curPage':1})" title="검색">

                            <article>
                                <h4>인기검색어</h4>
                                <a href="javascript:;" v-on:click="$PageMove('/user/findall/findall?words=양돈산업')">#양돈산업</a>
                                <a href="javascript:;" v-on:click="$PageMove('/user/findall/findall?words=항생제')">#항생제</a>
                                <a href="javascript:;" v-on:click="$PageMove('/user/findall/findall?words=유전자검사')">#유전자검사</a>
                                <a href="javascript:;" v-on:click="$PageMove('/user/findall/findall?words=항체생성')">#항체생성</a>
                                <a href="javascript:;" v-on:click="$PageMove('/user/findall/findall?words=질병')">#질병</a>
                            </article>

                        </form>
                    </div>
                    <!-- 검색영역e -->
                    <!-- 검색결과 list(s) -->
                    <div class="pu_searchListDiv">

                        <div class="pu_search_result">
                            <div class="pu_search_tit">
                                <p>
                                    <strong>'{{ searchWords }}'</strong>의 결과목록
                                </p>
                                <span>검색결과<strong>{{ arr34.length + arr70.length + arr71.length + arr52.length + arr53.length + arr54.length + arr56.length + arr57.length + arr58.length }}</strong>건</span>
                            </div>

                            <ul class="pu_search_list">

                                <li v-if="arr34.length > 0">
                                    <div class="pu_search_location">
                                        <i class="xi-paper-o"></i>  <span>(주)정P&C연구소 <i class="xi-angle-right"></i>  공지사항 </span>
                                    </div>

                                    <div class="pu_search_list_txt" v-for="(item, idx) in arr34">
                                        <span class="pu_num">{{ idx + 1 }}.</span>
                                        <span class="pu_tit">
                                            <a href="javascript:;" v-on:click="$PageMove('/user/board/notice_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                        </span>
                                        <span class="pu_linkdate">
                                            <a class="pu_ss_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/board/notice_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">바로가기</a>
                                        </span>
                                    </div>
                                </li>

                                <li v-if="arr70.length > 0">
                                    <div class="pu_search_location">
                                        <i class="xi-paper-o"></i>  <span>(주)정P&C연구소 <i class="xi-angle-right"></i> 뉴스속보 <i class="xi-angle-right"></i>  국내뉴스 </span>
                                    </div>

                                    <div class="pu_search_list_txt" v-for="(item, idx) in arr70">
                                        <span class="pu_num">{{ idx + 1 }}.</span>
                                        <span class="pu_tit">
                                            <a href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                        </span>
                                        <span class="pu_linkdate">
                                            <a class="pu_ss_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">바로가기</a>
                                        </span>
                                    </div>
                                </li>

                                <li v-if="arr71.length > 0">
                                    <div class="pu_search_location">
                                        <i class="xi-paper-o"></i>  <span>(주)정P&C연구소 <i class="xi-angle-right"></i> 뉴스속보 <i class="xi-angle-right"></i>  해외뉴스 </span>
                                    </div>

                                    <div class="pu_search_list_txt" v-for="(item, idx) in arr71">
                                        <span class="pu_num">{{ idx + 1 }}.</span>
                                        <span class="pu_tit">
                                            <a href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                        </span>
                                        <span class="pu_linkdate">
                                            <a class="pu_ss_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">바로가기</a>
                                        </span>
                                    </div>
                                </li>

                                <li v-if="arr52.length > 0">
                                    <div class="pu_search_location">
                                        <i class="xi-paper-o"></i>  <span>양돈 정보(유료) <i class="xi-angle-right"></i>  시장정보 <i class="xi-angle-right"></i> 주간 시장 정보 </span>
                                    </div>

                                    <div class="pu_search_list_txt" v-for="(item, idx) in arr52">
                                        <span class="pu_num">{{ idx + 1 }}.</span>
                                        <span class="pu_tit">
                                            <a href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                        </span>
                                        <span class="pu_linkdate">
                                            <a class="pu_ss_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">바로가기</a>
                                        </span>
                                    </div>
                                </li>

                                <li v-if="arr53.length > 0">
                                    <div class="pu_search_location">
                                        <i class="xi-paper-o"></i>  <span>양돈 정보(유료) <i class="xi-angle-right"></i>  시장정보 <i class="xi-angle-right"></i> 국내 시장 정보 </span>
                                    </div>

                                    <div class="pu_search_list_txt" v-for="(item, idx) in arr53">
                                        <span class="pu_num">{{ idx + 1 }}.</span>
                                        <span class="pu_tit">
                                            <a href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                        </span>
                                        <span class="pu_linkdate">
                                            <a class="pu_ss_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">바로가기</a>
                                        </span>
                                    </div>
                                </li>

                                <li v-if="arr54.length > 0">
                                    <div class="pu_search_location">
                                        <i class="xi-paper-o"></i>  <span>양돈 정보(유료) <i class="xi-angle-right"></i>  시장정보 <i class="xi-angle-right"></i> 해외 시장 정보 </span>
                                    </div>

                                    <div class="pu_search_list_txt" v-for="(item, idx) in arr54">
                                        <span class="pu_num">{{ idx + 1 }}.</span>
                                        <span class="pu_tit">
                                            <a href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                        </span>
                                        <span class="pu_linkdate">
                                            <a class="pu_ss_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">바로가기</a>
                                        </span>
                                    </div>
                                </li>

                                <li v-if="arr56.length > 0">
                                    <div class="pu_search_location">
                                        <i class="xi-paper-o"></i>  <span>양돈 정보(유료) <i class="xi-angle-right"></i>  세계 사료곡물 동향 </span>
                                    </div>

                                    <div class="pu_search_list_txt" v-for="(item, idx) in arr56">
                                        <span class="pu_num">{{ idx + 1 }}.</span>
                                        <span class="pu_tit">
                                            <a href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                        </span>
                                        <span class="pu_linkdate">
                                            <a class="pu_ss_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">바로가기</a>
                                        </span>
                                    </div>
                                </li>

                                <li v-if="arr57.length > 0">
                                    <div class="pu_search_location">
                                        <i class="xi-paper-o"></i>  <span>양돈 정보(유료) <i class="xi-angle-right"></i>  신기술/신정보 </span>
                                    </div>

                                    <div class="pu_search_list_txt" v-for="(item, idx) in arr57">
                                        <span class="pu_num">{{ idx + 1 }}.</span>
                                        <span class="pu_tit">
                                            <a href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                        </span>
                                        <span class="pu_linkdate">
                                            <a class="pu_ss_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">바로가기</a>
                                        </span>
                                    </div>
                                </li>

                                <li v-if="arr58.length > 0">
                                    <div class="pu_search_location">
                                        <i class="xi-paper-o"></i>  <span>양돈 정보(유료) <i class="xi-angle-right"></i>  질병정보 </span>
                                    </div>

                                    <div class="pu_search_list_txt" v-for="(item, idx) in arr58">
                                        <span class="pu_num">{{ idx + 1 }}.</span>
                                        <span class="pu_tit">
                                            <a href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                        </span>
                                        <span class="pu_linkdate">
                                            <a class="pu_ss_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">바로가기</a>
                                        </span>
                                    </div>
                                </li>

                            </ul>

                        </div>

                    </div>
                    <!-- 검색결과 list(e) -->
                </div>
                <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->

        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"
                
                , searchWords: "검색어"

                , arr34: []
                , arr70: []
                , arr71: []
                , arr52: []
                , arr53: []
                , arr54: []
                , arr56: []
                , arr57: []
                , arr58: []

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })
            
            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/searchAll";

                            // 폼
                            if (!$("[name='search_words']").val() && qs.words) {
                                $("[name='search_words']").val(qs.words)
                            }

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.arr34 = data.MAP_LIST_1["ARR34"];
                            _this.arr70 = data.MAP_LIST_1["ARR70"];
                            _this.arr71 = data.MAP_LIST_1["ARR71"];
                            _this.arr52 = data.MAP_LIST_1["ARR52"];
                            _this.arr53 = data.MAP_LIST_1["ARR53"];
                            _this.arr54 = data.MAP_LIST_1["ARR54"];
                            _this.arr56 = data.MAP_LIST_1["ARR56"];
                            _this.arr57 = data.MAP_LIST_1["ARR57"];
                            _this.arr58 = data.MAP_LIST_1["ARR58"];
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>