<template>

    <!--================ Head Menu Area =================-->

    <div></div>

    <!--================ //Head Menu Area =================-->

</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        },

        data() {
            return {
                THIS_NAME: "INC ZADMIN HEAD"
            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 시작
                _this.exec("DEFAULT");
                //_this.exec("INIT");  
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "DEFAULT":
                        {
                            let protocol = window.location.protocol;
                            let host = window.location.host;

                            // http => https로
                            if (protocol.indexOf("https") == -1
                                && !host.startsWith("localhost")) {

                                window.location.href = "https://" + host;                                
                            }
                            else {
                                _this.exec("INIT");                                
                            }

                            return;
                        }
                        break;

                    case "INIT":
                        {
                            // 1. dimm block 보이기
                            _this.$ShowDimmLogo();

                            // 2. 현재 경로 & 쿼리스트링
                            {
                                /*
                                window.location.hostname;   // => kftc.local
                                window.location.href;       // => http://kftc.local:8088/test.jsp
                                window.location.host;       // => kftc.local:8088
                                window.location.port;       // => 8088
                                window.location.pathname;   // => test.jsp
                                window.location.search;     // => ?gg=1
                                window.location.protocol;   // => http:
                                this.$route.query
                                */

                                _this.setBase({ "curPath": window.location.pathname });
                                _this.setBase({ "curQs": window.location.search });
                                _this.setBase({ "curQsMap": _this.$curQsMap() });

                                _this.$PrintLog("user head curQsMap : ");
                                _this.$PrintLog(_this.getBase.curQs);
                            }

                            // 3. 조회
                            {
                                // 체크 초기화
                                _this.$parent.check_grade_yn = "N";

                                // URL 설정
                                url = "/vue_api/common/menu/zadmin_check_grade";

                                // data 설정
                                data["url"] = _this.getBase.curPath; // 현재 url 경로
                                data["qs"] = _this.getBase.curQs;    // 현재 url 쿼리스트링
                            }                            
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "DEFAULT":
                        {
                            
                        }
                        break;

                    case "INIT":
                        {
                            _this.$PrintLog("[[ HEAD ]]-------------------------------");
                            _this.$PrintLog(data);
                            _this.$PrintLog("[[ //HEAD ]]-------------------------------");

                            // 1. 기존 유저 정보와 비교해서 다르면 메뉴 초기화
                            if (_this.getBase.adminNo != data.ADMIN_NO
                                || _this.getBase.adminGrade != data.ADMIN_GRADE) {

                                _this.setBase({ "mapAdminMenu": [] });
                            }

                            // 2. 유저 정보 새로 설정
                            _this.setBase({ "adminLoginYn": data.ADMIN_LOGIN_YN });
                            _this.setBase({ "adminNo": data.ADMIN_NO });
                            _this.setBase({ "adminGrade": data.ADMIN_GRADE });

                            // 3. 권한 체크완료
                            _this.$parent.check_grade_yn = "Y";
                        }
                        break;

                    default:
                        {

                        };
                }
            }
        },

        beforeCreate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeCreate");
        },

        created() {
            this.$PrintLog(this.THIS_NAME + " : created");
            this.default();
        },

        beforeMount() {
            // this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {
            // this.$PrintLog(this.THIS_NAME + " : mounted");
        },

        beforeUpdate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            // this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
            // this.$PrintLog(this.THIS_NAME + " : beforeDestroy");
        },

        destroyed() {
            // this.$PrintLog(this.THIS_NAME + " : destroyed");
        },
    };
</script>
