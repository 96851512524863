<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_zadmin_head />
            <inc_zadmin_left />
            <!-------------------------- 인클루드(e) -------------------------->

            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">

                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_zadmin_title />
                    <!-------------------------- 인클루드(e) -------------------------->

                    <form id="searchForm" class="pu_searchBox" onsubmit="return false">
                        
                        <!-- 검색조건 (s) -->
                        <section class="pu_searchSection">
                            <div class="pu_searchBox">
                                <dl>
                                    <dt class="pu_wd90"><label for="">적용여부</label></dt>
                                    <dd>
                                        <div>
                                            <input type="radio" id="searchshowyn01" name="show_yn" value="" checked=""><label for="searchshowyn01">전체</label>
                                            <input type="radio" id="searchshowyn02" name="show_yn" value="Y"><label for="searchshowyn02">적용</label>
                                            <input type="radio" id="searchshowyn03" name="show_yn" value="N"><label for="searchshowyn03">미적용</label>
                                        </div>
                                    </dd>
                                </dl>

                                <dl>
                                    <dt class="pu_wd90"><label for="">검색조건</label></dt>
                                    <dd>
                                        <select name="searchtype" class="pu_select-box" title="조건을 선택하세요." style="width:150px">
                                            <option value="title">제목</option>
                                        </select>
                                        <input name="searchtext" type="text" placeholder="검색어를 입력해주세요." style="width:300px">
                                    </dd>
                                </dl>
                            </div>
                            <div class="pu_searchBtn"><a class="xi-search" href="javascript:;" v-on:click="exec('SEARCH', {'curPage': 1})"><span>검색</span></a></div>
                        </section>
                        <!-- 검색조건(e) -->
                        <!-- 컨텐츠영역 (s) -->
                        <section class="pu_contentsSection pu_pd15">
                            <!-- 테이블영역 (s)  -->
                            <div class="pu_listTable01">
                                <p class="pu_listNum">
                                    <i class="xi-paper-o"></i> 총 <strong>{{ rownum }}</strong>개의 게시물이 있습니다.
                                </p>
                                <div class="pu_TableX01">
                                    <table>
                                        <colgroup>
                                            <col width="4%">
                                            <col width="7%">
                                            <col width="*">
                                            <col width="7%">
                                            <col width="7%">
                                            <col width="15%">
                                            <col width="7%">
                                            <col width="10%">
                                            <col width="10%">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <input type="checkbox" class="dev_checkbox_all pu_tableCheck" />
                                                </th>
                                                <th scope="col">번호</th>
                                                <th scope="col">제목</th>
                                                <th scope="col">이미지</th>
                                                <th scope="col">링크</th>
                                                <th scope="col">게시기간</th>
                                                <th scope="col">적용여부</th>
                                                <th scope="col">등록자</th>
                                                <th scope="col">등록일</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, idx) in list_1">
                                                <td>
                                                    <input type="checkbox" name="no_checked" class="dev_checkbox_each pu_tableCheck" v-bind:value="row.NO" />
                                                </td>
                                                <td>{{ getRowNum(idx) }}</td>
                                                <td><a href="javascript:;" v-on:click="$PageMove('./board_mainpopup_view?no=' + row.NO)">{{ row.TITLE }}</a></td>
                                                <td><img v-bind:src="row.IMAGEURL" style="max-width: 100%" /></td>
                                                <td><a class="pu_ss_btn_dark" v-bind:href="row.LINK" v-show="row.LINK" target="_blank">바로가기</a></td>
                                                <td>{{ row.SDATE }} ~ {{ row.EDATE }}</td>
                                                <td><span v-bind:class="row.SHOW_YN == 'Y' ? 'pu_txtBlue' : 'pu_txtGrey'">{{ row.SHOW_YN == 'Y' ? '적용' : '미적용' }}</span></td>
                                                <td>{{ row.REGI_USERID }}</td>
                                                <td>{{ row.REGI_DATE }}</td>
                                            </tr>
                                            <tr v-if="list_1.length == 0">
                                                <td colspan="9">조회된 결과가 없습니다.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- 테이블영역 (e)  -->
                            <!-- 하단 페이징영역 (s)  -->
                            <div class="pu_paging">
                                <div class="pu_pagination" v-html="paging">

                                </div>
                            </div>
                            <!-- 하단 페이징영역 (d)  -->
                            <!-- 버튼 스타일영역 (s)  -->
                            <div class="pu_btnDiv01 pu_over_hidden">
                                <article class="pu_f_left">
                                    <a class="pu_btn_red" href="javascript:;" v-on:click="exec('DELETE', {})"><i class="xi-trash"></i> 선택삭제</a>
                                    <a class="pu_btn_blue" href="javascript:;" v-on:click="$PageMove('./board_mainpopup_regi')"><i class="xi-pen"></i> 신규등록</a>
                                </article>
                                <article class="pu_f_right">                                    
                                </article>
                            </div>
                            <!-- 버튼 스타일영역 (s)  -->
                        </section>
                        <!-- 컨텐츠영역 (e) -->
                    </form>
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/homepage/board_mainpopup/list";

                            // 폼
                            data["curPage"] = params.curPage;

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    case "DELETE":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/homepage/board_mainpopup/delete";

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.rownum = data.ROWNUM;
                            _this.paging = data.PAGING;

                            _this.list_1 = data.LIST_1;
                            _this.list_2 = data.LIST_2;
                            _this.list_3 = data.LIST_3;
                        }
                        break;

                    case "DELETE":
                        {
                            _this.exec("SEARCH", { "curPage": 1 });
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>