
<template>

    <div id='pjh_div'></div>

</template>

<script>
    // import

    // export
    export default {

        data() {
            return {

            }
        }

        , methods: {

            // 초기화
            default() {

                setTimeout(() => {

                    if (typeof (pjh_uploader) === 'undefined') {

                        this.pjh_init;

                    } else {

                        let qs = this.$curQsMap();

                        pjh_uploader.pannelId = "pjh_div";
                        pjh_uploader.funcCallBack = this.pjh_finish;
                        pjh_uploader.funcCancelBack = this.pjh_cancel;
                        pjh_uploader.urlUpload = "/vue_api/common/file_upload/upload";
                        pjh_uploader.urlImg = "/common/js/pjh_uploader/img/";
                        pjh_uploader.params = { 'gubun': qs["gubun"], 'folder': qs["folder"] };
                        pjh_uploader.limFilecount = qs["count"] ? qs["count"] : 0;
                        pjh_uploader.limFileSize = 15;
                        pjh_uploader.limFileExt = qs["ext"];
                        pjh_uploader.fileUploadIntro = qs["title"];
                        pjh_uploader.Init();

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }

                }, 100);

            }

            // 업로드 완료
            , pjh_finish(result) {

                let qs = this.$curQsMap();

                try {

                    var funcBack = window.parent[qs["func_back"]];
                    if (typeof funcBack === "function") {
                        funcBack(result);
                    }
                } catch (e) {
                    console.log(e);
                }

                parent.FrameLayer.close();
            }

            // 취소
            , pjh_cancel(result) {

                if (confirm("취소 하시겠습니까?")) {
                    parent.FrameLayer.close();
                }

            }

        }

        , beforeCreate() {

            // import css
            var arrCss = [
                "/common/js/pjh_uploader/css/pjh_uploader.css"
            ];
            arrCss.forEach(css => {
                let tag = document.createElement("link");
                tag.setAttribute("rel", "stylesheet")
                tag.setAttribute("href", css);
                document.head.appendChild(tag);
            });

            // import script
            var arrScript = [
                "/common/js/pjh_uploader/js/jquery_min.js"
                , "/common/js/pjh_uploader/js/pjh_uploader_min.js"
            ];
            arrScript.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });

        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.default();
        }

        , beforeUpdate() {
        }

        , updated() {
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>
