<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-- 서브 상단 영역 (s) -->
            <section class="pu_layoutTop">
                <div class="pu_subVisual pu_sub04" style="height:190px;">
                    <dl>
                        <!--<dt>회원정보 수정</dt>-->
                        <!--<dd>정부 및 대 기업체의 양돈관련 연구 프로젝트를 수행 중에 있습니다.</dd>-->
                    </dl>
                </div>
                <!-- 서브 메뉴표시 (s) -->
                <div class="pu_locationWrap">
                    <div class="pu_locationMenu" style="width: fit-content;">
                        <a class="pu_home" href="javascript:;" v-on:click="$PageMove('/')"><i class="xi-home-o"></i></a>
                        <ul class="pu_lnbList">

                            <li style="width: 200px; text-align: center;">
                                <div class="pu_select_wrap">
                                    <a class="pu_txtwrap" href="javascript:;" v-on:click="$PageMove('/user/member/editmember')"><span>회원정보 수정</span></a>
                                </div>
                            </li>

                            <li style="width: 200px; text-align: center;">
                                <div class="pu_select_wrap">
                                    <a class="pu_txtwrap" href="javascript:;" v-on:click="$PageMove('/user/member/paymember')"><span>유료회원 결제</span></a>
                                </div>
                            </li>

                            <li style="width: 200px; text-align: center;">
                                <div class="pu_select_wrap">
                                    <a class="pu_txtwrap" href="javascript:;" v-on:click="$PageMove('/user/member/paylist')"><span>결제 내역</span></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 서브 메뉴표시 (e) -->
            </section>
            <!-- 서브 상단 영역 (s) -->
            <!-- 컨텐츠 영역 (s) -->
            <section>
                <div class="pu_subTitle01 loginTit">
                    <article>
                        <h2 class="pu_login">회원정보 <span class="pu_txtColor4">수정</span></h2>
                        <p>정P&C연구소를 방문해주셔서 감사합니다.</p>
                    </article>
                </div>
                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent" style="max-width:500px !important;">

                    <div class="pu_joinDiv">
                        <div class="pu_tablejoin">
                            <form id="regiForm" onsubmit="return false;">
                                <table>
                                    <colgroup>
                                        <col width="170px" />
                                        <col width="*">
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <th>아이디 <strong>*</strong></th>
                                            <td>
                                                {{ view_1.ID }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>비밀번호 <strong>*</strong></th>
                                            <td>
                                                <input type="password" name="password" value="" maxlength="20" style="width:100%;" />
                                                <span class="pu_txt">영문, 숫자를 조합하여 8~20자리로 설정하셔야 합니다.</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>비밀번호확인 <strong>*</strong></th>
                                            <td><input type="password" name="checkpassword" maxlength="20" style="width:100%;" /></td>
                                        </tr>
                                        <tr>
                                            <th>이름 <strong>*</strong></th>
                                            <td><input type="text" name="name" v-model="view_1.NAME" class="dev_koreng_5" style="width:100%;" /></td>
                                        </tr>
                                        <tr>
                                            <th>생년월일 <strong>*</strong></th>
                                            <td>
                                                <div class="pu_dateSelect">
                                                    <ul class="pu_date_calendar" style="width:100%;">
                                                        <li style="width:100%;">
                                                            <input type="text" name="birth" class="pu_calendar" readonly="readonly"
                                                                   v-model:value="view_1.BIRTH" onclick="pjh_calendar(undefined, '[name=birth]', 'YYYY-MM-DD')" />
                                                            <input type="image" src="/images/icon_calendar.png" style="width:auto !important;" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>연락처 <strong>*</strong></th>
                                            <td><input type="text" name="phone" v-model="view_1.PHONE" class="dev_phone" style="width:100%;" /></td>
                                        </tr>
                                        <tr>
                                            <th>이메일 <strong>*</strong></th>
                                            <td><input type="text" name="email" v-model="view_1.EMAIL" class="dev_idmail" style="width:100%;" /></td>
                                        </tr>
                                        <tr>
                                            <th>회사명</th>
                                            <td><input type="text" name="company" v-model="view_1.COMPANY" maxlength="50" style="width:100%;" /></td>
                                        </tr>
                                        <tr>
                                            <th>회사주소</th>
                                            <td><input type="text" name="companyaddress" v-model="view_1.COMPANYADDRESS" maxlength="50" style="width:100%;" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                            <div>
                                <div class="pu_btnDiv01">
                                    <a class="dev_btn_submit pu_btn_red" href="javascript:"><i class="xi-border-color"></i> 수정하기</a>
                                    <a class="pu_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/home')"><i class="xi-home-o"></i> 홈으로</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
                </div>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->
        <!-------------------------- 하단footer부분 (s) ------------------------>
        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>
        <inc_user_foot />
        <!-------------------------- 하단footer부분 (e) ------------------------>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }
    
        , data() {
            return {

                gubun_page: "REGI"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })
            
            // 진입점
            , default() {

                // this
                let _this = this;
                
                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {
                    
                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/member/member_info";
                        }
                        break;
                        
                    case "SAVE":
                        {
                            // 비밀번호
                            var password = $("[name='password']");
                            if (!password.val().trim()) {
                                alert("비밀번호를 입력해주세요");
                                password.focus();
                                return false;
                            }

                            // 비밀번호 확인
                            var checkpassword = $("[name='checkpassword']");
                            if (checkpassword.val().trim() != password.val().trim()) {
                                alert("비밀번호가 다릅니다");
                                checkpassword.focus();
                                return false;
                            }

                            // 이름
                            var name = $("[name='name']");
                            if (!name.val().trim()) {
                                alert("이름를 입력해주세요");
                                name.focus();
                                return false;
                            }

                            // 생년월일
                            var birth = $("[name='birth']");
                            if (!birth.val().trim()) {
                                alert("생년월일를 입력해주세요");
                                birth.focus();
                                return false;
                            }

                            // 이메일
                            var email = $("[name='email']");
                            if (!email.val().trim()) {
                                alert("이메일을 입력해주세요");
                                email.focus();
                                return false;
                            }

                            // 연락처
                            var phone = $("[name='phone']");
                            if (!phone.val().trim()) {
                                alert("연락처를 입력해주세요");
                                phone.focus();
                                return false;
                            }
                            
                            if (confirm("정보를 수정하시겠습니까?")) {

                                // dimm 보이기
                                _this.$ShowDimmLogo();

                                 // url 설정
                                url = "/vue_api/user/member/update_member_info";

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));
                            }                          
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {
                    
                    case "SEARCH":
                        {
                            _this.view_1 = data.VIEW_1;
                        }
                        break;
                        
                    case "SAVE":
                        {   

                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>