<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_zadmin_head />
            <inc_zadmin_left />
            <!-------------------------- 인클루드(e) -------------------------->

            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">

                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_zadmin_title />
                    <!-------------------------- 인클루드(e) -------------------------->

                    <form id="searchForm" onsubmit="return false">

                        <!-- 검색조건 (s) -->
                        <section class="pu_searchSection">
                            <div class="pu_searchBox">

                                <dl>
                                    <dt class="pu_wd90"><label for="">기관구분</label></dt>
                                    <dd>
                                        <select name="comp_gubun" class="pu_select-box" title="기관을 선택하세요." style="width:150px">
                                            <option value="">- 선택 -</option>
                                            <option v-for="(row, idx) in list_comp_gubun" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>
                                    </dd>
                                    <dt class="pu_wd90"><label for="">입금여부</label></dt>
                                    <dd>
                                        <select name="deposit_yn" class="pu_select-box" title="입금여부를 선택하세요." style="width:150px">
                                            <option value="">- 선택 -</option>
                                            <option value="Y">입금</option>
                                            <option value="N">미입금</option>
                                        </select>
                                    </dd>
                                    <dt class="pu_wd90"><label for="">발송방법</label></dt>
                                    <dd>
                                        <select name="delivery_gubun" class="pu_select-box" title="배송방법을 선택하세요." style="width:150px">
                                            <option value="">- 선택 -</option>
                                            <option v-for="(row, idx) in list_delivery_gubun" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>
                                    </dd>
                                </dl>

                                <dl>
                                    <dt class="pu_wd90"><label for="">기간</label></dt>
                                    <dd>
                                        <select name="date_gubun" class="pu_select-box" title="조회 구분을 선택하세요." style="width:150px">
                                            <option value="">- 선택 -</option>
                                            <option value="REQ_DATE">의뢰 기간</option>
                                            <option value="DEPOSIT_DATE">입금 기간</option>
                                            <option value="DELIVERY_DATE">발송 기간</option>
                                        </select>

                                        <span>
                                            <input type="text" name="date_st" class="pu_calendar" readonly="readonly" style="width:105px"
                                                   onclick="pjh_calendar('', '[name=date_st]', 'YYYY-MM-DD')" />
                                            <input type="image" src="/images/icon_calendar.png" />
                                        </span>
                                        ~
                                        <span>
                                            <input type="text" name="date_ed" class="pu_calendar" readonly="readonly" style="width:105px"
                                                   onclick="pjh_calendar('', '[name=date_ed]', 'YYYY-MM-DD')" />
                                            <input type="image" src="/images/icon_calendar.png" />
                                        </span>
                                    </dd>

                                    <dd style="margin-left: 20px;">
                                        <a class="pu_btn_green" href="javascript:;" v-on:click="exec('EXCEL', {})">엑셀 다운로드</a>
                                    </dd>
                                </dl>

                                <dl>
                                    <dt class="pu_wd90"><label for="">검색조건</label></dt>
                                    <dd>
                                        <select name="searchtype" class="pu_select-box" title="조건을 선택하세요." style="width:150px">
                                            <option value="COMP_NAME">기관명</option>
                                            <option value="DEPOSIT_NAME">입금자명</option>
                                        </select>
                                        <input type="text" name="searchtext" placeholder="검색어를 입력해주세요." style="width:300px">
                                    </dd>
                                </dl>
                            </div>
                            <div class="pu_searchBtn"><a class="xi-search" href="javascript:;" v-on:click="exec('SEARCH', {'curPage': 1})"><span>검색</span></a></div>
                        </section>
                        <!-- 검색조건(e) -->
                        <!-- 컨텐츠영역 (s) -->
                        <section class="pu_contentsSection pu_pd15">
                            <!-- 테이블영역 (s)  -->
                            <div class="pu_listTable01">
                                <p class="pu_listNum">
                                    <i class="xi-paper-o"></i> 총 <strong>{{ rownum }}</strong>개의 게시물이 있습니다.
                                </p>
                                <div class="pu_TableX01">
                                    <table>
                                        <colgroup>
                                            <col width="50px">
                                            <col width="70px">
                                            <col width="50px">
                                            <col width="90px">
                                            <col width="180px">
                                            <col width="100px">
                                            <col width="50px">
                                            <col width="100px">
                                            <col width="100px">
                                            <col width="120px">
                                            <col width="50px">
                                            <col width="*">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <input type="checkbox" class="dev_checkbox_all pu_tableCheck" />
                                                </th>
                                                <th scope="col">번호</th>
                                                <th scope="col">구분</th>
                                                <th scope="col">지역</th>
                                                <th scope="col">기관명</th>
                                                <th scope="col">의뢰일자</th>
                                                <th scope="col">의뢰<br />건수</th>
                                                <th scope="col">총금액</th>
                                                <th scope="col">입금일<br />입금자명</th>
                                                <th scope="col">발송일<br />발송법</th>
                                                <th scope="col">공개<br />여부</th>
                                                <th scope="col">비고</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, idx) in list_1">
                                                <td style="height:55px;">
                                                    <input type="checkbox" name="no_checked" class="dev_checkbox_each pu_tableCheck" v-bind:value="row.NO" />
                                                </td>
                                                <td>
                                                    {{ getRowNum(idx) }}
                                                </td>
                                                <td>
                                                    {{ row.COMP_GUBUN_NAME }}
                                                </td>
                                                <td>
                                                    {{ row.CODE_AREA_NAME_1 }} {{ row.CODE_AREA_NAME_2 }}
                                                </td>
                                                <td class="pu_leftTxt">
                                                    <a href="javascript:;" v-on:click="$PageMove('./req_regi?no=' + row.NO)">{{ row.COMP_NAME }}</a>
                                                </td>
                                                <td>
                                                    {{ row.REQ_DATE }}
                                                </td>
                                                <td>
                                                    {{ row.REQ_CNT }}
                                                </td>
                                                <td>
                                                    {{ $ConMoney(row.AMOUNT) }}
                                                </td>
                                                <td>
                                                    {{ row.DEPOSIT_DATE }}<br />{{ row.DEPOSIT_NAME }}
                                                </td>
                                                <td>
                                                    {{ row.DELIVERY_DATE }}<br />{{ row.DELIVERY_GUBUN_NAME }}
                                                </td>
                                                <td>
                                                    {{ row.SHOW_YN }}
                                                </td>
                                                <td>
                                                    {{ row.BIGO }}
                                                </td>
                                            </tr>
                                            <tr v-if="list_1.length == 0">
                                                <td colspan="12">조회된 결과가 없습니다.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- 테이블영역 (e)  -->
                            <!-- 하단 페이징영역 (s)  -->
                            <div class="pu_paging">
                                <div class="pu_pagination" v-html="paging">

                                </div>
                            </div>
                            <!-- 하단 페이징영역 (d)  -->
                            <!-- 버튼 스타일영역 (s)  -->
                            <div class="pu_btnDiv01 pu_over_hidden">
                                <article class="pu_f_left">
                                    <a class="pu_btn_red" href="javascript:;" v-on:click="exec('DELETE', {})"><i class="xi-trash"></i> 선택삭제</a>
                                    <a class="pu_btn_blue" href="javascript:;" v-on:click="$PageMove('./req_regi')"><i class="xi-pen"></i> 신규등록</a>
                                </article>
                                <article class="pu_f_right">
                                </article>
                            </div>
                            <!-- 버튼 스타일영역 (s)  -->
                        </section>
                        <!-- 컨텐츠영역 (e) -->
                    </form>
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                , list_comp_gubun: []     // 업체 구분
                , list_delivery_gubun: [] // 배송 구분
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("GMO_COMP_GUBUN", { "curPage": 1 });
                _this.exec("GMO_DELIVERY_GUBUN", { "curPage": 1 });
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {
                    
                    case "GMO_COMP_GUBUN":
                        {
                            // url 설정
                            url = "/vue_api/common/common/list_code";

                            // 폼
                            data["gubun"] = "GMO_COMP_GUBUN";
                        }
                        break;
                        
                    case "GMO_DELIVERY_GUBUN":
                        {
                            // url 설정
                            url = "/vue_api/common/common/list_code";

                            // 폼
                            data["gubun"] = "GMO_DELIVERY_GUBUN";
                        }
                        break;

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/gmo/req_list";

                            // 폼
                            data["curPage"] = params.curPage;

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    case "DELETE":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/gmo/req_delete";

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    case "EXCEL":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/gmo/req_excel";

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));

                            if (!data.date_gubun || !data.date_st || !data.date_ed) {

                                alert("조회 기간을 먼저 선택해주세요");
                                return;
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {
                    
                    case "GMO_COMP_GUBUN":
                        {
                            _this.list_comp_gubun = data.LIST_1;
                        }
                        break;
                        
                    case "GMO_DELIVERY_GUBUN":
                        {
                            _this.list_delivery_gubun = data.LIST_1;
                        }
                        break;

                    case "SEARCH":
                        {
                            _this.rownum = data.ROWNUM;
                            _this.paging = data.PAGING;

                            _this.list_1 = data.LIST_1;
                            _this.list_2 = data.LIST_2;
                            _this.list_3 = data.LIST_3;
                        }
                        break;

                    case "DELETE":
                        {
                            _this.exec("SEARCH", { "curPage": 1 });
                        }
                        break;

                    case "EXCEL":
                        {
                            // 데이터
                            var list = data.LIST_1;

                            // 엑셀 다운로드
                            const workBook = XLSX.utils.book_new();
                            const workSheet = XLSX.utils.json_to_sheet(list, { header: ["품목명", "기관구분", "지역1", "지역2", "기관(학교)명", "수량", "의뢰일자", "발송일자", "발송방법", "입금일자", "입금자명", "금액", "비고"] });
                            XLSX.utils.book_append_sheet(workBook, workSheet, '검사진행내역');
                            XLSX.writeFile(workBook, '검사진행내역.xlsx');
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>