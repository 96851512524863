<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_zadmin_head />
            <inc_zadmin_left />
            <!-------------------------- 인클루드(e) -------------------------->

            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">

                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_zadmin_title />
                    <!-------------------------- 인클루드(e) -------------------------->
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">
                                <table>
                                    <colgroup>
                                        <col width="15%">
                                        <col width="*">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>제목</th>
                                            <td>{{ view_1.TITLE }}</td>
                                        </tr>
                                        <tr>
                                            <th>이미지</th>
                                            <td v-html="view_1.HTML_IMG_ITEM_VIEW"></td>
                                        </tr>
                                        <tr>
                                            <th>팝업 크기</th>
                                            <td>가로 : {{ view_1.WIDTH }}px, 세로 : {{ view_1.HEIGHT }}px</td>
                                        </tr>
                                        <tr>
                                            <th>팝업 위치</th>
                                            <td>X : {{ view_1.LO_X }}px, Y : {{ view_1.LO_Y }}px</td>
                                        </tr>
                                        <tr>
                                            <th>링크</th>
                                            <td><a v-show="view_1.LINK" v-bind:href="view_1.LINK">{{ view_1.LINK }}</a></td>
                                        </tr>
                                        <tr>
                                            <th>게시기간</th>
                                            <td>{{ view_1.SDATE }} ~ {{ view_1.EDATE }}</td>
                                        </tr>
                                        <tr>
                                            <th>적용여부</th>
                                            <td>{{ view_1.SHOW_YN == "Y" ? "적용" : "미적용" }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->
                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_left">
                                <a class="pu_btn_blue" href="javascript:;" v-on:click="$PageMove('./board_mainpopup_regi?no=' + view_1.NO)"><i class="xi-eraser"></i> 수정</a>
                                <a class="pu_btn_dark" href="javascript:;" v-on:click="$PageMove('./board_mainpopup')"><i class="xi-bars"></i> 목록</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "VIEW"

                , cnt_lim_pic: 50  // 사진 갯수

                , rownum: 0        // 번호
                , paging: ""       // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/homepage/board_mainpopup/view";

                            // 폼
                            data["no"] = qs.no;
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.view_1 = data.VIEW_1;
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>