<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) ------------------------->
        <div class="pu_mainWrap">

            <!-- 메인비쥬얼 상단영역 s -->
            <section class="pu_mainTopVisual">

                <!-- 메인 슬라이딩  s -->
                <div class="pu_slide_box">
                    <ul class="pu_slide_pic">
                        <li style="background:url('/images/main_visual01.png') no-repeat;background-size:cover;">
                            <div>
                                <p class="pu_txt01">Real time Information Pork Industry</p>
                                <p class="pu_title01"><strong class="pu_txtWhite">양돈산업전문</strong> 종합컨설팅</p>
                                <!--<p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>-->
                            </div>
                        </li>
                        <li style="background:url('/images/main_visual02.png') no-repeat;background-size:cover;">
                            <div>
                                <p class="pu_txt01">Welcome to Jung P&C Laboratory</p>
                                <p class="pu_title01"><strong class="pu_txtWhite">건강,선진적</strong> 식문화사회 실천</p>
                                <!--<p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>-->
                            </div>
                        </li>
                        <li style="background:url('/images/main_visual03.png') no-repeat;background-size:cover;">
                            <div>
                                <p class="pu_txt01 pu_letter">최신 기술과 시설을 갖춘 공식 식품 유전자 분석 기관</p>
                                <p class="pu_title01">기능성유전자 <strong class="pu_txtWhite">GMO분석센터</strong></p>
                                <!--<p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>-->
                            </div>
                        </li>
                        <li style="background:url('/images/main_visual04.png') no-repeat;background-size:cover;">
                            <div>
                                <p class="pu_txt01">Real time Information Pork Industry</p>
                                <p class="pu_title01"><strong class="pu_txtWhite">종돈산업전문</strong> 종합컨설팅</p>
                                <!--<p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>-->
                            </div>
                        </li>
                    </ul>
                    <ul class="pu_slide_tab"></ul>
                    <a href="javascript:;" class="pu_slide_nav pu_slide_prev">&lt;</a>
                    <a href="javascript:;" class="pu_slide_nav pu_slide_next">&gt;</a>
                </div>
                <!-- 메인슬라이딩 e -->
                <!--  gmo분석센터 / 통합건색영역 (s)-->
                <div class="pu_jpncW pu_gmoSection">
                    <!-- 통합건색영역s  -->
                    <div class="pu_mainSearch">

                        <label for="">통합검색</label>
                        <input type="text" id="dev_search_text_home" name="dev_search_text_home" placeholder="검색어를 입력해주세요.">
                        <input type="button" class="pu_btnSerch" v-on:click="exec('SEARCHALL', {})" title="검색">

                        <article>
                            <a href="javascript:;" v-on:click="$PageMove('/user/findall/findall?words=양돈산업')">#양돈산업</a>
                            <a href="javascript:;" v-on:click="$PageMove('/user/findall/findall?words=항생제')">#항생제</a>
                            <a href="javascript:;" v-on:click="$PageMove('/user/findall/findall?words=유전자검사')">#유전자검사</a>
                            <a href="javascript:;" v-on:click="$PageMove('/user/findall/findall?words=항체생성')">#항체생성</a>
                            <a href="javascript:;" v-on:click="$PageMove('/user/findall/findall?words=질병')">#질병</a>
                        </article>
                    </div>
                    <!-- 통합건색영역e  -->
                    <!-- gmo분석센터s  -->
                    <div class="pu_mainGmo">
                        <article>
                            <div class="pu_title">
                                <p>기능성유전자</p>
                                <h2><span>GMO</span>분석센터</h2>
                            </div>
                            <ul>
                                <li><a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=60')">검사절차</a> </li>
                                <li><a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=60')">분석의뢰서</a> </li>
                                <li><a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=62')">센터인증서</a> </li>
                            </ul>
                        </article>
                    </div>
                    <!-- gmo분석센터e  -->

                </div>
                <!--  gmo분석센터 / 통합건색영역 (e)-->

            </section>
            <!-- 메인비쥬얼 상단영역 e -->
            <!-- 소식및이벤트 영역 (s) -->
            <section class="pu_jpncW pu_mainIssue">
                <!-- 뉴스영역s -->
                <div class="pu_mainNews">
                    <div class="pu_tabTitle">
                        <h2>(주)정P&C연구소 <span>소식</span></h2>
                        <ul>
                            <!--<li class="pu_on"><span>최신</span></li>-->
                            <li class="dev_btn_cont_list"><a href="javascript:;" v-on:click="exec('CONTLIST', {'idx': 0})"><span>국내뉴스</span></a></li>
                            <li class="dev_btn_cont_list"><a href="javascript:;" v-on:click="exec('CONTLIST', {'idx': 1})"><span>주간시장정보</span></a></li>
                            <li class="dev_btn_cont_list"><a href="javascript:;" v-on:click="exec('CONTLIST', {'idx': 2})"><span>국내시장정보</span></a></li>
                            <li class="dev_btn_cont_list"><a href="javascript:;" v-on:click="exec('CONTLIST', {'idx': 3})"><span>공지사항</span></a></li>
                        </ul>
                    </div>

                    <div class="pu_MainNewsList">

                        <ul class="dev_cont_list" style="display: none; border-bottom: none;">
                            <li v-for="(item, idx) in list_newsinner"
                                style="border-bottom: solid 1px">
                                <dl>
                                    <dt class="pu_title"><p>국내뉴스 <!--<span class="pu_new">N</span>--></p></dt>
                                    <dd class="pu_date">{{ item.REGI_DATE }}</dd>
                                    <dd class="pu_subject">
                                        <a href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                    </dd>
                                </dl>
                            </li>
                        </ul>

                        <ul class="dev_cont_list" style="display: none; border-bottom: none;">
                            <li v-for="(item, idx) in list_infoweek"
                                style="border-bottom: solid 1px">
                                <dl>
                                    <dt class="pu_title"><p>주간정보 <!--<span class="pu_new">N</span>--></p></dt>
                                    <dd class="pu_date">{{ item.REGI_DATE }}</dd>
                                    <dd class="pu_subject">
                                        <a href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                    </dd>
                                </dl>
                            </li>
                        </ul>

                        <ul class="dev_cont_list" style="display: none; border-bottom: none;">
                            <li v-for="(item, idx) in list_infoinner"
                                style="border-bottom: solid 1px">
                                <dl>
                                    <dt class="pu_title"><p>국내정보 <!--<span class="pu_new">N</span>--></p></dt>
                                    <dd class="pu_date">{{ item.REGI_DATE }}</dd>
                                    <dd class="pu_subject">
                                        <a href="javascript:;" v-on:click="$PageMove('/user/board/normal_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                    </dd>
                                </dl>
                            </li>
                        </ul>

                        <ul class="dev_cont_list" style="display: none; border-bottom: none;">
                            <li v-for="(item, idx) in list_notice"
                                style="border-bottom: solid 1px">
                                <dl>
                                    <dt class="pu_title"><p>공지사항 <!--<span class="pu_new">N</span>--></p></dt>
                                    <dd class="pu_date">{{ item.REGI_DATE }}</dd>
                                    <dd class="pu_subject">
                                        <a href="javascript:;" v-on:click="$PageMove('/user/board/notice_view?page_gubun=' + item.GUBUN + '&no=' + item.NO)">{{ item.TITLE }}</a>
                                    </dd>

                                </dl>
                            </li>
                        </ul>

                    </div>
                </div>
                <!-- 뉴스영역e -->
                <!-- 이벤트배너 영역s -->
                <div class="pu_mainEvent">
                    <div id="pu_MBanner">
                        <div class="title"><h2>(주)정P&C연구소 <span>이슈</span></h2></div>
                        <div class="pu_jpnc-slider">
                            <div>
                                <a class="pu_bannerLinks">
                                    <p class="title">환절기 주의 이벤트</p>
                                    <img src="/images/main_banner01.png" alt="">
                                </a>
                            </div>
                            <div>
                                <a class="pu_bannerLinks">
                                    <p class="title">서버점검 안내</p>
                                    <img src="/images/main_banner02.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>

                    <!--
                    <div class="pu_mainIconLink">
                        <ul>
                            <li><a href=""><span class="pu_icons"><i class="xi-book-o xi-2x"></i> </span><br><span class="pu_txt">(주)정P&C연구소 매거진</span></a></li>
                            <li><a href=""><span class="pu_icons"><i class="xi-bell-o xi-2x"></i></span> <br><span class="pu_txt">알림 서비스 신청</span></a></li>
                        </ul>
                    </div>
                    -->

                </div>
                <!-- 이벤트배너 영역e -->

            </section>
            <!-- 소식및이벤트 영역 (s) -->
            <!-- 분석센터 영역 (s) -->
            <section class="pu_BusinessSection">
                <div class="pu_mainBusiness">
                    <div class="pu_PContent">
                        <div class="pu_BusinessTitle pu_jpncW">
                            <h2><span>분석</span>서비스</h2>
                            <p>공인검사법으로 신뢰있는 검사를 합니다.</p>
                        </div>
                        <div class="pu_jpnc-slider">
                            <div class="pu_business" style="background: url('/images/main_business01.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=43')">
                                    <dl>
                                        <dt>한우 확인검사</dt>
                                        <!--<dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business02.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=42')">
                                    <dl>
                                        <dt>GMO 검사</dt>
                                        <!--<dd>유전자를 다른 생물체의 유전자와 결합시켜 특정한 목적에 맞도록 유전자 일부를 변형</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business03.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=45')">
                                    <dl>
                                        <dt>백수오(식품) 검사</dt>
                                        <!--<dd>백수오 검사를 합니다.설명글 입력예정</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business04.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=44')">
                                    <dl>
                                        <dt>쌀/현미 품종 검사</dt>
                                        <!--<dd>쌀·현미품종검정은 국립농산물품질관리원의 「SNP 유전자분석법」을 이용한 분석법입니다.</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business05.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=47')">
                                    <dl>
                                        <dt>살모넬라 검사</dt>
                                        <!--<dd>살모넬라 검사</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business06.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=50')">
                                    <dl>
                                        <dt>A2 Milk</dt>
                                        <!--<dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business07.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=48')">
                                    <dl>
                                        <dt>항생제 검사</dt>
                                        <!--<dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                        </div>
                    </div>
                    <!----------------------  mobile버전 배너s ---------------------->
                    <div class="pu_MContent">
                        <div class="pu_BusinessTitle pu_jpncW">
                            <h2><span>분석</span>서비스</h2>
                            <p>공인검사법으로 신뢰있는 검사를 합니다.</p>
                        </div>
                        <div class="pu_jpnc-slider">
                            <div class="pu_business" style="background: url('/images/main_business01.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=43')">
                                    <dl>
                                        <dt>한우 확인검사</dt>
                                        <!--<dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business02.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=42')">
                                    <dl>
                                        <dt>GMO 검사</dt>
                                        <!--<dd>유전자를 다른 생물체의 유전자와 결합시켜 특정한 목적에 맞도록 유전자 일부를 변형</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business03.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=45')">
                                    <dl>
                                        <dt>백수오(식품) 검사</dt>
                                        <!--<dd>백수오 검사를 합니다.설명글 입력예정</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business04.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=44')">
                                    <dl>
                                        <dt>쌀/현미 품종 검사</dt>
                                        <!--<dd>쌀·현미품종검정은 국립농산물품질관리원의 「SNP 유전자분석법」을 이용한 분석법입니다.</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business05.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=47')">
                                    <dl>
                                        <dt>살모넬라 검사</dt>
                                        <!--<dd>살모넬라 검사</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business06.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=50')">
                                    <dl>
                                        <dt>A2 Milk</dt>
                                        <!--<dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business" style="background: url('/images/main_business07.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=48')">
                                    <dl>
                                        <dt>항생제 검사</dt>
                                        <!--<dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>-->
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                        </div>
                    </div>
                    <!------------------------- mobile버전 배너e ---------------------->
                </div>
            </section>
            <!-- 분석센터 영역 (e) -->
            <!-- 퀵링크 배너 (s) -->
            <section class="pu_jpncW pu_mainQuick">
                <ul>
                    <li>
                        <a href="javascript:;" v-on:click="$PageMove('/user/board/normal?page_gubun=57')">
                            <p class="pu_Quick01"></p>
                            <span>신기술 / 신정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=31')">
                            <p class="pu_Quick02"></p>
                            <span>주요사업 및 상품</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;" v-on:click="$PageMove('/user/board/normal?page_gubun=53')">
                            <p class="pu_Quick03"></p>
                            <span>국내시장정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;" v-on:click="$PageMove('/user/board/normal?page_gubun=54')">
                            <p class="pu_Quick04"></p>
                            <span>해외시장정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;" v-on:click="$PageMove('/user/board/normal?page_gubun=58')">
                            <p class="pu_Quick05"></p>
                            <span>질병정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=32')">
                            <p class="pu_Quick06"></p>
                            <span>연구수행 및 과제</span>
                        </a>
                    </li>
                </ul>
            </section>
            <!-- 퀵링크 배너 (e) -->
            <!-- 찾아오시는 길(s) -->
            <section class="pu_mainMapSection">
                <div class="pu_mainCompay">
                    <div class="pu_mainCompayInfo">
                        <div class="pu_title">
                            <h2><span>(주)정P&C연구소</span></h2>
                            <p>경기도 용인시 기흥구 흥덕중앙로 20 U-Tower 1504, 1505 호</p>
                        </div>
                        <ul>
                            <li><a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=30')"><span>회사소개</span> <i class="xi-city"></i></a></li>
                            <li><a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=33')"><span>오시는길</span> <i class="xi-maker"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="pu_mainMap">

                </div>
            </section>
            <!-- 찾아오시는 길(e) -->

        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) ------------------------->

        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_pop: [] // 팝업

                , list_notice: []
                , list_newsinner: []
                , list_infoweek: []
                , list_infoinner: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("CONTLIST", { "idx": 0 });

                // 팝업
                _this.exec("POPUP", { "idx": 0 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "CONTLIST":
                        {
                            // url 설정
                            url = "/vue_api/user/contList";

                            // 폼
                            data["curPage"] = params.curPage;

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));

                            // css
                            {
                                // 버튼
                                $(".dev_btn_cont_list").removeClass("pu_on");
                                $($(".dev_btn_cont_list")[params.idx]).addClass("pu_on");

                                // 목록
                                $(".dev_cont_list").hide();
                                $($(".dev_cont_list")[params.idx]).show();
                            }
                        }
                        break;

                    case "POPUP":
                        {
                            // url 설정
                            url = "/vue_api/user/pop/popList";
                        }
                        break;

                    case "SEARCHALL":
                        {
                            let words = $("#dev_search_text_home").val().trim();
                            if (words) {
                                _this.$PageMove("/user/findall/findall?words=" + words);
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "CONTLIST":
                        {
                            // 내용
                            {
                                _this.list_notice = data.MAP_LIST_1.ARRNOTICE;       // 공지사항
                                _this.list_newsinner = data.MAP_LIST_1.ARRNEWSINNER; // 국내뉴스
                                _this.list_infoweek = data.MAP_LIST_1.ARRINFOWEEK;   // 주간시장정보
                                _this.list_infoinner = data.MAP_LIST_1.ARRINFOINNER; // 국내시장정보
                            }
                        }
                        break;

                    case "POPUP":
                        {
                            var arrPop = data.MAP_LIST_1.ARRPOP;

                            for (var idx = 0; idx < arrPop.length; idx++) {

                                var no = arrPop[idx].NO;
                                var title = arrPop[idx].TITLE;
                                var img = arrPop[idx].URL_IMG_SAVEPATH;
                                var link = arrPop[idx].LINK;
                                var width = arrPop[idx].WIDTH;
                                var height = arrPop[idx].HEIGHT;
                                var lo_x = arrPop[idx].LO_X;
                                var lo_y = arrPop[idx].LO_Y;

                                var encNo = _this.$EncBase64(no);
                                var encImg = _this.$EncBase64(img);
                                var encLink = _this.$EncBase64(link);



                                var url = "/user/pop/pop?no=" + encNo + "&img=" + encImg + "&link=" + encLink;
                                var opt = "width = " + width + ", height = " + (parseInt(height) + 30) + ", top = " + lo_y + ", left = " + lo_x;

                                var cookie = getCookie("pop_" + no);
                                if (!cookie) {
                                    window.open(url, title, opt);
                                }
                            }
                        }
                        break;

                    case "SEARCHALL":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>