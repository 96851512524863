<template>

    <div class="pu_loginWapper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_zadmin_head />
        <!-------------------------- 인클루드(s) -------------------------->

        <section class="pu_loginBox">

            <!-- 로그인 박스S -->
            <div class="pu_login_div">
                <div class="pu_loginL">
                    <div></div>
                </div>

                <div class="pu_loginR">
                    <h1><img src="/images/logo01.png" alt="jpnc로고"></h1>
                    <p class="pu_txt">Login to your account</p>
                    <form id="regiForm" action="">
                        <div><input name="id" type="text" placeholder="아이디 입력" maxlength="15"></div>
                        <div><input name="pw" type="password" placeholder="비밀번호 입력" maxlength="15"></div>
                        <div class="dev_btn_submit pu_LoginBtn"><a><i class="xi-lock-o xi-x"></i> 로그인</a></div>
                    </form>
                </div>
            </div>
            <!-- 로그인 박스e -->

            <address>
                <p>16950  경기 용인시 기흥구 흥덕중앙로 120 U타워 1504~5호(영덕동 1029)    </p>
                <p>COPYRIGHT © ㈜정P&C연구소. All rights reserved.</p>
            </address>
        </section>
    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGIFORM"

                , rownum: 0    // 번호
                , paging: ""   // 페이징
                
                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })
    
            // 진입점
            , default() {

                // this
                let _this = this;

                // 시작
                _this.exec("INIT");
            }
    
            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "INIT":
                        {
                            // enter 키 이벤트
                            {
                                _this.$SetEventEnter("id", function () { $("[name='pw']").focus(); });
                                _this.$SetEventEnter("pw", function () { _this.exec("LOGIN"); });
                            }
                        }
                        break;

                    case "LOGIN":
                    case "SAVE":
                        {
                            // 비밀번호
                            var id = $("[name='id']");
                            if (!id.val().trim()) {

                                alert("아이디를 입력해주세요");
                                id.focus();
                                return false;
                            }

                            // 비밀번호
                            var pw = $("[name='pw']");
                            if (!pw.val().trim()) {

                                alert("비밀번호를 입력해주세요");
                                pw.focus();
                                return false;
                            }

                            {
                                // dimm 보이기
                                _this.$ShowDimmLogo();

                                // URL 설정
                                url = "/vue_api/zadmin/login";

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "INIT":
                        {
                            
                        }
                        break;

                    case "LOGIN":
                        {
                           
                        }
                        break;

                    default:
                        {

                        };
                }
                
                // dimm 숨기기
                _this.$HideDimmLogo();
            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>