<template>
    <!-- 왼쪽 메뉴 layout(s) -->
    <div id="AdminNav" class="pu_LnbWrap">

        <a class="pu_openbtn" style="display: none;" onclick="openNav()" title="메뉴펼쳐보기">
            <i class="xi-bars xi-x2"></i>
        </a>

        <h1 class="pu_toggleN">
            ㈜정P&C연구소
            <a href="javascript:;" class="closebtn" onclick="closeNav()"><i class="xi-dedent xi-x"></i></a>
        </h1>

        <nav>
            <div class="pu_lnbmenu">
                <ul>
                    <li v-for="(d1, idx_d1) in getBase.arrAdminMenu_1d" v-bind:class="d1.ACTIVE"
                        v-bind:key="idx_d1">
                        <a href="javascript:;" v-on:click="$PageMove(d1.URL)">
                            <span></span>
                            <span v-html="d1.TITLE"></span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="pu_submenu">
                <ul class="pu_accordion-menu">
                    <li v-for="(d2, idx_d2) in getBase.arrAdminMenu_2d" v-bind:class="d2.ACTIVE"
                        v-bind:key="idx_d2">
                        <div class="pu_dropdownlink" v-html="d2.TITLE"></div>
                        <ul class="pu_submenuItems">
                            <li v-for="(d3, idx_d3) in getMenu3d(d2)" v-bind:class="d3.ACTIVE"
                                v-bind:key="idx_d3">
                                <a href="javascript:;" v-on:click="$PageMove(d3.URL)" v-html="d3.TITLE"></a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <!--왼쪽 메뉴 layout(e) -->
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {
        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                THIS_NAME: "INC ZADMIN LEFT"

                ,mapArrAdminMenu_3d: {} // 3뎁스 메뉴
            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 시작
                _this.exec("MENU_1D");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "MENU_1D":
                        {
                            // URL 설정
                            url = "/vue_api/common/menu/zadmin_1d";

                            // data 설정
                            data["url"] = _this.getBase.curPath; // 현재 url 경로
                            data["qs"] = _this.getBase.curQs;    // 현재 url 쿼리스트링
                            data["menu_code"] = _this.getBase.adminMenuCode; // 기존 메뉴 코드
                        }
                        break;

                    case "MENU_2D":
                        {
                            // URL 설정
                            url = "/vue_api/common/menu/zadmin_2d";

                            // data 설정
                            data["url"] = _this.getBase.curPath; // 현재 url 경로
                            data["qs"] = _this.getBase.curQs;    // 현재 url 쿼리스트링
                            data["menu_code"] = _this.getBase.adminMenuCode; // 기존 메뉴 코드
                        }
                        break;

                    case "MENU_3D":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "MENU_1D":
                        {
                            _this.setBase({ "adminLoginYn": data.ADMIN_LOGIN_YN });       // 로그인 여부
                            _this.setBase({ "adminNo": data.ADMIN_NO });                  // 로그인 번호

                            if (data.MENU_NAME_1D || _this.getBase.arrAdminMenu_1d.length < 1) {
                                _this.setBase({ "adminMenuCode": data.MENU_CODE });       // 메뉴코드
                                _this.setBase({ "adminMenuName_1d": data.MENU_NAME_1D }); // 1뎁스 메뉴명
                                _this.setBase({ "arrAdminMenu_1d": data.LIST_1 });        // 1뎁스 메뉴
                            }

                            // 2뎁스 메뉴
                            _this.exec("MENU_2D");
                        }
                        break;

                    case "MENU_2D":
                        {
                            if (data.MENU_NAME_2D || _this.getBase.arrAdminMenu_2d.length < 1) {
                                _this.setBase({ "adminMenuName_2d": data.MENU_NAME_2D }); // 2뎁스 메뉴명
                                _this.setBase({ "adminMenuName_3d": data.MENU_NAME_3D }); // 3뎁스 메뉴명
                                _this.setBase({ "arrAdminMenu_2d": data.LIST_1 });        // 2뎁스 메뉴
                            }

                            _this.mapArrAdminMenu_3d = data.MAP_LIST_1;                   // 3뎁스 메뉴
                        }
                        break;

                    case "MENU_3D":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }
            }

            // 3뎁스 리턴
            , getMenu3d(d2) {

                //this.$PrintLog(d2.CODE);
                return this.mapArrAdminMenu_3d[d2.CODE] || []
            }
        },

        beforeCreate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeCreate");
        },

        created() {
            // this.$PrintLog(this.THIS_NAME + " : created");
        },

        beforeMount() {
            // this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.default();
        },

        beforeUpdate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            // this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
            // this.$PrintLog(this.THIS_NAME + " : beforeDestroy");
        },

        destroyed() {
            // this.$PrintLog(this.THIS_NAME + " : destroyed");
        },
    };
</script>
