<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_gmo_docs pu_contentWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_title />
            <!-------------------------- 인클루드(e) -------------------------->
            <!-- 서브 상단 영역 (s) -->
            <section>
                <div class="pu_subTitle01">
                    <article>
                        <h2>{{ getBase.userMenuName_3d }}</h2>
                        <p></p>
                    </article>
                </div>
                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">
                    <div class="pu_newsListDiv">
                        <div class="pu_listSearch">
                            <div class="pu_search01" style="float:unset;">
                                <ul class="pu_search_btn_ul">
                                    <li :class="{'pu_on' : gubunDocs == 'a'}" v-on:click="exec('DOCS_A', {})"><a href="javascript:;"><span>견적서</span></a></li>
                                    <li :class="{'pu_on' : gubunDocs == 'b'}" v-on:click="exec('DOCS_B', {})"><a href="javascript:;"><span>거래명세서</span></a></li>
                                    <li :class="{'pu_on' : gubunDocs == 'c'}" v-on:click="exec('DOCS_C', {})"><a href="javascript:;"><span>사업자등록증</span></a></li>
                                    <li :class="{'pu_on' : gubunDocs == 'd'}" v-on:click="exec('DOCS_D', {})"><a href="javascript:;"><span>통장사본</span></a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="pu_tableY" style="margin-top: 20px; width:500px; float: left;">
                            <table>
                                <colgroup>
                                    <col width="120px">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th class="list" scope="col">발행일자</th>
                                        <th class="list" scope="col">
                                            <span style="position: relative;">
                                                <input type="text" name="printDate" class="pu_calendar" readonly="readonly"
                                                       v-model:value="printDate" onclick="pjh_calendar(undefined, '[name=printDate]', 'YYYY-MM-DD')"
                                                       style="text-align:center;" />
                                                <input type="image" src="/images/icon_calendar.png" style="position:absolute; top:3px; right:3px;" />
                                            </span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="list" scope="col">거래처명</th>
                                        <th class="list" scope="col">
                                            <input type="text" placeholder="거래처명을 입력해주세요." v-model="compName"
                                                   style="text-align:center;" />
                                        </th>
                                    </tr>
                                </tbody>
                            </table>

                            <table style="margin-top: 30px;">
                                <colgroup>
                                    <col width="50px">
                                    <col width="*">
                                    <col width="80px">
                                    <col width="80px">
                                    <col width="80px">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th class="list" scope="col">
                                            <input type="checkbox" class="dev_checkbox_all pu_tableCheck" />
                                        </th>
                                        <th class="list" scope="col">품명</th>
                                        <th class="list" scope="col">규격</th>
                                        <th class="list" scope="col">단가</th>
                                        <th class="list" scope="col">수량</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, idx) in list_1">
                                        <td class="list">
                                            <input type="checkbox" name="no_checked" class="dev_checkbox_each pu_tableCheck" v-bind:value="idx" />
                                        </td>
                                        <td class="list" style="font-size: 13px;">
                                            {{ row.TITLE }}
                                        </td>
                                        <td class="list" style="font-size: 13px;">
                                            {{ row.STANDARD }}
                                        </td>
                                        <td class="list" style="font-size: 13px;">
                                            {{ $ConMoney(row.PRICE) }} 원
                                        </td>
                                        <td class="list dev_row_checked" style="font-size: 13px;">
                                            <input type="number" name="qty" v-model="row.QTY" style="width:100%; text-align:right;" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="pu_wrapper_btn">
                                <a class="pu_btn_apply" v-on:click="exec('APPLY', {})">단가 적용 하기</a>
                                <a class="pu_btn_print" v-on:click="exec('PRINT', {})">{{ gubunTitle }} 다운로드</a>
                            </div>

                        </div>

                        <div id="dev_print_this" class="pu_tableX" style="margin-top: 20px; margin-left: 20px; padding: 25px; width: calc(100% - 520px); min-width: 680px; height:1040px; border: solid 1px #000000; float: left;">

                            <span style="margin: 20px auto; width: fit-content; font-size: 30px; font-weight:700; letter-spacing: 30px; display: block;">
                                {{ gubunTitle }}
                            </span>

                            <table>
                                <colgroup>
                                    <col width="85px">
                                    <col width="*">
                                    <col width="80px">
                                    <col width="150px">
                                    <col width="70px">
                                    <col width="120px">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td rowspan="2" style="font-size: 14px; text-align: center;">발행일자</td>
                                        <td rowspan="2" style="font-size: 14px; text-align: center;">
                                            {{ printDate }}
                                        </td>
                                        <td style="font-size: 14px; text-align: center;">등록번호</td>
                                        <td colspan="3" style="font-size: 14px; text-align: center;">129-86-14186</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 14px;text-align:center;">상 호<br />(법인명)</td>
                                        <td style="font-size: 14px;text-align:center;">(주)정피엔씨연구소</td>
                                        <td style="font-size: 14px;text-align:center;">성 명</td>
                                        <td style="font-size: 14px;text-align:center; position:relative;">
                                            정 영 철&emsp;(인)
                                            <img src="/images/stamp_jpnc.png"
                                                 style="width: 90px; position: absolute; right: -16px; top: -10px;" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" style="font-size: 14px;text-align:center;">거래처명</td>
                                        <td rowspan="2" style="font-size: 14px;text-align:center;">
                                            {{ compName }}
                                        </td>
                                        <td style="font-size: 14px; text-align: center;">주 소</td>
                                        <td colspan="3" style="font-size: 14px; text-align: center;">경기도 용인시 기흥구 흥덕중앙로 120 1505호</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 14px;text-align:center;">업 태</td>
                                        <td style="font-size: 14px;text-align:center;">서비스</td>
                                        <td style="font-size: 14px;text-align:center;">종 목</td>
                                        <td style="font-size: 14px;text-align:center;">연구 및 개발업</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 14px;text-align:center;">금 액<br />(VAT포함)</td>
                                        <td style="font-size: 14px;text-align:center;">
                                            &#8361; {{ $ConMoney(priceTot) }} 원
                                        </td>
                                        <td style="font-size: 14px;text-align:center;">전화번호</td>
                                        <td style="font-size: 14px;text-align:center;">031-708-8113</td>
                                        <td style="font-size: 14px;text-align:center;">팩 스</td>
                                        <td style="font-size: 14px;text-align:center;">031-702-8110</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="pu_table_detail" style="margin-top: 10px;">
                                <colgroup>
                                    <col width="*">
                                    <col width="80px">
                                    <col width="60px">
                                    <col width="90px">
                                    <col width="70px">
                                    <col width="120px">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td style="text-align:center;">품 명</td>
                                        <td style="text-align:center;">규 격</td>
                                        <td style="text-align:center;">수 량</td>
                                        <td style="text-align:center;">단 가</td>
                                        <td style="text-align:center;">세 액</td>
                                        <td style="text-align:center;">금 액</td>
                                    </tr>

                                    <tr v-for="(row, idx) in list_2">
                                        <td style="font-size:13px; text-align:left;">{{ row.TITLE }}</td>
                                        <td style="font-size:13px; text-align:center;">{{ row.STANDARD }}</td>
                                        <td style="font-size:13px; text-align:right;">{{ row.QTY ? $ConMoney(row.QTY) : "" }}</td>
                                        <td style="font-size:13px; text-align:right;">{{ row.PRICE ? $ConMoney(row.PRICE) : "" }}</td>
                                        <td style="font-size:13px; text-align:right;">{{ row.PRICE ? $ConMoney(row.PRICE * 0.1) : "" }}</td>
                                        <td style="font-size:13px; text-align:right;">{{ row.PRICE ? $ConMoney(row.PRICE * 1.1 * row.QTY) : "-" }}</td>
                                    </tr>

                                    <tr>
                                        <td colspan="5" style="font-size: 14px; text-align: center;">합 계</td>
                                        <td style="font-size: 14px; text-align: right;">&#8361; {{ $ConMoney(priceTot) }} 원</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->
        <!-------------------------- 하단footer부분 (s) ------------------------>
        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>
        <inc_user_foot />
        <!-------------------------- 하단footer부분 (e) ------------------------>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , gubunDocs: "a"
                , gubunTitle: "견적서"

                , printDate: ""
                , compName: ""
                , priceTot: 0

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("DOCS_A", {});
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "DOCS_A":
                        {
                            _this.gubunDocs = 'a';

                            // url 설정
                            url = "/vue_api/user/gmo/price_item/list";
                        }
                        break;

                    case "DOCS_B":
                        {
                            _this.gubunDocs = 'b';

                            // url 설정
                            url = "/vue_api/user/gmo/price_item/list";
                        }
                        break;

                    case "DOCS_C":
                        {
                            _this.gubunDocs = 'c';
                        }
                        break;

                    case "DOCS_D":
                        {
                            _this.gubunDocs = 'd';
                        }
                        break;

                    case "APPLY":
                        {

                        }
                        break;

                    case "PRINT":
                        {
                            if (!_this.printDate) {
                                alert("발행일자를 선택해주세요.");
                                return;
                            }

                            if (!_this.compName) {
                                alert("거래처명을 입력해주세요.");
                                return;
                            }

                            if (_this.priceTot < 1) {
                                alert("물품 및 수량 설정 후\r\n[단가 적용 하기] 버튼을 클릭해주세요.");
                                return;
                            }

                            if (!confirm(_this.gubunTitle + "를 다운로드 하시겠습니까?")) {
                                return;
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "DOCS_A":
                        {
                            _this.gubunTitle = "견적서";
                            _this.list_1 = data.LIST_1;

                            _this.exec("APPLY", {});
                        }
                        break;

                    case "DOCS_B":
                        {
                            _this.gubunTitle = "거래명세서";
                            _this.list_1 = data.LIST_1;

                            _this.exec("APPLY", {});
                        }
                        break;

                    case "DOCS_C":
                        {
                            const link = document.createElement('a')
                            link.href = "/images/jpnc_doc_comp.jpg"
                            link.download = '사업자등록증'
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                        }
                        break;

                    case "DOCS_D":
                        {
                            const link = document.createElement('a')
                            link.href = "/images/jpnc_doc_bank.jpg"
                            link.download = '통장사본'
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                        }
                        break;

                    case "APPLY":
                        {
                            // 초기화
                            _this.list_2 = [];

                            // 총가격
                            _this.priceTot = 0;

                            // 데이터 복제
                            var $arrOb = $("[name='no_checked']:checked");
                            for (var idx = 0; idx < $arrOb.length; idx++) {

                                var row = _this.list_1[$($arrOb[idx]).val()];
                                if (row.PRICE > 0 && row.QTY > 0) {

                                    _this.list_2.push(row);
                                    _this.priceTot += row.PRICE * 1.1 * row.QTY;
                                }
                            }

                            // 빈칸 채우기
                            var lim = _this.list_2.length;
                            for (var idx = 0; idx < (15 - lim); idx++) {
                                _this.list_2.push({});
                            }

                            console.log(lim);
                        }
                        break;

                    case "PRINT":
                        {
                            html2canvas(
                                document.getElementById("dev_print_this")
                                , { scale: 5 }
                            ).then(function (canvas) {
                                var el = document.createElement("a")
                                el.href = canvas.toDataURL("image/jpeg")
                                el.download = _this.gubunTitle + '.jpg' //다운로드 할 파일명 설정
                                el.click()
                            })

                            /*
                            $('#dev_print_this').printThis({
                                debug: false,               // show the iframe for debugging
                                importCSS: true,            // import page CSS
                                importStyle: false,         // import style tags
                                printContainer: true,       // grab outer container as well as the contents of the selector
                                //loadCSS: "path/to/my.css",  // path to additional css file - us an array [] for multiple
                                pageTitle: "",              // add title to print page
                                removeInline: false,        // remove all inline styles from print elements
                                printDelay: 500,            // variable print delay
                                header: null,               // prefix to html
                                footer: null,               // postfix to html
                                base: false,                // preserve the BASE tag, or accept a string for the URL
                                formValues: true,           // preserve input/form values
                                canvas: true,               // copy canvas elements (experimental)
                                //doctypeString: '...',     // enter a different doctype for older markup
                                removeScripts: false,       // remove script tags from print content
                                copyTagClasses: false       // copy classes from the html & body tag
                            });
                            */
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // 일/주/월별 조회
            , searchByDate: function (gubun) {
                this.gubunDate = gubun;
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>

<style>

    .pu_gmo_docs .pu_tableY .list {
        width: unset;
    }

    .pu_gmo_docs .pu_search_btn_ul {
        margin-bottom: 3px;
        width: 100%;
        float: left
    }

        .pu_gmo_docs .pu_search_btn_ul li {
            margin-right: 2px;
            float: left;
        }

            .pu_gmo_docs .pu_search_btn_ul li a {
            }

                .pu_gmo_docs .pu_search_btn_ul li a span {
                    width: 100px;
                    height: 33px;
                    line-height: 33px;
                    font-weight: 400;
                    text-align: center;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    background-color: #ffffff;
                    cursor: pointer;
                    display: block;
                }

            .pu_gmo_docs .pu_search_btn_ul li.pu_on a span,
            .pu_gmo_docs .pu_search_btn_ul li:hover a span {
                background: #D8006B;
                color: #fff;
                border-color: #D8006B;
            }

    .pu_gmo_docs .pu_listSearch .pu_search01 {
        width: 100%;
    }

    .pu_gmo_docs .pu_dateSelect {
        float: left;
    }

    .pu_gmo_docs .pu_search01 select {
        width: auto;
    }

    .pu_gmo_docs .pu_input_wrap {
        width: 50px !important;
    }

    .pu_gmo_docs .pu_tableY th {
        line-height: 25px;
        height: 40px;
    }

    .pu_gmo_docs .pu_wrapper_btn {
        margin-top: 20px;
        margin-bottom: 30px;
        float: right;
    }

    .pu_gmo_docs .pu_btn_apply,
    .pu_gmo_docs .pu_btn_print {
        margin-left: 5px;
        width: 160px;
        font-size: 17px;
        height: 40px;
        line-height: 40px;
        font-weight: 400;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        background: #D8006B;
        color: #fff;
        border-color: #D8006B;
    }

        .pu_gmo_docs .pu_btn_apply:hover,
        .pu_gmo_docs .pu_btn_print:hover {
            background: #ffffff;
            color: #D8006B;
            border: 1px solid #D8006B;
        }

    .pu_gmo_docs .pu_tableX table tr td {
        border-top: 1px solid #000000;
        border-right: 1px solid #000000;
        border-left: 1px solid #000000;
        border-bottom: 1px solid #000000;
    }

    .pu_gmo_docs .pu_tableX .pu_table_detail tr td {
        padding: 7px;
    }
</style>