<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_zadmin_head />
            <inc_zadmin_left />
            <!-------------------------- 인클루드(e) -------------------------->

            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">

                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_zadmin_title />
                    <!-------------------------- 인클루드(e) -------------------------->

                    <!-- 검색조건 (s) -->
                    <section class="pu_searchSection">
                        <form id="searchForm" class="pu_searchBox" onsubmit="return false">

                            <dl>
                                <dt class="pu_wd90"><label for="">관리선택</label></dt>
                                <dd>
                                    <div>
                                        <span>
                                            <input type="radio" name="grade" id="grade_0" value="" />
                                            <label for="grade_0">전체</label>
                                        </span>

                                        <span v-for="(grade, idx) in list_grade">
                                            <input type="radio" v-bind:id="`grade_${idx + 1}`" name="grade" v-bind:value="grade.CODE" />
                                            <label v-bind:for="`grade_${idx + 1}`">{{grade.TITLE}}</label>
                                        </span>
                                    </div>
                                </dd>
                            </dl>

                            <dl>
                                <dt class="pu_wd90"><label for="">검색조건</label></dt>
                                <dd>
                                    <select name="searchtype" class="pu_select-box" title="조건을 선택하세요." style="width:150px">
                                        <option value="NAME">이름</option>
                                        <option value="ID">아이디</option>
                                    </select>
                                    <input type="text" name="searchtext" placeholder="검색어를 입력해주세요." style="width:300px">
                                </dd>
                            </dl>
                        </form>
                        <div class="pu_searchBtn"><a class="xi-search" href="javascript:;" v-on:click="exec('SEARCH', {'curPage': 1})"><span>검색</span></a></div>
                    </section>
                    <!-- 검색조건(e) -->
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <p class="pu_listNum">
                                <i class="xi-user"></i> 가입 회원 <strong>{{ rownum }}</strong>명
                            </p>
                            <div class="pu_TableX01">
                                <table>
                                    <colgroup>
                                        <col width="5%">
                                        <col width="10%">
                                        <col width="*">
                                        <col width="*">
                                        <col width="*">
                                        <col width="10%">
                                        <col width="10%">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">번호</th>
                                            <th scope="col">권한</th>
                                            <th scope="col">이름</th>
                                            <th scope="col">아이디</th>
                                            <th scope="col">부서</th>
                                            <th scope="col">마지막 접속일</th>
                                            <th scope="col">등록일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row, idx) in list_1">
                                            <td>{{ getRowNum(idx) }}</td>
                                            <td>{{ gradeName(row.GRADE) }}</td>
                                            <td><a href="javascript:;" v-on:click="$PageMove('./admin_view?no=' + row.NO)">{{ row.NAME }}</a></td>
                                            <td><a href="javascript:;" v-on:click="$PageMove('./admin_view?no=' + row.NO)">{{ row.ID }}</a></td>
                                            <td>{{ row.DEPARTMENT }}</td>
                                            <td>{{ row.LASTLOGIN_DATE }}</td>
                                            <td>{{ row.REGI_DATE }}</td>
                                        </tr>
                                        <tr v-if="list_1.length == 0">
                                            <td colspan="7">조회된 결과가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->

                        <!-- 하단 페이징영역 (s)  -->
                        <div class="pu_paging">
                            <div class="pu_pagination" v-html="paging">
                            </div>
                        </div>
                        <!-- 하단 페이징영역 (e)  -->

                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_left">
                                <a class="pu_btn_blue" href="javascript:;" v-on:click="$PageMove('./admin_regi')"><i class="xi-plus"></i> 계정생성</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (e)  -->

                    </section>
                    <!-- 컨텐츠영역 (e) -->

                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_grade: []

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("OPT_1", {});
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "OPT_1":
                        {
                            // url 설정
                            url = "/vue_api/common/common/list_code";

                            data["gubun"] = "GRADE_ADMIN";
                        }
                        break;

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/homepage/admin_list/list";

                            // 폼
                            data["gubun_page"] = _this.gubun_page;
                            data["curPage"] = params.curPage;
                            data["grade"] = qs.page_gubun;

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "OPT_1":
                        {
                            _this.list_grade = data.LIST_1;

                            // 검색
                            _this.exec("SEARCH", { "curPage": 1 });
                        }
                        break;

                    case "SEARCH":
                        {
                            _this.rownum = data.ROWNUM;
                            _this.paging = data.PAGING;
                            _this.list_1 = data.LIST_1;
                            _this.list_2 = data.LIST_2;
                            _this.list_3 = data.LIST_3;
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // 권한명
            , gradeName: function(grade){

                if (grade == 'A')
                    return "슈퍼관리자";
                else if (grade == 'B')
                    return "관리자";
                else if (grade == 'C')
                    return "휴면회원";
                else if (grade == 'D')
                    return "탈퇴회원";
                else
                    return "";
                 
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>