<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_zadmin_head />
            <inc_zadmin_left />
            <!-------------------------- 인클루드(e) -------------------------->

            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">
                    
                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_zadmin_title />
                    <!-------------------------- 인클루드(e) -------------------------->

                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">
                                <form id="regiForm">
                                    <table>
                                        <colgroup>
                                            <col width="15%">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>아이디*</th>
                                                <td>
                                                    <input type="text" name="id" v-model:value="view_1.ID" v-show="!view_1.NO" style="width:80%" />
                                                    {{ view_1.NO ? view_1.ID : "" }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>비밀번호*<br />(빈칸=변경안됨)</th>
                                                <td><input type="password" name="pw" style="width:80%"></td>
                                            </tr>
                                            <tr>
                                                <th>이름*</th>
                                                <td>
                                                    <input type="text" name="name" v-model:value="view_1.NAME" style="width:80%" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>부서</th>
                                                <td>
                                                    <input type="text" name="department" v-model:value="view_1.DEPARTMENT" style="width:80%" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>권한*</th>
                                                <td>
                                                    <div>
                                                        <span v-for="(grade, idx) in list_grade">
                                                            <input type="radio" v-bind:id="`grade_${idx + 1}`" name="grade" v-bind:value="grade.CODE" v-model="view_1.GRADE">
                                                            <label v-bind:for="`grade_${idx + 1}`">{{grade.TITLE}}</label>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->
                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01">
                            <article class="pu_f_left">
                                <a class="pu_btn_dark" href="javascript:history.back();"><i class="xi-close"></i> 취소</a>
                                <a class="pu_btn_blue" href="javascript:;" v-on:click="exec('SAVE', {})"><i class="xi-check"></i> 저장</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {
        
        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "REGI"

                , rownum: 0    // 번호
                , paging: ""   // 페이징
                
                , list_grade: []

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {
            
            ...mapActions({
                setBase: "setBase"
            })
            
            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("OPT_1", {});
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "OPT_1":
                        {
                            // url 설정
                            url = "/vue_api/common/common/list_code";

                            data["gubun"] = "GRADE_ADMIN";
                        }
                        break;

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/homepage/admin_list/view";

                            // 폼
                            data["gubun_page"] = _this.gubun_page;
                            data["no"] = qs.no;
                        }
                        break;
                        
                    case "SAVE":
                        {
                            // 아이디
                            var id = $("[name='id']");
                            if (!id.val().trim()) {
                                alert("아이디를 입력해주세요");
                                id.focus();
                                return false;
                            }

                            // 비밀번호
                            var pw = $("[name='pw']");
                            if (!pw.val().trim()) {
                                alert("비밀번호를 입력해주세요");
                                pw.focus();
                                return false;
                            }

                            // 권한
                            var show_yn = $("[name='grade']:checked");
                            if (show_yn.length < 1) {
                                alert("권한을 선택해주세요");
                                $("[name='grade']")[0].focus();
                                return false;
                            }
                            
                            // 이름
                            var name = $("[name='name']");
                            if (!name.val().trim()) {
                                alert("이름을 입력해주세요");
                                name.focus();
                                return false;
                            }

                            //if (confirm(INFO_CONF_SAVE))                             
                            {
                                // dimm 보이기
                                _this.$ShowDimmLogo();

                                // url 설정
                                url = "/vue_api/zadmin/homepage/admin_list/save";

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));
                                data["gubun_page"] = this.gubun_page;
                                data["no"] = qs.no;
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "OPT_1":
                        {
                            _this.list_grade = data.LIST_1;

                            // 검색
                            _this.exec("SEARCH", { "curPage": 1 });
                        }
                        break;

                    case "SEARCH":
                        {
                            _this.view_1 = data.VIEW_1;
                        }
                        break;
                        
                    case "SAVE":
                        {
                            
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // 권한명
            , gradeName: function(grade){

                if (grade == 'A')
                    return "슈퍼관리자";
                else if (grade == 'B')
                    return "관리자";
                else if (grade == 'C')
                    return "휴면회원";
                else if (grade == 'D')
                    return "탈퇴회원";
                else
                    return "";
                 
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>