<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_zadmin_head />
            <inc_zadmin_left />
            <!-------------------------- 인클루드(e) -------------------------->

            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">

                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_zadmin_title />
                    <!-------------------------- 인클루드(e) -------------------------->
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">
                                <form id="regiForm" onsubmit="return false">
                                    <table>
                                        <colgroup>
                                            <col width="10%">
                                            <col width="40%">
                                            <col width="10%">
                                            <col width="40%">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>제목*</th>
                                                <td colspan="3">
                                                    <input name="title" type="text" v-model="view_1.TITLE" style="width: 100%" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    이미지<br />
                                                    <a class="pu_ss_btn_blue" href="javascript:;" v-on:click="exec('ADDFILES', {})"
                                                       style="margin-top: 10px; width: 90px; height: 30px; line-height: 30px;">파일추가</a>
                                                </th>
                                                <td colspan="3">
                                                    <ul id="file_item_list" class="pu_file_item_ul"
                                                        v-html="$ConHtml(view_1.HTML_FILE_ITEM_UPLOAD)"></ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>팝업 크기</th>
                                                <td>
                                                    <span style="display: block;">
                                                        가로 : <input type="text" name="width" class="dev_numnumnum" v-model="view_1.WIDTH" />px
                                                    </span>
                                                    <span style="margin-top: 3px; display: block;">
                                                        세로 : <input type="text" name="height" class="dev_numnumnum" v-model="view_1.HEIGHT" />px
                                                    </span>
                                                </td>
                                                <th>팝업 위치</th>
                                                <td>
                                                    <span style="display: block;">
                                                        가로 : <input type="text" name="lo_x" class="dev_numnumnum" v-model="view_1.LO_X" />px
                                                    </span>
                                                    <span style="margin-top: 3px; display: block;">
                                                        세로 : <input type="text" name="lo_y" class="dev_numnumnum" v-model="view_1.LO_Y" />px
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>링크</th>
                                                <td colspan="3">
                                                    <input type="text" name="link" v-model="view_1.LINK" style="width: 100%" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>게시기간</th>
                                                <td colspan="3">
                                                    <span>
                                                        <input type="text" name="sdate" class="pu_calendar" readonly="readonly"
                                                               v-model:value="view_1.SDATE" onclick="pjh_calendar(undefined, '[name=sdate]', 'YYYY-MM-DD')" />
                                                        <input type="image" src="/images/icon_calendar.png" />
                                                    </span>
                                                    ~
                                                    <span>
                                                        <input type="text" name="edate" class="pu_calendar" readonly="readonly"
                                                               v-model:value="view_1.EDATE" onclick="pjh_calendar(undefined, '[name=edate]', 'YYYY-MM-DD')" />
                                                        <input type="image" src="/images/icon_calendar.png" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>적용여부</th>
                                                <td colspan="3">
                                                    <input type="radio" id="showyn1" name="show_yn" value="Y" v-model="view_1.SHOW_YN"><label for="showyn1">적용</label>
                                                    <input type="radio" id="showyn2" name="show_yn" value="N" v-model="view_1.SHOW_YN"><label for="showyn2">미적용</label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->
                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_left">
                                <a class="pu_btn_dark" href="javascript:history.back();"><i class="xi-close"></i> 취소</a>
                                <a class="pu_btn_blue" href="javascript:;" v-on:click="exec('SAVE', {})"><i class="xi-check"></i> 저장</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "REGI"

                , cnt_lim_pic: 1  // 사진 갯수
                , imagefile: ""

                , rownum: 0        // 번호
                , paging: ""       // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/homepage/board_mainpopup/view";

                            // 폼
                            data["no"] = qs.no;
                        }
                        break;

                    case "SAVE":
                        {
                            // 체크
                            var title = $("[name='title']");
                            if (!title.val().trim()) {
                                alert("제목를 입력해주세요");
                                title.focus();
                                return false;
                            }

                            //if (confirm(INFO_CONF_SAVE))                             
                            {
                                // dimm 보이기
                                _this.$ShowDimmLogo();

                                // url 설정
                                url = "/vue_api/zadmin/homepage/board_mainpopup/save";

                                // 폼
                                data["no"] = qs.no;

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));
                            }
                        }
                        break;

                    case "ADDFILES":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.view_1 = data.VIEW_1;
                        }
                        break;

                    case "SAVE":
                        {

                        }
                        break;

                    case "ADDFILES":
                        {
                            AddFiles("board_mainpopup", _this.cnt_lim_pic, ".jpg,.jpeg,.png");
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>