<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->

        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_title />
            <!-------------------------- 인클루드(e) -------------------------->

            <!-- 컨텐츠 영역 (s) -->
            <section>
                <div v-show="view_1.NO" class="pu_subTitle01">
                    <article>
                        <h2>{{ view_1.TITLE }}</h2>
                    </article>
                </div>
                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">
                    <p style="min-height: 600px;" v-html="view_1.CONTENTS" />
                </div>
                <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->

        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {
        
        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "VIEW"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })
            
            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/board/cms/viewone";

                            // 폼
                            data["curPage"] = params.curPage;
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.view_1 = data.VIEW_1;
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>