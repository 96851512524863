/* 

[[ readme ]]

- store.js 는 각 vue에서 사용되는 공통 데이터 및 함수를 정의 한다.
- actions > mutations > getters > state 로 접근한다.

도움 : https://any-ting.tistory.com/44

*/

// [[ 라이브러리 import ]]
import Vue from "vue"; // vue
import Vuex from "vuex"; // vuex

Vue.use(Vuex);

// [[ vuex 설정 ]]
export const store = new Vuex.Store({

    // 데이터
    state: {

        base: {

            curPath: ""             // 현재 접속경로
            , curQs: ""             // 현재 쿼리스트링
            , curQsMap: {}          // 현재 쿼리스트링 맵

            //--------------------------------------------------

            , userLoginYn: "N"      // 유저 로그인 여부
            , userNo: "-1"          // 유저 번호
            , userGrade: "Z"        // 유저 권한

            , userMenuCode: ""      // 유저 코드

            , userMenuCode_1d: ""   // 유저 1뎁스 코드
            , userMenuCode_2d: ""   // 유저 2뎁스 코드
            , userMenuCode_3d: ""   // 유저 3뎁스 코드

            , userMenuName_1d: ""   // 유저 1뎁스 명
            , userMenuName_2d: ""   // 유저 2뎁스 명
            , userMenuName_3d: ""   // 유저 3뎁스 명

            , mapUserMenu: []       // 유저 메뉴 맵
            , arrUserMenu_1d: []    // 유저 1뎁스 메뉴
            , arrUserMenu_2d: []    // 유저 2뎁스 메뉴
            , arrUserMenu_3d: []    // 유저 3뎁스 메뉴

            //--------------------------------------------------

            , adminLoginYn: "N"     // 관리자 로그인 여부
            , adminNo: "-1"         // 관리자 번호
            , adminGrade: "Z"       // 관리자 권한

            , adminMenuCode: ""     // 관리자 코드

            , adminMenuCode_1d: ""  // 관리자 1뎁스 코드
            , adminMenuCode_2d: ""  // 관리자 2뎁스 코드
            , adminMenuCode_3d: ""  // 관리자 3뎁스 코드

            , adminMenuName_1d: ""  // 관리자 1뎁스 명
            , adminMenuName_2d: ""  // 관리자 2뎁스 명
            , adminMenuName_3d: ""  // 관리자 3뎁스 명

            , mapAdminMenu: []      // 관리자 메뉴 맵
            , arrAdminMenu_1d: []   // 관리자 1뎁스 메뉴
            , arrAdminMenu_2d: []   // 관리자 2뎁스 메뉴
            , arrAdminMenu_3d: []   // 관리자 3뎁스 메뉴
        }
    },

    // 게터
    getters: {

        // Base
        getBase: (state) => {

            // 불러오기
            if (state.base.arrUserMenu_1d.length == 0
                && state.base.arrAdminMenu_1d.length == 0) {

                var base = JSON.parse(localStorage.getItem("HYPER_WEB"))
                if (base) {
                    state.base = base;
                }
            }

            return state.base;
        },

    },

    // 동기 메서드
    mutations: {

        // Base
        setBase: (state, param) => {

            // 데이터 업데이트
            var keys = Object.keys(param);
            for (var idx = 0; idx < keys.length; idx++) {
                state.base[keys[idx]] = param[keys[idx]];
            }

            // local 저장하기
            localStorage.setItem("HYPER_WEB", JSON.stringify(state.base));

        },

    },

    // 비동기 메서드 : api 에서 데이터 받아서 반영 > 동기 메서드
    actions: {

        // Base
        setBase: ({ commit, state }, param) => {
            commit("setBase", param);
        },

    },

    // module
    modules: {
    },

});
