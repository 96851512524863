<template>
    <!-------------------------- 하단footer부분 (s) ------------------------>
    <div class="pu_footerWrap">
        <footer class="pu_jpncW">

            <ul class="pu_footMenu">
                <li><a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=30')">회사소개</a></li>
                <li><a href="javascript:;" v-on:click="$PageMove('/user/board/cms_view?page_gubun=33')">찾아오시는길</a></li>
                <!--<li><a href="javascript:;">이용약관</a></li>-->
                <!--<li><a href="javascript:;">개인정보보호정책</a></li>-->
            </ul>

            <address>
                <ul>
                    <li>(주)정피엔씨연구소 ｜ 대표: 정영철  ｜ 사업자등록번호 : 129-86-14186 ｜ 통신판매업 : 제2011-용인기흥-00370호</li>
                    <li>주소 : 경기도 용인시 기흥구 흥덕중앙로 120 U-Tower 1504, 1505 호</li>
                    <li><span>사무실</span> Tel: 031-704-8113  Fax: 031-705-0296  E-mail: pnc8113@jpnc.co.kr</li>
                    <li><span>실험실</span> Tel: 031-708-8113  Fax: 031-702-8110  E-mail: gmodna@jpnc.co.kr</li>
                    <li>@ Copyright Jung P&C Institute, Inc. All right reserved</li>
                    <!--<li>@ Copyright <a href="http://hyper-nomad.com" style="color: rgb(170, 170, 170);">HyperNomad</a>. All right reserved</li>-->
                </ul>
            </address>

            <ul class="pu_footerSns">
                <!--
                <li><a href="javascript:"><i class="xi-facebook-official xi-2x"></i> <span>페이스북</span></a></li>
                <li><a href="javascript:"><i class="xi-naver xi-2x"></i> <span>네이버</span></a></li>
                <li><a href="javascript:"><i class="xi-twitter xi-2x"></i> <span>트위터</span></a></li>
                <li><a href="javascript:"><i class="xi-youtube xi-2x"></i> <span>유튜브</span></a></li>
                -->
            </ul>
        </footer>
    </div>
    <!-------------------------- 하단footer부분 (e) ------------------------>
</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        },

        data() {
            return {
                THIS_NAME: "INC USER FOOT"
            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

            }

        },

        beforeCreate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeCreate");
        },

        created() {
            // this.$PrintLog(this.THIS_NAME + " : created");
        },

        beforeMount() {
            // this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.default();
        },

        beforeUpdate() {
            // this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            // this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
            // this.$PrintLog(this.THIS_NAME + " : beforeDestroy");
        },

        destroyed() {
            // this.$PrintLog(this.THIS_NAME + " : destroyed");
        },
    };
</script>
