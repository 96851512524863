<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_carcass pu_contentWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_title />
            <!-------------------------- 인클루드(e) -------------------------->
            <!-- 서브 상단 영역 (s) -->
            <section>
                <div class="pu_subTitle01">
                    <article>
                        <h2>{{ getBase.userMenuName_3d }}</h2>
                        <p></p>
                    </article>
                </div>
                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">
                    <div class="pu_newsListDiv">
                        <div class="pu_listSearch">
                            <form id="searchForm" onsubmit="return false;">
                                <div class="pu_search01">
                                    <div class="pu_row_01">
                                        <ul class="pu_search_btn_ul">
                                            <li :class="{'pu_on' : gubunDate == 'd'}" v-on:click="searchByDate('d')"><a href="javascript:;"><span>일별</span></a></li>
                                            <li :class="{'pu_on' : gubunDate == 'w'}" v-on:click="searchByDate('w')"><a href="javascript:;"><span>주별</span></a></li>
                                            <li :class="{'pu_on' : gubunDate == 'm'}" v-on:click="searchByDate('m')"><a href="javascript:;"><span>월별</span></a></li>
                                        </ul>
                                    </div>

                                    <div class="pu_row_02">
                                        <div style="width:245px; float:left;">
                                            <select name="year" v-model="searchYear" v-if="gubunDate == 'm' || gubunDate == 'w'" style="width:120px;">
                                                <option v-for="(item, idx) in arrYYYY" v-bind:value="item">{{item}} 년</option>
                                            </select>

                                            <div class="pu_dateSelect" v-if="gubunDate == 'd'">
                                                <ul class="pu_date_calendar" style="width: 240px;">
                                                    <li>
                                                        <input type="text" name="dateSt" class="pu_calendar" v-model="searchDateSt" readonly="readonly" onclick="pjh_calendar(null, '[name=dateSt]', 'YYYY-MM-DD')">
                                                        <input type="image" src="/images/icon_calendar.png">
                                                    </li>
                                                    <li>~</li>
                                                    <li>
                                                        <input type="text" name="dateEd" class="pu_calendar" v-model="searchDateEd" readonly="readonly" onclick="pjh_calendar(null, '[name=dateEd]', 'YYYY-MM-DD')">
                                                        <input type="image" src="/images/icon_calendar.png">
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="pu_input_wrap">
                                            <a href="javascript:;" class="pu_btnSerch01" v-on:click="exec('SEARCH', {'curPage': 1})">검색</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="pu_totalTxt">총 <span>{{ list_1.length }}</span>개 조회완료</div>
                        </div>
                        <div class="pu_tableY" style="min-width:900px;">
                            <table>
                                <colgroup>
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                    <col style="width:*%;">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th class="list" scope="col" rowspan="2" style="line-height:25px;">번호</th>
                                        <th class="list" scope="col" rowspan="2" style="line-height:25px;border-left: solid 1px #333; border-right: solid 1px #333;">날짜</th>
                                        <th class="list" scope="col" colspan="2" style="line-height:25px;border-right: solid 1px #333;">전국<br />(제주제외)</th>
                                        <th class="list" scope="col" colspan="2" style="line-height:25px;border-right: solid 1px #333;">수도권</th>
                                        <th class="list" scope="col" colspan="2" style="line-height:25px;border-right: solid 1px #333;">중부권</th>
                                        <th class="list" scope="col" colspan="2" style="line-height:25px;border-right: solid 1px #333;">영남권</th>
                                        <th class="list" scope="col" colspan="2" style="line-height:25px;border-right: solid 1px #333;">호남권</th>
                                        <th class="list" scope="col" colspan="2" style="line-height: 25px;">제주권</th>
                                    </tr>
                                    <tr>
                                        <th class="list" scope="col" style="line-height: 25px;">두수</th>
                                        <th class="list" scope="col" style="line-height: 25px; border-right: solid 1px #333;">평균</th>
                                        <th class="list" scope="col" style="line-height: 25px;">두수</th>
                                        <th class="list" scope="col" style="line-height: 25px; border-right: solid 1px #333;">평균</th>
                                        <th class="list" scope="col" style="line-height: 25px;">두수</th>
                                        <th class="list" scope="col" style="line-height: 25px; border-right: solid 1px #333;">평균</th>
                                        <th class="list" scope="col" style="line-height: 25px;">두수</th>
                                        <th class="list" scope="col" style="line-height: 25px; border-right: solid 1px #333;">평균</th>
                                        <th class="list" scope="col" style="line-height: 25px;">두수</th>
                                        <th class="list" scope="col" style="line-height: 25px; border-right: solid 1px #333;">평균</th>
                                        <th class="list" scope="col" style="line-height: 25px;">두수</th>
                                        <th class="list" scope="col" style="line-height: 25px;">평균</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, idx) in list_1">
                                        <td class="list" style="text-align: center; border-right: solid 1px #333;">{{ list_1.length - idx }}</td>
                                        <td class="list" style="text-align: center; border-right: solid 1px #333;">{{ item.DATE }}</td>
                                        <td class="list" style="text-align: right;">{{ $ConMoney(item.ALL_NOT_JJ_COUNT) }}</td>
                                        <td class="list" style="text-align: right; border-right: solid 1px #333;">{{ $ConMoney(item.ALL_NOT_JJ_PRICE) }}</td>
                                        <td class="list" style="text-align: right;">{{ $ConMoney(item.SUDO_COUNT) }}</td>
                                        <td class="list" style="text-align: right; border-right: solid 1px #333;">{{ $ConMoney(item.SUDO_PRICE) }}</td>
                                        <td class="list" style="text-align: right;">{{ $ConMoney(item.JUNGBU_COUNT) }}</td>
                                        <td class="list" style="text-align: right; border-right: solid 1px #333;">{{ $ConMoney(item.JUNGBU_PRICE) }}</td>
                                        <td class="list" style="text-align: right;">{{ $ConMoney(item.YUNGNAM_COUNT) }}</td>
                                        <td class="list" style="text-align: right; border-right: solid 1px #333;">{{ $ConMoney(item.YUNGNAM_PRICE) }}</td>
                                        <td class="list" style="text-align: right;">{{ $ConMoney(item.HONAM_COUNT) }}</td>
                                        <td class="list" style="text-align: right; border-right: solid 1px #333;">{{ $ConMoney(item.HONAM_PRICE) }}</td>
                                        <td class="list" style="text-align: right;">{{ $ConMoney(item.JEJU_COUNT) }}</td>
                                        <td class="list" style="text-align: right;">{{ $ConMoney(item.JEJU_PRICE) }}</td>
                                    </tr>

                                    <tr v-if="list_1.length < 1">
                                        <td colspan="14">조회된 내용이 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->
        <!-------------------------- 하단footer부분 (s) ------------------------>
        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>
        <inc_user_foot />
        <!-------------------------- 하단footer부분 (e) ------------------------>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , gubunDate: "m" // d:일 w:주 m:월
                , searchYear: 2022
                , searchMonth: 12
                , searchDateSt: "2022-12-12"
                , searchDateEd: "2022-12-12"

                , arrYYYY: []

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("DEFAULT", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "DEFAULT":
                        {
                            // 기본날짜 설정
                            {
                                // 년
                                _this.searchYear = new Date().getFullYear();
                                for (let idx = 0; idx < 20; idx++) {
                                    _this.arrYYYY.push(_this.searchYear - idx);
                                }

                                // 월
                                let tempMonth = new Date().getMonth() + 1;
                                _this.searchMonth = (tempMonth < 10 ? "0" + tempMonth : tempMonth);

                                // 일
                                let dd = new Date().getDate();

                                // 년월일
                                _this.searchDateSt = [_this.searchYear, _this.searchMonth, dd].join("-");
                                _this.searchDateEd = [_this.searchYear, _this.searchMonth, dd].join("-");
                            }
                        }
                        break;

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/board/carcass/list";

                            // 폼
                            data["curPage"] = params.curPage;
                            data["gubunDate"] = _this.gubunDate;
                            data["year"] = data["year"] ? data["year"] : _this.searchYear;

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "DEFAULT":
                        {
                            setTimeout(function () {

                                // 조회
                                _this.exec("SEARCH", { "curPage": 1 });

                            }, 500);
                        }
                        break;

                    case "SEARCH":
                        {
                            _this.rownum = data.ROWNUM;
                            _this.paging = data.PAGING;

                            _this.list_1 = data.LIST_1;
                            _this.list_2 = data.LIST_2;
                            _this.list_3 = data.LIST_3;
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // 일/주/월별 조회
            , searchByDate: function (gubun) {
                this.gubunDate = gubun;
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>

<style>

    .pu_carcass .pu_tableY .list {
        width: unset;
    }

    .pu_carcass .pu_search01 {
        width: 300px;
        float: right;
        display: unset;
    }

        .pu_carcass .pu_search01::after {
            display: table;
            content: " ";
            clear: both;
        }

        .pu_carcass .pu_search01 .pu_row_01 {
        }

            .pu_carcass .pu_search01 .pu_row_01 ul {
            }

                .pu_carcass .pu_search01 .pu_row_01 ul::after {
                    display: table;
                    content: " ";
                    clear: both;
                }

                .pu_carcass .pu_search01 .pu_row_01 ul li {
                    margin-right: 3px;
                    float: left;
                }

                    .pu_carcass .pu_search01 .pu_row_01 ul li a {
                        width: 95px;
                        height: 33px;
                        line-height: 33px;
                        font-weight: 400;
                        text-align: center;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        background-color: #ffffff;
                        cursor: pointer;
                        display: block;
                    }

                    .pu_carcass .pu_search01 .pu_row_01 ul li.pu_on a,
                    .pu_carcass .pu_search01 .pu_row_01 ul li:hover a {
                        background: #D8006B;
                        color: #fff;
                        border-color: #D8006B;
                    }

        .pu_carcass .pu_search01 .pu_row_02 {
            margin-top: 3px;
        }

            .pu_carcass .pu_search01 .pu_row_02::after {
                display: table;
                content: " ";
                clear: both;
            }

            .pu_carcass .pu_search01 .pu_row_02 .pu_input_wrap {
                padding: 0;
                width: 35px;
                height: 35px;
                float: left;
                position: relative;
            }

    .pu_carcass .pu_dateSelect .pu_date_calendar li input[type=text] {
        padding: 0 8px;
    }

    @media screen and (max-width: 720px) {

        .pu_carcass .pu_tableY .list {
            width: unset;
        }

        .pu_carcass .pu_search01 {
            width: 100%;
            float: right;
            display: unset;
        }

            .pu_carcass .pu_search01::after {
                display: table;
                content: " ";
                clear: both;
            }

            .pu_carcass .pu_search01 .pu_row_01 {
            }

                .pu_carcass .pu_search01 .pu_row_01 ul {
                }

                    .pu_carcass .pu_search01 .pu_row_01 ul::after {
                        display: table;
                        content: " ";
                        clear: both;
                    }

                    .pu_carcass .pu_search01 .pu_row_01 ul li {
                        margin-right: 3px;
                        float: left;
                    }

                        .pu_carcass .pu_search01 .pu_row_01 ul li a {
                            width: 95px;
                            height: 33px;
                            line-height: 33px;
                            font-weight: 400;
                            text-align: center;
                            border: 1px solid #ccc;
                            border-radius: 4px;
                            background-color: #ffffff;
                            cursor: pointer;
                            display: block;
                        }

                        .pu_carcass .pu_search01 .pu_row_01 ul li.pu_on a,
                        .pu_carcass .pu_search01 .pu_row_01 ul li:hover a {
                            background: #D8006B;
                            color: #fff;
                            border-color: #D8006B;
                        }

            .pu_carcass .pu_search01 .pu_row_02 {
                margin-top: 3px;
            }

                .pu_carcass .pu_search01 .pu_row_02::after {
                    display: table;
                    content: " ";
                    clear: both;
                }

                .pu_carcass .pu_search01 .pu_row_02 .pu_input_wrap {
                    padding: 0;
                    width: 35px;
                    height: 35px;
                    float: left;
                    position: relative;
                }

        .pu_carcass .pu_dateSelect .pu_date_calendar li input[type=text] {
            padding: 0 8px;
        }
    }
</style>