<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-- 컨텐츠 영역 (s) -->
            <section>
                <div class="pu_subTitle01 loginTit">
                    <article>
                        <h2 class="pu_login">Member <span class="pu_txtColor4">아이디, 비밀번호 찾기</span></h2>
                    </article>
                </div>

                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent" style="max-width:500px;">

                    <div class="pu_joinDiv">
                        <div class="pu_tablejoin">
                            <form id="regiForm" onsubmit="return false;">
                                <table>
                                    <colgroup>
                                        <col width="20%" />
                                        <col width="*" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>이름 <strong>*</strong></th>
                                            <td>
                                                <input type="text" name="name" class="dev_koreng_5" style="width:100% !important; text-align:center;" placeholder="이름을 입력해주세요."
                                                       v-model="name" v-bind:readonly="list_1.length > 0" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이메일 <strong>*</strong></th>
                                            <td>
                                                <input type="text" name="email" class="dev_idmail" style="width:100% !important; text-align:center;" placeholder="이메일을 입력해주세요."
                                                       v-model="email" v-bind:readonly="list_1.length > 0" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>연락처 <strong>*</strong></th>
                                            <td>
                                                <input type="text" name="phone" class="dev_phone" style="width:100% !important; text-align:center;" placeholder="연락처를 입력해주세요."
                                                       v-model="phone" v-bind:readonly="list_1.length > 0" />
                                            </td>
                                        </tr>
                                        <tr v-show="list_1.length > 0">
                                            <th>아이디</th>
                                            <td>
                                                <div v-for="(item, idx) in list_1">
                                                    <input type="radio" name="id"
                                                           v-bind:id="`id${idx}`" v-bind:value="item.ID" />
                                                    <label v-bind:for="`id${idx}`">{{ item.ID }}</label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                            <div>
                                <div class="pu_btnDiv01">
                                    <a class="pu_btn_red" href="javascript:;" v-on:click="$PageMove('/user/member/join')">회원가입</a>
                                    <a class="pu_btn_red" href="javascript:;" v-on:click="exec('GETID', {})" v-show="list_1.length == 0">ID 찾기</a>
                                    <a class="pu_btn_blue" href="javascript:;" v-on:click="exec('GETPW', {})" v-show="list_1.length > 0">비밀번호 찾기</a>
                                    <a class="pu_btn_defalut" href="javascript:;" v-on:click="$PageMove('/')"><i class="xi-close"></i> 취소하기</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
                </div>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->
        <!-------------------------- 하단footer부분 (s) ------------------------>
        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>
        <inc_user_foot />
        <!-------------------------- 하단footer부분 (e) ------------------------>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGI"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })
            
            // 진입점
            , default() {

                // this
                let _this = this;
                
                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {
                    
                    case "SEARCH":
                        {
                            
                        }
                        break;
                        
                    case "GETID":
                        {
                            // 이름
                            var name = $("[name='name']");
                            if (!name.val().trim()) {
                                alert("이름를 입력해주세요");
                                name.focus();
                                return false;
                            }

                            // 이메일
                            var email = $("[name='email']");
                            if (!email.val().trim()) {
                                alert("이메일을 입력해주세요");
                                email.focus();
                                return false;
                            }

                            var phone = $("[name='phone']");
                            if (!phone.val().trim()) {
                                alert("연락처를 입력해주세요");
                                phone.focus();
                                return false;
                            }
                            
                            {
                                // dimm 보이기
                                _this.$ShowDimmLogo();

                                 // url 설정
                                url = "/vue_api/user/login/findid";

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));
                            }                          
                        }
                        break;
                        
                    case "GETPW":
                        {
                            var id = $("[name='id']:checked");
                            if (id.length == 0) {

                                alert("아이디를 선택해주세요");
                                return false;
                            }

                            {
                                // dimm 보이기
                                _this.$ShowDimmLogo();

                                 // url 설정
                                url = "/vue_api/user/login/findpw";

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));
                            }
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {
                    
                    case "SEARCH":
                        {
                            
                        }
                        break;
                        
                    case "GETID":
                        {                            
                            if (data.LIST_1.length < 1) {

                                alert("등록된 회원정보가 없습니다.");
                                _this.list_1 = [];
                            }
                            else
                            {
                                _this.list_1 = data.LIST_1;
                            }
                        }
                        break;
                        
                    case "GETPW":
                        {
                            if(data.RESULT_YN == 'Y'){

                                let email = $("[name='email']").val();
                                alert("[" + email + "]로 임시비밀번호를 전송했습니다.");

                                this.$PageMove('/user/member/login');
                            }                            
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>