<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->

        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-- 서브 상단 영역 (s) -->
            <section class="pu_layoutTop">
                <div class="pu_subVisual pu_sub04" style="height:190px;">
                    <dl>
                        <!--<dt>결제 내역</dt>-->
                        <!--<dd>정부 및 대 기업체의 양돈관련 연구 프로젝트를 수행 중에 있습니다.</dd>-->
                    </dl>
                </div>
                <!-- 서브 메뉴표시 (s) -->
                <div class="pu_locationWrap">
                    <div class="pu_locationMenu" style="width: fit-content;">
                        <a class="pu_home" href="javascript:;" v-on:click="$PageMove('/')"><i class="xi-home-o"></i></a>
                        <ul class="pu_lnbList">

                            <li style="width: 200px; text-align: center;">
                                <div class="pu_select_wrap">
                                    <a class="pu_txtwrap" href="javascript:;" v-on:click="$PageMove('/user/member/editmember')"><span>회원정보 수정</span></a>
                                </div>
                            </li>

                            <li style="width: 200px; text-align: center;">
                                <div class="pu_select_wrap">
                                    <a class="pu_txtwrap" href="javascript:;" v-on:click="$PageMove('/user/member/paymember')"><span>유료회원 결제</span></a>
                                </div>
                            </li>

                            <li style="width: 200px; text-align: center;">
                                <div class="pu_select_wrap">
                                    <a class="pu_txtwrap" href="javascript:;" v-on:click="$PageMove('/user/member/paylist')"><span>결제 내역</span></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 서브 메뉴표시 (e) -->
            </section>
            <!-- 서브 상단 영역 (s) -->
            <!-- 컨텐츠 영역 (s) -->
            <section>
                <div class="pu_subTitle01 loginTit">
                    <article>
                        <h2 class="pu_join">결제 <span class="pu_txtColor4">내역</span></h2>
                        <p>정P&C연구소를 방문해주셔서 감사합니다.</p>
                    </article>
                </div>

                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent" style="max-width:600px !important; min-height:400px !important;">
                    <div class="pu_newsListDiv">

                        <div class="pu_tableY">
                            <table>
                                <colgroup>
                                    <col style="width:10%;">
                                    <col style="width:*;">
                                    <col style="width:20%;">
                                    <col style="width:20%;">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">번호</th>
                                        <th scope="col" class="list">결제내역</th>
                                        <th scope="col">타입</th>
                                        <th scope="col">결제일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, idx) in list_1">
                                        <td>
                                            {{ list_1.length - idx }}
                                        </td>
                                        <td class="list" style="text-align:center;">
                                            유료회원 결제&emsp;₩{{ item.AMOUNT }}원
                                            <br />({{ item.DATE_ST }} ~ {{ item.DATE_ED }})
                                        </td>
                                        <td>
                                            {{ item.TYPE }}
                                        </td>
                                        <td>
                                            {{ item.REGI_DATE }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->
        <!-------------------------- 하단footer부분 (s) ------------------------>
        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>
        <inc_user_foot />
        <!-------------------------- 하단footer부분 (e) ------------------------>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , list_1: []
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                this.getData();                
            }

            , getData() {
                var data = [];
                this.$SendPost("/vue_api/user/member/list_member_pay", data, this.returnGetData);
            }
            , returnGetData(data, err) {
                if (data && data.RESULT_YN == 'Y') {

                    this.list_1 = data.LIST_1;

                    // dimm 숨기기
                    this.$HideDimmLogo();
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>