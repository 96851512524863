<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->

        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">
            <!-- 컨텐츠 영역 (s) -->
            <section>
                <div class="pu_subTitle01 loginTit">
                    <article>
                        <h2 class="pu_login">Member <span class="pu_txtColor4">회원가입</span></h2>
                        <p>정P&C연구소를 방문해주셔서 감사합니다.</p>
                    </article>
                </div>
                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent" style="max-width:900px !important;">

                    <div class="pu_joinDiv">
                        <div class="pu_tablejoin">
                            <form id="regiForm" onsubmit="return false;">

                                <div style="position:relative;">
                                    <h3 style="font-weight: 600;">이용약관</h3>
                                    <div style="margin: 15px 0px; padding: 30px 25px; height: 250px; border: solid 1px #dddddd; overflow-y: scroll; line-height: 30px;"
                                         v-html="view_2.CONTENTS" />

                                    <span style="margin-bottom: 10px; right: 5px; bottom: -40px; position: absolute;">
                                        <input type="radio" id="agreeterm" name="agreeterm" value="1" /><label for="agreeterm">위 약관에 동의합니다.</label>
                                    </span>
                                </div>

                                <table style="margin-top:60px;">
                                    <colgroup>
                                        <col width="150px">
                                        <col width="*">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>아이디 <strong>*</strong></th>
                                            <td>
                                                <input type="text" name="id" v-on:change="isCheckId = false" maxlength="20" style="width:100% !important;" />
                                                <a class="pu_s_btn_dark" href="javascript:" v-on:click="exec('CHECKID', {})" v-show="!isCheckId" style="margin-top: 7px;">중복확인</a>
                                                <p v-show="isCheckId" style="margin-top: 7px; color: #d8006b;">사용가능한 아이디 입니다.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>비밀번호 <strong>*</strong></th>
                                            <td>
                                                <input type="password" name="password" value="" maxlength="20" style="width:100% !important;" />
                                                <span class="pu_txt">영문, 숫자를 조합하여 8~20자리로 설정하셔야 합니다.</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>비밀번호확인 <strong>*</strong></th>
                                            <td>
                                                <input type="password" name="checkpassword" maxlength="20" style="width:100% !important;" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이름 <strong>*</strong></th>
                                            <td>
                                                <input type="text" name="name" class="dev_koreng_5" style="width:100% !important;" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>생년월일 <strong>*</strong></th>
                                            <td>
                                                <div class="pu_dateSelect">
                                                    <ul class="pu_date_calendar">
                                                        <li>
                                                            <input type="text" name="birth" class="pu_calendar" readonly="readonly"
                                                                   onclick="pjh_calendar(undefined, '[name=birth]', 'YYYY-MM-DD')" />
                                                            <input type="image" src="/images/icon_calendar.png" style="width:auto !important;" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>연락처 <strong>*</strong></th>
                                            <td>
                                                <input type="text" name="phone" class="dev_phone" style="width:100% !important;" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이메일 <strong>*</strong></th>
                                            <td>
                                                <input type="text" name="email" class="dev_idmail" style="width:100% !important;" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>회사명</th>
                                            <td>
                                                <input type="text" name="company" maxlength="50" style="width:100% !important;" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>회사주소</th>
                                            <td>
                                                <input type="text" name="companyaddress" maxlength="50" style="width:100% !important;" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                            <div>
                                <div class="pu_btnDiv01">
                                    <a class="pu_btn_red" href="javascript:;" v-on:click="exec('SAVE', {})"><i class="xi-border-color"></i> 가입하기</a>
                                    <a class="pu_btn_defalut" href="javascript:;" v-on:click="$PageMove('/user/home')"><i class="xi-close"></i> 취소하기</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
                </div>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->
        <!-------------------------- 하단footer부분 (s) ------------------------>
        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>
        <inc_user_foot />
        <!-------------------------- 하단footer부분 (e) ------------------------>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGI"

                , rownum: 0    // 번호
                , paging: ""   // 페이징
                
                , isCheckId: false

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })
            
            // 진입점
            , default() {

                // this
                let _this = this;
                
                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {
                    
                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/board/cms/viewone";

                            // 데이터
                            data["page_gubun"] = -10;
                        }
                        break;
                        
                    case "CHECKID":
                        {
                            // url 설정
                            url = "/vue_api/user/login/idcheck";
                                                        
                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#regiForm")));                   
                        }
                        break;
                        
                    case "SAVE":
                        {
                            // 약관 동의
                            var agreeterm = $("[name='agreeterm']");
                            if (!agreeterm.is(":checked")) {
                                alert("약관에 동의해야합니다.");
                                agreeterm.focus();
                                return false;
                            }

                            // 아이디
                            var id = $("[name='id']");
                            if (!id.val().trim()) {
                                alert("아이디를 입력해주세요");
                                id.focus();
                                return false;
                            }

                            // 중복체크
                            if (!_this.isCheckId) {
                                alert("아이디 중복체크를 해야합니다")
                                id.focus();
                                return false;
                            }

                            // 비밀번호
                            var password = $("[name='password']");
                            if (!password.val().trim()) {
                                alert("비밀번호를 입력해주세요");
                                password.focus();
                                return false;
                            }

                            // 비밀번호 확인
                            var checkpassword = $("[name='checkpassword']");
                            if (checkpassword.val().trim() != password.val().trim()) {
                                alert("비밀번호가 다릅니다");
                                checkpassword.focus();
                                return false;
                            }

                            // 이름
                            var name = $("[name='name']");
                            if (!name.val().trim()) {
                                alert("이름를 입력해주세요");
                                name.focus();
                                return false;
                            }

                            // 생년월일
                            var birth = $("[name='birth']");
                            if (!birth.val().trim()) {
                                alert("생년월일를 입력해주세요");
                                birth.focus();
                                return false;
                            }

                            // 연락처
                            var phone = $("[name='phone']");
                            if (!phone.val().trim()) {
                                alert("연락처를 입력해주세요");
                                phone.focus();
                                return false;
                            }

                            // 이메일
                            var email = $("[name='email']");
                            if (!email.val().trim()) {
                                alert("이메일을 입력해주세요");
                                email.focus();
                                return false;
                            }

                            if (confirm("가입 하시겠습니까?")) {

                                // dimm 보이기
                                _this.$ShowDimmLogo();

                                // url 설정
                                url = "/vue_api/user/login/savejoin";

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));
                            }
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {
                    
                    case "SEARCH":
                        {
                            _this.view_2 = data.VIEW_1;
                        }
                        break;
                        
                    case "CHECKID":
                        {                            
                           if (data.VIEW_1.CNT == "0") {

                                _this.isCheckId = true;
                                $("[name='id']").attr("readonly", true);
                            } else {

                                alert("사용중인 아이디입니다.");;
                            }
                        }
                        break;
                        
                    case "SAVE":
                        {
                           
                        }
                        break;
                        
                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // 저장
            , saveData() {

                // 약관 동의
                var agreeterm = $("[name='agreeterm']");
                if (!agreeterm.is(":checked")) {
                    alert("약관에 동의해야합니다.");
                    agreeterm.focus();
                    return false;
                }

                // 아이디
                var id = $("[name='id']");
                if (!id.val().trim()) {
                    alert("아이디를 입력해주세요");
                    id.focus();
                    return false;
                }

                // 중복체크
                if (!this.passid) {
                    alert("아이디 중복체크를 해야합니다")
                    id.focus();
                    return false;
                }

                // 비밀번호
                var password = $("[name='password']");
                if (!password.val().trim()) {
                    alert("비밀번호를 입력해주세요");
                    password.focus();
                    return false;
                }

                // 비밀번호 확인
                var checkpassword = $("[name='checkpassword']");
                if (checkpassword.val().trim() != password.val().trim()) {
                    alert("비밀번호가 다릅니다");
                    checkpassword.focus();
                    return false;
                }

                // 이름
                var name = $("[name='name']");
                if (!name.val().trim()) {
                    alert("이름를 입력해주세요");
                    name.focus();
                    return false;
                }

                // 생년월일
                var birth = $("[name='birth']");
                if (!birth.val().trim()) {
                    alert("생년월일를 입력해주세요");
                    birth.focus();
                    return false;
                }

                // 연락처
                var phone = $("[name='phone']");
                if (!phone.val().trim()) {
                    alert("연락처를 입력해주세요");
                    phone.focus();
                    return false;
                }

                // 이메일
                var email = $("[name='email']");
                if (!email.val().trim()) {
                    alert("이메일을 입력해주세요");
                    email.focus();
                    return false;
                }

                if (confirm("가입 하시겠습니까?")) {

                    var data = this.$FormToMap($("#regiForm"));

                    // API 조회
                    this.$SendPost("/vue_api/user/login/savejoin", data, this.returnSave);
                }
            }

            // 저장 데이터
            , returnSave(data, err) {
                if (data && data.RESULT_YN == "Y") {
                    this.$PageMove('/user/member/join_ok');
                } else {
                    alert("처리중 에러가 발생했습니다.");
                }
            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>