<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->

        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-- 컨텐츠 영역 (s) -->
            <section>

                <div class="pu_subTitle01 loginTit"></div>

                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">

                    <div class="pu_joinEnd">
                        <dl>
                            <dt>회원가입이 <strong>완료</strong>되었습니다.</dt>
                            <dd>
                                회원가입을 축하합니다.<br>
                                알차고 빠른 서비스로 찾아뵙겠습니다.
                            </dd>
                        </dl>

                        <div class="pu_btnDiv01">
                            <a class="pu_btn_red" href="javascript:;" v-on:click="$PageMove('/user/member/login')"><i class="xi-lock-o"></i> 로그인</a>
                            <a class="pu_btn_defalut" href="javascript:;" v-on:click="$PageMove('/')"><i class="xi-home-o"></i> 홈으로</a>
                        </div>

                        <div>
                        </div>

                    </div>
                    <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
                </div>

            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->
        <!-------------------------- 하단footer부분 (s) ------------------------>
        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>
        <inc_user_foot />
        <!-------------------------- 하단footer부분 (e) ------------------------>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "VIEW"

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // dimm 숨기기
                this.$HideDimmLogo();
            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>