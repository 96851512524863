<template>
    <div class="pu_warpper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(e) -------------------------->
        <!--------------------------------------------------------- 컨텐츠 영역 부분(s) ---------------------------------------------------->
        <div class="pu_contentWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_title />
            <!-------------------------- 인클루드(e) -------------------------->
            <!-- 컨텐츠 영역 (s) -->
            <section>
                <div class="pu_subTitle01">
                    <article>
                        <h2>{{ getBase.userMenuName_3d }}</h2>
                        <p style="margin: 13px auto; font-size: 15px; font-weight: 400; color: #D8006B;">* 해당 기관명을 클릭하시면 분석수수료 입금 및 성적서 발송여부를 확인하실수 있습니다.</p>
                    </article>
                </div>
                <!-----------------서브 실제 컨텐츠 영역 (s)  ------------------>
                <div class="pu_layoutContent">
                    <div class="pu_newsListDiv">
                        <div class="pu_listSearch">
                            <div class="pu_search01">
                                <form id="searchForm" onsubmit="return false;">
                                    <select name="searchtype">
                                        <option value="COMP_NAME" selected="selected">기관명</option>
                                        <option value="DEPOSIT_NAME">입금자명</option>
                                    </select>
                                    <div class="pu_input_wrap">
                                        <label for="search" class="pu_blind">검색어</label>
                                        <input type="text" name="searchtext" id="searchtext" value="" placeholder="검색어를 입력하세요" title="검색어를 입력하세요" />
                                        <a href="javascript:;" class="pu_btnSerch01" v-on:click="exec('SEARCH', {'curPage': 1})">검색</a>
                                    </div>
                                </form>
                            </div>
                            <div class="pu_totalTxt">총 <span>{{ rownum }}</span>개 조회완료</div>
                        </div>
                        <div class="pu_tableY">
                            <table>
                                <colgroup>
                                    <col width="70px">
                                    <col width="15%">
                                    <col width="15%">
                                    <col width="*">
                                    <col width="15%">
                                    <!--
                                    <col width="70px">
                                    <col width="120px">
                                    <col width="120px">
                                    <col width="120px">
                                    -->
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col" style="line-height:20px;">번호</th>
                                        <th scope="col" style="line-height:20px;">구분</th>
                                        <th scope="col" style="line-height:20px;">지역</th>
                                        <th scope="col" class="list" style="line-height:20px;">기관명</th>
                                        <th scope="col" style="line-height:20px;">의뢰일자</th>
                                        <!--
                                        <th scope="col" style="line-height:20px;">의뢰<br />건수</th>
                                        <th scope="col" style="line-height:20px;">총금액</th>
                                        <th scope="col" style="line-height:20px;">입금일<br />입금자명</th>
                                        <th scope="col" style="line-height:20px;">발송일<br />발송법</th>
                                        -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, idx) in list_1">
                                        <td style="padding: 5px; height:55px; border-right:solid 1px #f2f2f2">
                                            {{ getRowNum(idx) }}
                                        </td>
                                        <td style="padding: 5px; border-right:solid 1px #f2f2f2">
                                            {{ item.COMP_GUBUN_NAME }}
                                        </td>
                                        <td style="padding: 5px; border-right:solid 1px #f2f2f2">
                                            {{ item.CODE_AREA_NAME_1 }} {{ item.CODE_AREA_NAME_2 }}
                                        </td>
                                        <td class="list" style="padding: 5px; border-right: solid 1px #f2f2f2">
                                            <a href="javascript:;" v-on:click="$PageMove('./req_view?no=' + item.NO)">{{ item.COMP_NAME }}</a>
                                        </td>
                                        <td style="padding: 5px; border-right: solid 1px #f2f2f2">
                                            {{ item.REQ_DATE }}
                                        </td>
                                        <!--
                                        <td style="padding: 5px; border-right: solid 1px #f2f2f2">
                                            {{ item.REQ_CNT }}
                                        </td>
                                        <td style="padding: 5px; border-right: solid 1px #f2f2f2">
                                            {{ $ConMoney(item.AMOUNT) }}
                                        </td>
                                        <td style="padding: 5px; border-right: solid 1px #f2f2f2">
                                            {{ item.DEPOSIT_DATE }}<br />{{ item.DEPOSIT_NAME }}
                                        </td>
                                        <td style="padding: 5px;">
                                            {{ item.DELIVERY_DATE }}<br />{{ item.DELIVERY_GUBUN_NAME }}
                                        </td>
                                        -->
                                    </tr>

                                    <tr v-if="list_1.length < 1">
                                        <td colspan="5">조회된 내용이 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!--  -->
                        <!-- 페이징 -->
                        <div class="pu_paging">
                            <div class="pu_pagination" v-html="paging">

                            </div>
                        </div>
                        <!--  //페이징 -->
                        <!--  -->
                    </div>
                </div>
                <!------------------ 서브 실제 컨텐츠 영역 (e) ------------------>
            </section>
            <!-- 컨텐츠 영역 (e) -->
        </div>
        <!----------------------------------------------------- 컨텐츠 영역 부분(e) ---------------------------------------------------->

        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/gmo/req_list";

                            // 폼
                            data["curPage"] = params.curPage;

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.rownum = data.ROWNUM;
                            _this.paging = data.PAGING;

                            _this.list_1 = data.LIST_1;
                            _this.list_2 = data.LIST_2;
                            _this.list_3 = data.LIST_3;
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>