<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">
                    <inc_zadmin_title />
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">

                        <div class="pu_pu_MenuSettingDiv">
                            <!-- 메뉴관리 (s) -->
                            <div class="pu_MenuSetting">
                                <div class="pu_MenuSettingTop">
                                    <h3 class="pu_f_left"><i class="xi-comment-o"></i> 대메뉴</h3>
                                    <div class="pu_f_right">
                                        <a class="pu_ss_btn_dark" href="javascript:" v-on:click="moveDirection(list_1, -1)">상단이동</a>
                                        <a class="pu_ss_btn_dark" href="javascript:" v-on:click="moveDirection(list_1, 1)">하단이동</a>
                                    </div>
                                </div>
                                <div class="pu_TableX01">
                                    <table class="pu_zebra">
                                        <colgroup>
                                            <col width="*">
                                            <col span="2" width="18%">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">목록</th>
                                                <th scope="col">삭제</th>
                                                <th scope="col">선택</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, idx) in list_1">
                                                <td class="pu_leftTxt">
                                                    <input ref="input1_1" style="width:100%" v-show="row.EDITABLE == 1" v-model="row.CODE_NAME" v-on:blur="saveCodename(list_1, row)" />
                                                    <div style="width:100%" v-show="row.EDITABLE == 0" v-on:click="row.EDITABLE = '1'">{{ row.CODE_NAME }}</div>
                                                </td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제" v-on:click="deleteCode(list_1, row)"></td>
                                                <td><input type="image" v-bind:src="row.MATCH == '1' ? '/images/icon_move.png' : '/images/icon_check.png'" title="선택" v-on:click="selectSitecode(list_1, row)"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="pu_footBtn01">
                                    <button v-on:click="addCodename(list_1, 1, undefined)"> 대메뉴 추가 시, 이곳을 클릭하여 주세요 <i class="xi-angle-right"></i></button>
                                </div>
                            </div>
                            <!-- 메뉴관리 (s) -->
                            <!-- 메뉴관리 (s) -->
                            <div class="pu_MenuSetting">
                                <div class="pu_MenuSettingTop">
                                    <h3 class="pu_f_left"><i class="xi-comment-o"></i> 중메뉴</h3>
                                    <div class="pu_f_right">
                                        <a class="pu_ss_btn_dark" href="javascript:" v-on:click="moveDirection(list_2, -1)">상단이동</a>
                                        <a class="pu_ss_btn_dark" href="javascript:" v-on:click="moveDirection(list_2, 1)">하단이동</a>
                                    </div>
                                </div>
                                <div class="pu_TableX01">
                                    <table class="pu_zebra">
                                        <colgroup>
                                            <col width="*">
                                            <col span="2" width="16%">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">목록</th>
                                                <th scope="col">삭제</th>
                                                <th scope="col">선택</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, idx) in list_2">
                                                <td class="pu_leftTxt">
                                                    <input style="width:100%" v-show="row.EDITABLE == 1" v-model="row.CODE_NAME" v-on:blur="saveCodename(list_2, row)" />
                                                    <div style="width:100%" v-show="row.EDITABLE == 0" v-on:click="row.EDITABLE = '1'">{{ row.CODE_NAME }}</div>
                                                </td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제" v-on:click="deleteCode(list_2, row)"></td>
                                                <td><input type="image" v-bind:src="row.MATCH == '1' ? '/images/icon_move.png' : '/images/icon_check.png'" title="선택" v-on:click="selectSitecode(list_2, row)"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="pu_footBtn01">
                                    <button v-on:click="addCodename(list_2, 2, list_1.find(d => d.MATCH == 1).CODE)"> 중메뉴 추가 시, 이곳을 클릭하여 주세요 <i class="xi-angle-right"></i></button>
                                </div>
                            </div>
                            <!-- 메뉴관리 (s) -->
                            <!-- 메뉴관리 (s) -->
                            <div class="pu_MenuSetting">
                                <div class="pu_MenuSettingTop">
                                    <h3 class="pu_f_left"><i class="xi-comment-o"></i> 소메뉴</h3>
                                    <div class="pu_f_right">
                                        <a class="pu_ss_btn_dark" href="javascript:" v-on:click="moveDirection(list_3, -1)">상단이동</a>
                                        <a class="pu_ss_btn_dark" href="javascript:" v-on:click="moveDirection(list_3, 1)">하단이동</a>
                                    </div>
                                </div>
                                <div class="pu_TableX01">
                                    <table class="pu_zebra">
                                        <colgroup>
                                            <col width="*">
                                            <col span="3" width="16%">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">목록</th>
                                                <th scope="col">설정</th>
                                                <th scope="col">삭제</th>
                                                <th scope="col">선택</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, idx) in list_3">
                                                <td>
                                                    <input style="width:100%" v-show="row.EDITABLE == 1" v-model="row.CODE_NAME" v-on:blur="saveCodename(list_3, row)" />
                                                    <div style="width:100%" v-show="row.EDITABLE == 0" v-on:click="row.EDITABLE = '1'">{{ row.CODE_NAME }}</div>
                                                </td>
                                                <td><input type="image" src="/images/icon_setting.png" data-popname="popup01" class="pu_call_pop" title="설정" v-on:click="showSiteinfo(list_3, row)"></td>
                                                <td><input type="image" src="/images/icon_del.png" title="삭제" v-on:click="deleteCode(list_3, row)"></td>
                                                <td><input type="image" v-bind:src="row.MATCH == '1' ? '/images/icon_move.png' : '/images/icon_check.png'" title="선택" v-on:click="selectSitecode(list_3, row)"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="pu_footBtn01">
                                    <button v-on:click="addCodename(list_3, 3, list_2.find(d => d.MATCH == 1).CODE)">소메뉴 추가 시, 이곳을 클릭하여 주세요 <i class="xi-angle-right"></i></button>
                                </div>
                            </div>
                            <!-- 메뉴관리 (s) -->
                        </div>

                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
        <!--  팝업 s-->
        <div class="pu_mask"></div>
        <!-- 메뉴명 팝업 s-->
        <div class="pu_popup popup01" style="display:none;width:500px">
            <div class="pu_pop_title">
                <h2>메뉴명</h2>
                <button type="button" class="pu_pop_close" v-on:click="hideSiteinfo()">닫기</button>
            </div>
            <div class="pu_pop_contents">
                <section>
                    <!-- 컨텐츠 테이블 s -->
                    <div class="pu_TableY01">
                        <table>
                            <tbody>
                                <tr>
                                    <th>URL<br>(절대경로)</th>
                                    <td><input type="text" placeholder="Url을 입력하세요" style="width: 100%;" v-model="siteinfo.URL" ></td>
                                </tr>
                                <tr>
                                    <th>SUBURL<br>(절대경로)</th>
                                    <td><input type="text" placeholder="SubUrl을 입력하세요" style="width: 100%;" v-model="siteinfo.SUBURL"></td>
                                </tr>
                                <tr>
                                    <th>접근권한</th>
                                    <td>
                                        <div>
                                            <span v-for="(auth, idx) in authlist">
                                                <input type="radio" v-bind:id="`gubun${idx}`" v-bind:value="auth.GRADE" v-model="siteinfo.AUTHGRADE" /><label v-bind:for="`gubun${idx}`">{{auth.TITLE}}</label>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>메뉴 사용여부</th>
                                    <td>
                                        <div>
                                            <input type="radio" id="useyn01" name="use_yn" value="Y" v-model="siteinfo.USE_YN"><label for="useyn01">사용</label>
                                            <input type="radio" id="useyn02" name="use_yn" value="N" v-model="siteinfo.USE_YN"><label for="useyn02">사용안함</label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- 컨텐츠 테이블 e -->
                </section>
            </div>
            <div class="pu_popup_btn">
                <a class="pu_s_btn_dark pu_pop_close" v-on:click="saveCodename(undefined, siteinfo)">저장후 닫기</a>
            </div>
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {}

        , data() {

            return {
                gubun_page: "menu_admin"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                , authlist: []
                , siteinfo: {}

                , searchtypevalue: "id"
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // dimm 숨기기
                this.getSiteauth();
                this.getSitecode(1, "");

                this.$HideDimmLogo();
            }

            , getSiteauth: function () {
                var data = {};
                data["gubun"] = 'A';
                this.$SendPost("/vue_api/common/menu/siteauthkind", data, this.returnSiteauth);
            }
            , returnSiteauth: function (data, err) {
                if (data && data.RESULT_YN === "Y") {
                    this.authlist = data.LIST_1;
                }
            }
            , getSitecode: function (depth, ptcode) {

                // qs
                let qs = this.getBase.curQsMap;

                // 폼
                var data = {};
                data["depth"] = depth;
                data["ptcode"] = ptcode;

                // API 조회
                this.$SendPost("/vue_api/common/menu/zadminbydepth", data, this.searchDataReturn);

            }
            , searchDataReturn: function (data, err) {

                if (data) {
                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        var depth = data.VIEW_1["DEPTH"];
                        switch (depth) {
                            case "1":
                                var selrow = this.list_1.find(d => d["MATCH"] == 1);
                                var selcode = selrow ? selrow["CODE"] : null;
                                if (selrow) {
                                    var matchrow = data.LIST_1.find(d => d["CODE"] == selcode);
                                    if (matchrow) {
                                        matchrow["MATCH"] = 1
                                        this.getSitecode(2, selcode);
                                    } else {
                                        this.list_2 = [];
                                    }
                                }
                                this.list_1 = data.LIST_1;
                                break;
                            case "2":
                                var selrow = this.list_2.find(d => d["MATCH"] == 1);
                                var selcode = selrow ? selrow["CODE"] : null;
                                if (selrow) {
                                    var matchrow = data.LIST_1.find(d => d["CODE"] == selcode);
                                    if (matchrow) {
                                        matchrow["MATCH"] = 1
                                        this.getSitecode(3, selcode);
                                    } else {
                                        this.list_3 = [];
                                    }
                                }
                                this.list_2 = data.LIST_1;
                                break;
                            case "3":
                                var selrow = this.list_3.find(d => d["MATCH"] == 1);
                                var selcode = selrow ? selrow["CODE"] : null;
                                if (selrow) {
                                    var matchrow = data.LIST_1.find(d => d["CODE"] == selcode);
                                    if (matchrow) {
                                        matchrow["MATCH"] = 1
                                    }
                                }
                                this.list_3 = data.LIST_1;
                                break;
                        }
                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }
            , refreshSitecode: function (data, err) {
                var depth = data.VIEW_1["DEPTH"];
                var selcode = data.VIEW_1["PT_CODE"];
                this.getSitecode(depth, selcode);
            }
            , selectSitecode: function (curlist, row) {
                if (row["EDITABLE"] == "1") {
                    return;
                }
                var nextdepth = parseInt(row["DEPTH"]) + 1;
                var ptcode = row["CODE"];
                for (var i = 0; i < curlist.length; i++) {
                    curlist[i]["MATCH"] = "0";
                }
                row["MATCH"] = "1";
                this.getSitecode(nextdepth, ptcode);
            }
            , saveCodename: function (curlist, row) {
                var data = {};
                data["code"] = row.CODE;
                data["code_name"] = row.CODE_NAME;
                data["gubun"] = row.GUBUN;
                data["depth"] = row.DEPTH;
                data["rank"] = row.RANK;
                data["pt_code"] = row.PT_CODE;
                data["use_yn"] = row.USE_YN;
                data["url"] = row.URL;
                data["suburl"] = row.SUBURL;
                data["authgrade"] = row.AUTHGRADE;

                if (curlist && !row["CODE"] && !row["CODE_NAME"]) {
                    curlist.pop(row);
                    return;
                }
                
                row.EDITABLE = '0';

                this.$SendPost("/vue_api/zadmin/homepage/menu_admin/save", data, this.refreshSitecode);
            }
            , addCodename: function (curlist, curdepth, ptcode) {
                var rank = (curlist.length > 0 ? parseInt(curlist[curlist.length - 1].RANK) : 0) + 1;
                curlist.push({
                    "PT_CODE": ptcode, "GUBUN": "A", "RANK": rank, "DEPTH": parseInt(curdepth),
                    "EDITABLE": "1", "USE_YN" : "Y"
                });
            }
            , deleteCode: function (curlist, row) {
                var data = {};
                data["code"] = row.CODE;
                data["depth"] = row.DEPTH;
                data["pt_code"] = row.PT_CODE;
                this.$SendPost("/vue_api/zadmin/homepage/menu_admin/delete", data, this.refreshSitecode);
            }
            , moveDirection: function (curlist, step) {
                var row = curlist.find(row => row["MATCH"] == "1");
                var index = curlist.indexOf(row);
                if (index == -1) {
                    alert("메뉴를 선택해주세요.");
                    return;
                }
                if (step < 0 && index == 0) {
                    alert("더 이상 상위로 올라갈 수 없습니다.");
                    return;
                } else if (step > 0 && curlist.length <= index + step) {
                    alert("더 이상 하위로 내려갈 수 없습니다.");
                    return;
                }

                var data = {};
                data["code"] = row["CODE"];
                data["depth"] = row["DEPTH"];
                data["pt_code"] = row["PT_CODE"];
                data["step"] = step;
                this.$SendPost("/vue_api/zadmin/homepage/menu_admin/rankswap", data, this.refreshSitecode);
            }
            , showSiteinfo(curlist, row) {
                this.siteinfo = { ...row };
            }
            , saveSiteinfo(row) {
                var data = {};
                data["code"] = row.CODE;
                data["code_name"] = row.CODE_NAME;
                data["gubun"] = row.GUBUN;
                data["depth"] = row.DEPTH;
                data["rank"] = row.RANK;
                data["pt_code"] = row.PT_CODE;
                data["use_yn"] = row.USE_YN;
                data["url"] = row.URL;
                data["suburl"] = row.SUBURL;
                data["authgrade"] = row.AUTHGRADE;

                this.$SendPost("/vue_api/zadmin/homepage/menu_admin/save", data, this.refreshSitecode);
            }
            , hideSiteinfo() {
                this.siteinfo = {};
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>