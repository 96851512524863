<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_zadmin_head />
            <inc_zadmin_left />
            <!-------------------------- 인클루드(e) -------------------------->

            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">
                    
                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_zadmin_title />
                    <!-------------------------- 인클루드(e) -------------------------->
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div id="dev_print" class="pu_listTable01" style="max-width:800px;">
                            <div class="pu_TableY01">
                                <table>
                                    <colgroup>
                                        <col width="15%">
                                        <col width="*">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>제목</th>
                                            <td>{{ view_1.TITLE }}</td>
                                        </tr>
                                        <tr>
                                            <th>내용</th>
                                            <td><div class="pu_div_contents" v-html="$ConHtml(view_1.CONTENTS)"></div></td>
                                        </tr>
                                        <tr>
                                            <th>적용여부</th>
                                            <td>{{ view_1.SHOW_YN == "Y" ? "적용" : "미적용" }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->
                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_left">
                                <a class="pu_btn_dark" href="javascript:;" v-on:click="$PageMove('./normal?page_gubun=' + getBase.curQsMap.page_gubun)"><i class="xi-bars"></i> 목록</a>
                                <a class="pu_btn_dark" href="javascript:;" v-on:click="exec('PRINT', {'curPage':1})"><i class="xi-print"></i> 인쇄</a>
                                <a class="pu_btn_blue" href="javascript:;" v-on:click="$PageMove('./normal_regi?page_gubun=' + getBase.curQsMap.page_gubun + '&no=' + view_1.NO)"><i class="xi-eraser"></i> 수정</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "VIEW"

                , cnt_lim_pic: 50  // 사진 갯수

                , rownum: 0        // 번호
                , paging: ""       // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {
            
            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , default() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", { "curPage": 1 });
            }

            // 실행
            , exec: function (gubun, params) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.getBase.curQsMap;
                let data = { "PJH_GUBUN": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/board/normal/view";

                            // 폼
                            data["no"] = qs.no;
                        }
                        break;

                    case "PRINT":
                        {
                            
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.PJH_GUBUN) {

                    case "SEARCH":
                        {
                            _this.view_1 = data.VIEW_1;
                        }
                        break;

                    case "PRINT":
                        {
                            $('#dev_print').printThis({
                                debug: false,               // show the iframe for debugging
                                importCSS: true,            // import page CSS
                                importStyle: false,         // import style tags
                                printContainer: true,       // grab outer container as well as the contents of the selector
                                //loadCSS: "path/to/my.css",  // path to additional css file - us an array [] for multiple
                                pageTitle: "",              // add title to print page
                                removeInline: false,        // remove all inline styles from print elements
                                printDelay: 500,            // variable print delay
                                header: null,               // prefix to html
                                footer: null,               // postfix to html
                                base: false,                // preserve the BASE tag, or accept a string for the URL
                                formValues: true,           // preserve input/form values
                                canvas: true,               // copy canvas elements (experimental)
                                //doctypeString: '...',     // enter a different doctype for older markup
                                removeScripts: false,       // remove script tags from print content
                                copyTagClasses: false       // copy classes from the html & body tag
                            });
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>